import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { AutocompleteField, Box, Button, Card, Form } from '@common/components/';
import { Grid } from '@mui/material';
import { CyclePurchaseListTable } from '@icoach/documents/table';
import { refIsRequiredError } from '@util/utils';
import usePeopleApi from '@apis/usePeopleApi';
import useCyclePurchaseApi from '@apis/useCyclePurchaseApi';
import { CyclePurchaseProvider, useCyclePurchaseContext } from '@icoach/documents/cyclePurchase/CyclePurchaseContext';
import { DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER } from '@icoach/router/routerPath';
import { initBookmark } from '@icoach/members/staticData';

const SearchBar = React.memo((props) => {
    const { className } = props;
    const history = useHistory();
    const { setTableData, setRefresh, bookmark } = useCyclePurchaseContext();
    // 所有會員
    const { people: peopleOption = [] } = usePeopleApi(6);
    const { getCyclePurchaseMemberOrdersApi } = useCyclePurchaseApi();
    const memberRef = useRef(null);
    const preloadRef = useRef(false);
    const paramsRef = useRef(null); // 記住按下搜尋時的參數

    const getParams = () => {
        return { memberID: memberRef.current && memberRef.current.getResult('id') };
    };
    const checkError = () => refIsRequiredError(memberRef);

    const doCyclePurchaseMemberOrdersApi = async (memberID, params) => {
        preloadRef.current = false;
        let resp = await getCyclePurchaseMemberOrdersApi(memberID, params);
        if (resp) {
            setTableData(({ list = [] }) => {
                let resource = {};
                if (list.length === 0 || resp.pageIndex === 1) {
                    Object.assign(resource, resp);
                } else {
                    let oldList = cloneDeep(list);
                    oldList.push(...resp.list);
                    Object.assign(resource, resp);
                    Object.assign(resource, { list: oldList });
                }
                return resource;
            });
        }
        preloadRef.current = true;
    };

    const handleSubmit = (pageObject = { pageIndex: null }) => {
        let isError = checkError();
        if (!isError) {
            let pageModel = initBookmark();
            if (pageObject && pageObject.pageIndex * 1) {
                // 更多頁
                Object.assign(pageModel, { pageIndex: pageObject.pageIndex });
            } else {
                // 按下搜尋
                const { memberID } = getParams();
                Object.assign(pageModel);
                paramsRef.current = memberID;
            }
            doCyclePurchaseMemberOrdersApi(paramsRef.current, pageModel);
        }
    };

    useEffect(
        () => {
            setRefresh(doCyclePurchaseMemberOrdersApi);
            doCyclePurchaseMemberOrdersApi(null, initBookmark());
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            if (preloadRef.current) {
                handleSubmit({ pageIndex: bookmark.pageIndex });
            }
        },
        // eslint-disable-next-line
        [bookmark.pageIndex]
    );

    return (
        <Card className={className}>
            <Form onSubmit={handleSubmit}>
                <Card.CardContent className={'px-4 pt-2 pb-2'}>
                    <Grid spacing={3} container>
                        <Grid xs={6} item>
                            <AutocompleteField
                                label={'訂購人'}
                                ref={memberRef}
                                options={peopleOption}
                                optionKeys={['name', 'mobile', 'no']}
                                primaryKey={'id'}
                                direction={'row'}
                                inputProps={{
                                    placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可)',
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={3} item>
                            <Button type={'submit'} variant={'contained'}>
                                搜尋訂單
                            </Button>
                        </Grid>
                        <Grid className={'text-right'} xs={3} item>
                            <Button variant={'contained'} onClick={() => history.push(DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER)}>
                                新增循環訂購單
                            </Button>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Form>
        </Card>
    );
});

const CyclePurchaseOverviewPage = (props) => {
    const [tableData, setTableData] = useState([]);
    const [bookmark, setBookmark] = useState({
        ...initBookmark(),
    });
    const refreshRef = useRef();
    const { list = [], ...others } = tableData;

    const childContext = {
        tableData,
        setTableData,
        refresh: refreshRef,
        setRefresh: (fn) => (refreshRef.current = fn),
        bookmark: bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
    };

    return (
        <CyclePurchaseProvider value={childContext}>
            <Box className="container main-container-spacing cycle-purchase-page">
                <SearchBar className={'mb-4'} />
                <CyclePurchaseListTable rowsData={list || []} {...others} />
            </Box>
        </CyclePurchaseProvider>
    );
};

export default CyclePurchaseOverviewPage;
