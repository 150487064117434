import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { NavLink, useHistory } from 'react-router-dom';
import { ListItemButton, ListItemText, Box, Divider, List, Chip } from '@common/components/';
import {
    NOTIFICATIONS_ENTIRE_ROUTER,
    NOTIFICATIONS_DOC_TRANSFERIN_ROUTER,
    NOTIFICATIONS_DOC_TRANSFEROUT_ROUTER,
    // NOTIFICATIONS_DOC_MEMBERSHIP_EXPIRE_ROUTER,
    NOTIFICATIONS_INVOICE_ROUTER,
    NOTIFICATIONS_UPDATE_VERSION_ROUTER,
    // NOTIFICATIONS_DOC_TEMPORARINESS_ROUTER,
} from '@icoach/router/routerPath';
import useNotificationApi from '@apis/useNotificationApi';
import Logo from '@images/nav_logo.svg';
import { HOME_ARRIVE_ROUTER } from '@icoach/router/routerPath';
// import { notificationMenus } from '@icoach/notifications/staticData';
// import { keyBy } from 'lodash';

const NotificationNavBox = () => {
    const [sourceData, setSourceData] = useState([]);
    const history = useHistory();
    const { getNotificationUnreadApi } = useNotificationApi();
    const totalCount = sourceData.reduce((acc, { count = 0 }) => {
        return acc + count;
    }, 0);

    const handleReturnHomePage = () => {
        history.push(HOME_ARRIVE_ROUTER);
    };

    const targetMenuCount = (targetData = [], type = 0) => {
        return targetData.find(({ sourceType }) => String(sourceType) === String(type))?.count ?? 0;
    };

    const initApi = async () => {
        const resp = await getNotificationUnreadApi();
        if (resp) {
            setSourceData(resp);
        }
    };

    useEffect(
        () => {
            initApi();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Box className={clsx(`notification-menus`)}>
            <Box className={clsx(`notification-menus-container`)}>
                <Box className={'logo'}>
                    <img src={Logo} alt={'Curves'} className="cursor-pointer" onClick={handleReturnHomePage} />
                </Box>
                <List component="div" className="menu" disablePadding>
                    <ListItemButton component={NavLink} to={NOTIFICATIONS_ENTIRE_ROUTER} activeClassName={`list-item-button-selected`}>
                        <ListItemText primary={'全部通知'} />
                        {!!totalCount && <Chip className="count-dot" label={totalCount} color={'error'} />}
                    </ListItemButton>
                </List>
                <Divider className="mx-2" />
                <List component="div" className="menu" disablePadding>
                        <ListItemButton component={NavLink} to={NOTIFICATIONS_DOC_TRANSFERIN_ROUTER} activeClassName={`list-item-button-selected`}>
                            <ListItemText primary={'會籍移入'} />
                            {!!targetMenuCount(sourceData, 1) && <Chip className="count-dot" label={targetMenuCount(sourceData, 1)} color={'error'} />}
                        </ListItemButton>
                    </List>
                    <List component="div" className="menu" disablePadding>
                        <ListItemButton component={NavLink} to={NOTIFICATIONS_DOC_TRANSFEROUT_ROUTER} activeClassName={`list-item-button-selected`}>
                            <ListItemText primary={'會籍移出'} />
                            {!!targetMenuCount(sourceData, 2) && <Chip className="count-dot" label={targetMenuCount(sourceData, 2)} color={'error'} />}
                        </ListItemButton>
                    </List>
                    {/* <List component="div" className="menu" disablePadding>
                        <ListItemButton component={NavLink} to={NOTIFICATIONS_DOC_MEMBERSHIP_EXPIRE_ROUTER} activeClassName={`list-item-button-selected`}>
                            <ListItemText primary={'會籍到期'} />
                            {!!targetMenuCount(sourceData, 3) && <Chip className="count-dot" label={targetMenuCount(sourceData, 3)} color={'error'} />}
                        </ListItemButton>
                    </List> */}
                <List component="div" className="menu" disablePadding>
                    <ListItemButton component={NavLink} to={NOTIFICATIONS_INVOICE_ROUTER} activeClassName={`list-item-button-selected`}>
                        <ListItemText primary={'發票異常'} />
                        {!!targetMenuCount(sourceData, 4) && <Chip className="count-dot" label={targetMenuCount(sourceData, 4)} color={'error'} />}
                    </ListItemButton>
                </List>
                {/* <List component="div" className="menu" disablePadding>
                        <ListItemButton component={NavLink} to={NOTIFICATIONS_DOC_TEMPORARINESS_ROUTER} activeClassName={`list-item-button-selected`}>
                            <ListItemText primary={'暫存文書'} />
                            {!!targetMenuCount(sourceData, 5) && <Chip className="count-dot" label={targetMenuCount(sourceData, 5)} color={'error'} />}
                        </ListItemButton>
                    </List> */}
                <List component="div" className="menu" disablePadding>
                    <ListItemButton component={NavLink} to={NOTIFICATIONS_UPDATE_VERSION_ROUTER} activeClassName={`list-item-button-selected`}>
                        <ListItemText primary={'新版本'} />
                        {!!targetMenuCount(sourceData, 6) && <Chip className="count-dot" label={targetMenuCount(sourceData, 6)} color={'error'} />}
                    </ListItemButton>
                </List>
            </Box>
        </Box>
    );
};

export default NotificationNavBox;
