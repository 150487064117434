import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { merge, isEmpty, cloneDeep, keyBy } from 'lodash';
import { Grid, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { Alert, Button, Card, CheckboxField, DateField, MenuItem, SelectField, TextField, Typography, Box, Stack } from '@common/components/';
import { UserCircle as UserCircleIcon, Dollar as DollarIcon, Edit as EditIcon } from '@common/SvgIcon/';
import { ReadTextField, SignBox } from '@icoach/components/';
import { formatCurrencyFn, getErrorModuleStatus, pathRouterShit, refIsRequiredError } from '@util/utils';
import useToggleDialog from '@util/hook/useToggleDialog';
import useMessageDialog from '@util/hook/useMessageDialog';
import useShowPDF from '@util/hook/useShowPDF';
import { displayDateText, initialDate, parseDate } from '@util/moment';
import useDocMembershipQuitApi from '@apis/useDocMembershipQuitApi';
import PromptDialog from '@icoach/components/dialog/PromptDialog';
import { default as termsData } from '@icoach/documents/membership/quit/terms';
import {
    MembershipQuitSettlementBy101,
    MembershipQuitSettlementBy102,
    MembershipQuitTrialCalculationBy101,
    MembershipQuitTrialCalculationBy102,
} from '@icoach/documents/membership/quit/MembershipQuitComponents';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import {
    ExtensionReasonsDialog,
    GiftReasonsDialog,
    MembershipBillingRecordDialog,
    MembershipRecordDialog,
    SuspendReasonsDialog,
} from '@icoach/documents/membership/dialog';
import { QuitAllRentCabinetDialog } from '@icoach/rentCabinet/dialog/';
import { getCheckboxValue, setCheckboxValue } from '@icoach/documents/tools';
import { TermContent } from '@icoach/documents/components/';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER } from '@icoach/router/routerPath';
import { DateTimeStandard } from '../../../../components/pickers/enums/DateTimeStandard';

const formatCurrency = (target) => {
    return formatCurrencyFn(target, { symbol: '$' });
};

const WorkspaceBox = (props) => {
    const { handleOpenDialog } = props;

    return (
        <Box className="workspace-box">
            <SpeedDial ariaLabel="SpeedDial openIcon example" icon={<SpeedDialIcon openIcon={<EditIcon />} />}>
                {/* {Boolean(contractID) && (
                    <SpeedDialAction icon={<DownloadIcon />} tooltipTitle={'下載申請書'} onClick={() => handleOnDownload({ memberID, contractID })} />
                )} */}
                <SpeedDialAction icon={<UserCircleIcon />} tooltipTitle={'會籍紀錄'} onClick={() => handleOpenDialog(dialogTypeMap.membershipRecord)} />
                <SpeedDialAction
                    icon={<DollarIcon htmlColor="#a45bc8" />}
                    tooltipTitle={'收費明細'}
                    onClick={() => handleOpenDialog(dialogTypeMap.membershipBillingRecord)}
                />
            </SpeedDial>
        </Box>
    );
};

const MembershipProgramSection = (props) => {
    const { data = {} } = props;
    const { joinFee, membership = {} } = data;
    const { paymentTypeText, monthlyFee, membershipTermText, startDate, endDate } = membership;
    return (
        <React.Fragment>
            <Card.CardHeader title="合約方案" className="bg-light text-center" />
            <Card.CardContent className="px-4 py-5">
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <ReadTextField label="付款方式">{paymentTypeText}</ReadTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <ReadTextField label="月費">{formatCurrency(monthlyFee)}</ReadTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <ReadTextField label="入會費">{formatCurrency(joinFee)}</ReadTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <ReadTextField label="合約長度">{membershipTermText}</ReadTextField>
                    </Grid>
                    <Grid item xs={8}>
                        <ReadTextField label="會籍期間">{displayDateText(startDate, endDate)}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

const FeedbackSection = React.forwardRef((props, ref) => {
    const { data = {}, resource = {}, isReadOnly = false, errorModule, doDocMembershipQuitRangeFeeApi, handleOpenDialog } = props;
    const { coachOptions = [], quitReasonTypeOptions = [] } = resource;
    const { quitDate, employeeID, reasonType, reasonText, applyDate, isExtendEndDate } = data;
    const employeeRef = useRef(null);
    const applyDateRef = useRef(null);
    const reasonTypeRef = useRef(null);
    const reasonTextRef = useRef(null);
    const rentCabinetConfirmRef = useRef(null);
    const isExtendEndDateRef = useRef(null);

    const handleChangeRangeFee = (key, val) => {
        const params = {
            [key]: val,
        };
        doDocMembershipQuitRangeFeeApi && doDocMembershipQuitRangeFeeApi(params);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};

                if (applyDateRef.current && applyDateRef.current.getResult) {
                    result = Object.assign(result, { applyDate: applyDateRef.current.getResult() });
                }

                if (employeeRef.current && employeeRef.current.getResult) {
                    result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
                }

                if (reasonTypeRef.current && reasonTypeRef.current.getResult) {
                    result = Object.assign(result, {
                        reasonType: reasonTypeRef.current.getResult(),
                    });
                }

                if (reasonTextRef.current && reasonTextRef.current.getResult) {
                    result = Object.assign(result, {
                        reasonText: reasonTextRef.current.getResult(),
                    });
                }

                if (isExtendEndDateRef.current && isExtendEndDateRef.current.getResult) {
                    result = Object.assign(result, {
                        isExtendEndDate: getCheckboxValue(isExtendEndDateRef.current.getResult(), {
                            isMultiple: false,
                        }),
                    });
                }
                return result;
            },
            isError: () => refIsRequiredError(applyDateRef, employeeRef, reasonTypeRef, reasonTextRef, rentCabinetConfirmRef, isExtendEndDateRef),
        }),
        // eslint-disable-next-line
        []
    );
    return (
        <React.Fragment>
            <Card.CardHeader title="填寫表單" className="bg-light text-center" />
            <Card.CardContent className="px-4 py-5">
                <FieldGroup label="表單資訊">
                    <Grid item xs={4}>
                        <DateField
                            key={initialDate(applyDate)}
                            ref={applyDateRef}
                            defaultValue={initialDate(applyDate)}
                            label={'申請日期'}
                            onChange={(e, val) => handleChangeRangeFee('applyDate', val)}
                            {...getErrorModuleStatus(errorModule, 'applyDate')}
                            readOnly={isReadOnly}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ReadTextField label="會籍終止生效日">{displayDateText(quitDate)}</ReadTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectField
                            label="經辦教練"
                            key={employeeID}
                            ref={employeeRef}
                            defaultValue={employeeID}
                            {...getErrorModuleStatus(errorModule, 'employeeID')}
                            readOnly={isReadOnly}
                            displayEmpty
                            fullWidth
                            required
                        >
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {Array.isArray(coachOptions) &&
                                coachOptions.map(({ text, value }) => (
                                    <MenuItem key={value} value={value}>
                                        {text}
                                    </MenuItem>
                                ))}
                        </SelectField>
                    </Grid>
                </FieldGroup>
                <FieldGroup label="意見回饋">
                    <Grid item xs={12}>
                        <CheckboxField
                            ref={isExtendEndDateRef}
                            defaultValue={setCheckboxValue(isExtendEndDate)}
                            readOnly={isReadOnly}
                            onChange={(e, val) => handleChangeRangeFee('isExtendEndDate', val)}
                        >
                            <CheckboxField.Checkbox label="已與會員協商可運動到月底，故調整會籍終止生效日" value={true} />
                        </CheckboxField>
                    </Grid>
                    <Grid item xs={8}>
                        <SelectField
                            label="終止原因"
                            key={reasonType}
                            ref={reasonTypeRef}
                            defaultValue={reasonType}
                            onChange={(e, n, val) => handleChangeRangeFee('reasonType', val)}
                            {...getErrorModuleStatus(errorModule, 'reasonType')}
                            readOnly={isReadOnly}
                            displayEmpty
                            fullWidth
                            required
                        >
                            {!isEmpty(quitReasonTypeOptions) &&
                                quitReasonTypeOptions.map((options) => (
                                    <MenuItem key={options.value} value={options.value}>
                                        {options.text}
                                    </MenuItem>
                                ))}
                        </SelectField>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="誠摯邀請您對於店舖服務，提供寶貴的意見"
                            key={reasonText}
                            ref={reasonTextRef}
                            defaultValue={reasonText}
                            {...getErrorModuleStatus(errorModule, 'reasonText')}
                            readOnly={isReadOnly}
                            multiline
                            fullWidth
                            rows={4}
                        />
                    </Grid>
                </FieldGroup>
                {!isReadOnly && (
                    <FieldGroup>
                        <Grid item xs={12}>
                            <Stack direction={'row'} alignItems="start">
                                <CheckboxField ref={rentCabinetConfirmRef} readOnly={isReadOnly} required>
                                    <CheckboxField.Checkbox label="已辦理置物櫃退租作業" value={true} />
                                </CheckboxField>
                                <Button variant="outlined" onClick={() => handleOpenDialog(dialogTypeMap.quitallcabinets)}>
                                    辦理租櫃退租
                                </Button>
                            </Stack>
                        </Grid>
                    </FieldGroup>
                )}
            </Card.CardContent>
        </React.Fragment>
    );
});

const SettlementSection = React.forwardRef((props, ref) => {
    const { data, isReadOnly, errorModule, handleOpenDialog, doDocMembershipQuitRangeFeeApi } = props;
    const { contractVersion, isConfirm, signImg } = data;
    const trialCalculationRef = useRef();
    const settlementRef = useRef();
    const isConfirmRef = useRef();
    const signImgRef = useRef();

    useImperativeHandle(ref, () => ({
        getResult: () => {
            let result = {};

            if (trialCalculationRef && trialCalculationRef.current.getResult) {
                result = Object.assign(result, trialCalculationRef.current.getResult());
            }

            if (settlementRef && settlementRef.current.getResult) {
                result = Object.assign(result, settlementRef.current.getResult());
            }

            if (isConfirmRef.current && isConfirmRef.current.getResult) {
                result = Object.assign(result, {
                    isConfirm: getCheckboxValue(isConfirmRef.current.getResult(), {
                        isMultiple: false,
                    }),
                });
            }

            if (signImgRef.current && signImgRef.current.getResult) {
                result = Object.assign(result, {
                    signImg: signImgRef.current.getResult(),
                });
            }

            return result;
        },
        isError: () => refIsRequiredError(trialCalculationRef, settlementRef, isConfirmRef, signImgRef),
    }));

    return (
        <React.Fragment>
            <Card.CardHeader title="費用計算" className="bg-light text-center" />
            <Card.CardContent className="px-4 py-5">
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {String(contractVersion) === '101' && (
                            <MembershipQuitTrialCalculationBy101
                                ref={trialCalculationRef}
                                data={data}
                                errorModule={errorModule}
                                isReadOnly={isReadOnly}
                                handleOpenDialog={handleOpenDialog}
                                doDocMembershipQuitRangeFeeApi={doDocMembershipQuitRangeFeeApi}
                            />
                        )}
                        {['102', '103'].indexOf(String(contractVersion)) !== -1 && (
                            <MembershipQuitTrialCalculationBy102
                                ref={trialCalculationRef}
                                data={data}
                                errorModule={errorModule}
                                isReadOnly={isReadOnly}
                                handleOpenDialog={handleOpenDialog}
                                doDocMembershipQuitRangeFeeApi={doDocMembershipQuitRangeFeeApi}
                            />
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {String(contractVersion) === '101' && (
                            <MembershipQuitSettlementBy101
                                ref={settlementRef}
                                data={data}
                                errorModule={errorModule}
                                isReadOnly={isReadOnly}
                                handleOpenDialog={handleOpenDialog}
                                doDocMembershipQuitRangeFeeApi={doDocMembershipQuitRangeFeeApi}
                            />
                        )}
                        {['102', '103'].indexOf(String(contractVersion)) !== -1 && (
                            <MembershipQuitSettlementBy102
                                ref={settlementRef}
                                data={data}
                                errorModule={errorModule}
                                isReadOnly={isReadOnly}
                                handleOpenDialog={handleOpenDialog}
                                doDocMembershipQuitRangeFeeApi={doDocMembershipQuitRangeFeeApi}
                            />
                        )}
                    </Grid>
                </Grid>
                <div className="mt-5">
                    <FieldGroup label="注意事項">
                        <Grid item xs={12}>
                            <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                為保障個人權益，完成以上所有條款 / 流程，方視為會員權終止手續之完成。
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Alert severity="success" variant="filled" color="success" className="alert-justify-content-start">
                                謝謝您這段時間選擇 Curves 陪伴運動，相信妳了解持續運動帶給妳的好處，期待您會將運動帶進生活， Curves 祝福妳。
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField
                                ref={isConfirmRef}
                                key={isConfirm}
                                defaultChecked={setCheckboxValue(isConfirm)}
                                {...getErrorModuleStatus(errorModule, 'isConfirm')}
                                readOnly={isReadOnly}
                                required
                            >
                                <CheckboxField.Checkbox label="本人已瞭解、詳讀會員權終止條款，並同意遵守。" value={true} />
                            </CheckboxField>
                        </Grid>
                        <Grid item xs={6}>
                            <SignBox
                                ref={signImgRef}
                                title="會員簽名"
                                key={signImg}
                                defaultValue={signImg}
                                {...getErrorModuleStatus(errorModule, 'signImg')}
                                readOnly={isReadOnly}
                            />
                        </Grid>
                    </FieldGroup>
                </div>
            </Card.CardContent>
        </React.Fragment>
    );
});

const MembershipQuitContent = (props) => {
    const { isReadOnly = false, onReturnHistory } = props;
    const { referenceID: referenceIDProps, contractID: contractIDProps, peopleType: peopleTypeProps } = useParams();
    const history = useHistory();
    const setMessageDialog = useMessageDialog();
    const {
        getDocMembershipQuitApi,
        postDocMembershipQuitApi,
        postDocMembershipQuitTemporaryApi,
        postDocMembershipQuitRangeFeeApi,
    } = useDocMembershipQuitApi();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const showPDF = useShowPDF();
    // 判斷是否阻擋
    const [showPrompt, setShowPrompt] = useState(true);
    const [data, setData] = useState({});
    const [options, setOptions] = useState({});
    const [isMounted, setMounted] = useState(false);
    const [errorModule, setErrorModule] = useState({});
    const {
        membershipID,
        contractID,
        memberID,
        contractVersion,
        contractVersionText,
        isCompleteContract, // 是否為正式文件
    } = data;
    const calculateFeeRef = useRef();
    const feedbackSectionRef = useRef();
    const apiSendingRef = useRef(false);

    const getParams = () => {
        let result = { ...data };
        if (calculateFeeRef.current && calculateFeeRef.current.getResult) {
            result = Object.assign(result, calculateFeeRef.current.getResult());
        }
        if (feedbackSectionRef.current && feedbackSectionRef.current.getResult) {
            result = Object.assign(result, feedbackSectionRef.current.getResult());
        }
        delete result.membership;
        return result;
    };

    // 取得申請書資料
    const doGetDocMembershipQuitApi = async (contractID, referenceID) => {
        const res = await getDocMembershipQuitApi(contractID, { id: referenceID });
        if (res) {
            const { resource, ...others } = res;
            setData(others);
            setOptions(resource);
            setMounted(true);
        }
    };

    // 新增/重填退會合約
    const doPostDocMembershipQuitApi = async (contractID = 0, params) => {
        const res = await postDocMembershipQuitApi(contractID, params);
        if (res) {
            if (res.isError) {
                setErrorModule(keyBy(res.result, 'key'));
                apiSendingRef.current = false;
            } else {
                setShowPrompt(false);
                const { completedPath, canRefundAll } = res;
                const handleCompleted = (completedPath) => {
                    const routerPath = pathRouterShit(DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER, {
                        peopleType: peopleTypeProps,
                        referenceID: referenceIDProps,
                        contractID: contractID,
                    });
                    showPDF({
                        open: true,
                        pdfUrl: completedPath,
                    });
                    setErrorModule({});
                    history.push(routerPath);
                };

                if (canRefundAll) {
                    handleCompleted(completedPath);
                } else {
                    setMessageDialog({
                        open: true,
                        title: '提醒',
                        MsgComp: () => {
                            return (
                                <React.Fragment>
                                    <Typography>若有費用需退回會員，不是在本系統處理的批次扣款與發票開立，請特案處理唷！</Typography>
                                    <Typography>註：</Typography>
                                    <ol>
                                        <li>金流：請採現金退款或填寫紙本紙本刷退申請書向銀行刷退。</li>
                                        <li>發票：請至綠界網頁後台執行發票作廢或折讓。</li>
                                    </ol>
                                </React.Fragment>
                            );
                        },
                        onConfirm: () => handleCompleted(completedPath),
                    });
                }
            }
        } else {
            setErrorModule({});
            apiSendingRef.current = false;
        }
    };

    // 暫存申請書
    const doDocMembershipQuitTemporaryApi = async (params) => {
        const resp = await postDocMembershipQuitTemporaryApi(params);
        if (resp) {
            setShowPrompt(false);
            window.setTimeout(() => onReturnHistory && onReturnHistory(), 5);
        } else {
            apiSendingRef.current = false;
        }
    };

    const doDocMembershipQuitRangeFeeApi = async (params) => {
        const handleParams = (params) => {
            let temporarySourceData = cloneDeep(data);
            temporarySourceData = merge(temporarySourceData, getParams());
            temporarySourceData = merge(temporarySourceData, params);
            return {
                membershipID: temporarySourceData.membershipID,
                applyDate: parseDate(temporarySourceData.applyDate, DateTimeStandard.DisplayUtcFull),
                firstMonthDay: temporarySourceData.firstMonthDay,
                contractMonthTerm: temporarySourceData.contractMonthTerm,
                suspendMonth: temporarySourceData.suspendMonth,
                suspendDay: temporarySourceData.suspendDay,
                giftMonth: temporarySourceData.giftMonth,
                giftDay: temporarySourceData.giftDay,
                usedMonth: temporarySourceData.usedMonth,
                usedDay: temporarySourceData.usedDay,
                avgMonthFee: temporarySourceData.avgMonthFee,
                processingFee: temporarySourceData.processingFee,
                unusedExtensionDay: temporarySourceData.unusedExtensionDay,
                unusedExtensionMonth: temporarySourceData.unusedExtensionMonth,
                reasonType: temporarySourceData.reasonType,
                isFreeQuitFee: temporarySourceData.isFreeQuitFee,
                lastMonthlyFee: temporarySourceData.lastMonthlyFee,
                isExtendEndDate: temporarySourceData.isExtendEndDate,
            };
        };

        const resp = await postDocMembershipQuitRangeFeeApi(handleParams(params));
        if (resp) {
            setData((prev) => ({
                ...prev,
                ...resp,
            }));
        }
        apiSendingRef.current = false;
    };

    // 送出 按鈕
    const handleSubmitCreate = () => {
        if (apiSendingRef.current) return false;
        const isError = refIsRequiredError(calculateFeeRef, feedbackSectionRef);
        if (!isError) {
            const params = getParams();
            apiSendingRef.current = true;
            doPostDocMembershipQuitApi(contractIDProps, params);
        } else {
            window.scrollTo({
                top: 705,
                behavior: 'smooth',
            });
        }
    };

    // 點擊暫存 按鈕
    const handleSubmitTemporary = () => {
        if (apiSendingRef.current) return false;
        const params = getParams();
        apiSendingRef.current = true;
        doDocMembershipQuitTemporaryApi(params);
    };

    useEffect(
        () => {
            doGetDocMembershipQuitApi(contractIDProps, referenceIDProps);
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        []
    );

    if (!isMounted) return null;

    return (
        <React.Fragment>
            <TermContent data={termsData[contractVersion].data || []} className="mb-4" title="會員權終止條款" subheader="依教育部體育署健身中心定型化契約" />
            <Card>
                <MembershipProgramSection data={data} />
                <FeedbackSection
                    ref={feedbackSectionRef}
                    data={data}
                    resource={options}
                    errorModule={errorModule}
                    isReadOnly={isReadOnly}
                    handleOpenDialog={handleOpenDialog}
                    doDocMembershipQuitRangeFeeApi={doDocMembershipQuitRangeFeeApi}
                />
                <SettlementSection
                    ref={calculateFeeRef}
                    data={data}
                    errorModule={errorModule}
                    isReadOnly={isReadOnly}
                    handleOpenDialog={handleOpenDialog}
                    doDocMembershipQuitRangeFeeApi={doDocMembershipQuitRangeFeeApi}
                />
            </Card>
            <Card>
                <Card.CardContent>
                    {!isReadOnly ? (
                        <Box className="btn-group text-center my-2">
                            {!isCompleteContract && (
                                <Button name="temporary" variant="outlined" color="secondary" className="btn-minWidth" onClick={handleSubmitTemporary}>
                                    暫存檔案
                                </Button>
                            )}
                            <Button name="create" type="submit" variant="contained" color="secondary" className="btn-minWidth" onClick={handleSubmitCreate}>
                                確認送出
                            </Button>
                        </Box>
                    ) : (
                        <Box className="btn-group text-center my-1">
                            <Button variant="contained" color="secondary" className="btn-minWidth" onClick={() => onReturnHistory(referenceIDProps)}>
                                返回
                            </Button>
                        </Box>
                    )}
                    <Typography variant="body2" className="text-center mb-0 px-2 font-color-3 my-4">
                        {contractVersionText}
                    </Typography>
                </Card.CardContent>
            </Card>
            {Boolean(membershipID) && (
                <WorkspaceBox memberID={memberID} contractID={contractID} isCompleteContract={isCompleteContract} handleOpenDialog={handleOpenDialog} />
            )}
            <MembershipRecordDialog
                open={openTarget === dialogTypeMap.membershipRecord}
                memberID={memberID}
                membershipID={membershipID}
                onClose={handleCloseDialog}
            />
            <MembershipBillingRecordDialog
                open={openTarget === dialogTypeMap.membershipBillingRecord}
                memberID={memberID}
                membershipID={membershipID}
                onClose={handleCloseDialog}
            />
            <GiftReasonsDialog open={openTarget === dialogTypeMap.gift} memberID={memberID} membershipID={membershipID} onClose={handleCloseDialog} />
            <SuspendReasonsDialog open={openTarget === dialogTypeMap.suspension} memberID={memberID} membershipID={membershipID} onClose={handleCloseDialog} />
            <ExtensionReasonsDialog open={openTarget === dialogTypeMap.extension} memberID={memberID} membershipID={membershipID} onClose={handleCloseDialog} />
            <QuitAllRentCabinetDialog open={openTarget === dialogTypeMap.quitallcabinets} memberID={memberID} onClose={handleCloseDialog} />
            {!isReadOnly && <PromptDialog when={showPrompt} />}
        </React.Fragment>
    );
};

export default MembershipQuitContent;
