import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import _ from 'lodash';
import clsx from 'clsx';
import { FilterInBodyScannerSearch, FilterInBodyScannerLabel } from '@icoach/inbody/overview/FilterInBodyScannerTemplate';
import { FilterUnInBodyScannerSearch, FilterUnInBodyScannerLabel } from '@icoach/inbody/overview/FilterUnInBodyScannerTemplate';
import { FilterScannerResultSearch } from '@icoach/inbody/overview/FilterScannerResultTemplate';
import useInBody from '@icoach/inbody/components/useInBody';
import { initBookmark } from '@icoach/members/staticData';
import { Card, Form, Button, SelectField, MenuItem, Stack, Typography } from '@common/components/';
import useMemberFilterApi from '@apis/useMemberFilterApi';
import useMemberFilterExportApi from '@apis/useMemberFilterExportApi';
import { objectToQueryString, paramsToObject } from '@util/utils';
import { INBODY_OVERVIEW_FILTER_NUM_PATH } from '@icoach/router/routerPath';
import useMessageDialog from '@util/hook/useMessageDialog';
import { FilterMemberComparisonLabel, FilterMemberComparisonSearch } from '@icoach/inbody/overview/FilterInMemberComparisonTemplate';
import useWindowScrollPositions from '@util/hook/useWindowScrollPositions';

// 量身快速名單 searchbar
const InbodyOverviewFilterSearchBar = React.forwardRef((props, ref) => {
    const { className: classNameProps, targetType: quickType } = props;
    const history = useHistory();
    const { setSourceData, setRowsLabel, setSortOption, bookmark, setBookmarkNum, setBookmarkSortBy, resource, setResource, reSetCheckedItems } = useInBody();
    const { measuredOrderBy: measuredSortOption = [], notYetMeasureOrderBy: unInBodyScannerSortOption = [], measureResultTypeOrderByOption } = resource;
    const {
        getMemberFilterOptionsApi,
        getNotYetMeasureFilterDataApi,
        getMeasuredFilterDataApi,
        getMeasureResultExcelApi,
        getMeasuredResultApi,
    } = useMemberFilterApi();
    const { getMemberFilterMeasuredApi, getMemberFilterMeasureNotYetApi, getMemberFilterMeasureResultApi } = useMemberFilterExportApi();
    const [paramsData, setParamsData] = useState({}); // 初始預設搜尋條件使用,其他狀況下無用
    const [isMounted, setMounted] = useState(false);
    const searchPanelsRef = useRef(null);
    const preloadRef = useRef(false);
    const prevFilterTypeRef = useRef(quickType);
    const setMessageDialog = useMessageDialog();
    useWindowScrollPositions();

    const getParams = (targetPage = {}) => {
        let params = { ...targetPage };
        if (searchPanelsRef && searchPanelsRef.current) {
            Object.assign(params, {
                ...searchPanelsRef.current.getResult(),
            });
        }
        return params;
    };

    const handleTypeChange = (_dom, { props }) => {
        const _r = INBODY_OVERVIEW_FILTER_NUM_PATH(props.value);
        history.push(_r);
        preloadRef.current = false;
    };

    const handleSearchClick = () => {
        reSetCheckedItems();
        const params = getParams(initBookmark(bookmark?.sortBy));
        const _r = `${INBODY_OVERVIEW_FILTER_NUM_PATH(quickType)}${objectToQueryString(params)}`;
        history.replace(_r);
        setBookmarkNum(1);
        doSearchApi(params);
        preloadRef.current = true; // 防止切換名單後無法更多與排序
    };

    const handleExcelOnClick = () => {
        const params = getParams(initBookmark(bookmark?.sortBy));
        doExcelSendEmailApi(params);
        preloadRef.current = true; // 防止切換名單後無法更多與排序
    };

    const doMemberFilterOptionsApi = async () => {
        const resp = await getMemberFilterOptionsApi();
        if (resp) {
            setResource(resp);
        }
        // 重新載入頁面才會觸發，當下的頁面切換quickType不會進來
        const { location } = history;
        const par = paramsToObject(location.search);
        let pageModel = initBookmark(); // bookmark跟不上同步,故用此參數
        if (!_.isEmpty(par)) {
            setBookmarkNum(par.pageIndex);
            setBookmarkSortBy(par.sortBy);
            pageModel.sortBy = par.sortBy;
            pageModel.pageIndex = par.pageIndex;
            setParamsData(par);
        }
        setMounted(true);
        preloadRef.current = true;
        !_.isEmpty(par) && doSearchApi(getParams(pageModel));
    };

    const doSearchApi = async (params) => {
        const doApi = memberFilterMap[quickType]['doApi'];
        const resp = await doApi(params);
        if (resp) {
            const { list, ...pageParams } = resp;
            setSourceData((prev) => {
                let targetList = [...list];
                if (String(params.pageIndex) !== '1') {
                    targetList = [...(Array.isArray(prev.list) ? prev.list : []), ...targetList];
                }
                return {
                    ...pageParams,
                    list: targetList,
                };
            });
            const _r = `${INBODY_OVERVIEW_FILTER_NUM_PATH(quickType)}${objectToQueryString(params)}`;
            history.replace(_r);
        }
        if (!_.isEmpty(paramsData)) {
            setParamsData({}); // 防止換回同類型初始參數
        }
    };

    const doExcelSendEmailApi = async (params) => {
        const doApi = memberFilterMap[quickType]['excelApi'];
        const resp = await doApi(params);

        if (resp) {
            let { message, url } = resp;
            setMessageDialog({
                open: true,
                title: '個資資訊安全原則',
                MsgComp: () => (
                    <div>
                        <Typography className="word-break-break-word">{message}</Typography>
                        <p />
                        <Button to={url} color="error">
                            保管責任書
                        </Button>
                    </div>
                ),
            });
        }
    };

    const memberFilterMap = useMemo(
        () => ({
            1: {
                description: '未量身名單',
                rowLabel: FilterUnInBodyScannerLabel,
                sortOption: unInBodyScannerSortOption,
                Component: FilterUnInBodyScannerSearch,
                excelApi: getMemberFilterMeasureNotYetApi,
                doApi: getNotYetMeasureFilterDataApi,
            },
            2: {
                description: '已量身名單',
                rowLabel: FilterInBodyScannerLabel,
                sortOption: measuredSortOption,
                Component: FilterInBodyScannerSearch,
                excelApi: getMemberFilterMeasuredApi,
                doApi: getMeasuredFilterDataApi,
            },
            3: {
                description: '量身成果名單(匯出)',
                rowLabel: null,
                Component: FilterScannerResultSearch,
                excelApi: null,
                doApi: getMeasureResultExcelApi,
            },
            4: {
                description: '會員量身成果比較表',
                rowLabel: FilterMemberComparisonLabel,
                sortOption: measureResultTypeOrderByOption,
                Component: FilterMemberComparisonSearch,
                excelApi: getMemberFilterMeasureResultApi,
                doApi: getMeasuredResultApi,
            },
        }),
        // eslint-disable-next-line
        [resource]
    );

    useEffect(
        () => {
            const filterTypeIsSame = String(prevFilterTypeRef.current) === String(quickType);
            const { rowLabel = [], sortOption = [] } = memberFilterMap[quickType];

            setRowsLabel(rowLabel);
            setSortOption(sortOption);

            if (!filterTypeIsSame) {
                setBookmarkSortBy(1);
                prevFilterTypeRef.current = quickType;
            }
            return () => {
                setSourceData([]);
                setRowsLabel([]);
            };
        },
        // eslint-disable-next-line
        [quickType, memberFilterMap]
    );

    // 換頁
    useEffect(
        () => {
            if (preloadRef.current) {
                const params = getParams(bookmark);
                if (bookmark && String(bookmark.pageIndex) !== '1') {
                    doSearchApi(params);
                }
            }
        },
        // eslint-disable-next-line
        [bookmark.pageIndex]
    );

    // 變更排序
    useEffect(
        () => {
            if (preloadRef.current) {
                const params = getParams(bookmark);
                doSearchApi(params);
            }
        },
        // eslint-disable-next-line
        [bookmark.sortBy]
    );

    useEffect(
        () => {
            if (_.isEmpty(resource)) {
                doMemberFilterOptionsApi();
            }
            return () => {
                setResource({});
                setBookmarkSortBy(1);
                setBookmarkNum(1);
            };
        },
        // eslint-disable-next-line
        []
    );

    const CompPanel = React.useMemo(
        () => {
            if (!isMounted) return null;
            return quickType ? memberFilterMap[quickType]['Component'] : null;
        },
        // eslint-disable-next-line
        [isMounted, quickType]
    );

    return (
        <Card className={clsx(classNameProps)}>
            <Card.CardContent className="px-4 py-3">
                <Form onSubmit={handleSearchClick}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <SelectField label="篩選名單" value={quickType} onChange={handleTypeChange} fullWidth>
                                <MenuItem value="1">未量身名單</MenuItem>
                                <MenuItem value="2">已量身名單</MenuItem>
                                <MenuItem value="3">量身成果名單(匯出)</MenuItem>
                                <MenuItem value="4">會員成果查詢</MenuItem>
                            </SelectField>
                        </Grid>
                        {isMounted && CompPanel ? <CompPanel key={quickType} ref={searchPanelsRef} paramsData={paramsData} resource={resource} /> : null}
                        <Grid xs={3} item>
                            <Stack direction={'row'} spacing={1} className="pt-4">
                                <Button variant="contained" type="submit">
                                    搜尋
                                </Button>
                                {memberFilterMap[quickType]['excelApi'] && (
                                    <Button variant="contained" color="success" onClick={handleExcelOnClick}>
                                        下載Excel
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            </Card.CardContent>
        </Card>
    );
});
export default InbodyOverviewFilterSearchBar;
