import React from 'react';
import useMessageDialog from '@util/hook/useMessageDialog';
import { Box, Typography } from '@common/components/';
import clsx from 'clsx';

const MemoControllerBox = (props) => {
    const {
        className,
        textEllipsisColumns = 2,
        width = '180px',
        dialogTitle = '備註',
        typographyProps = {
            className: '',
        },
        data,
        isTableSpace = true,
    } = props;
    const whiteSpaceNormal = isTableSpace ? 'white-space-normal' : '';
    const setMessageDialog = useMessageDialog();
    const handleClickNotes = () => {
        if (data && data.length > 0) {
            setMessageDialog({
                open: true,
                title: dialogTitle,
                msg: data,
            });
        }
    };

    return (
        <Box
            className={clsx(`memo-controller-box text-ellipsis-columns-${textEllipsisColumns}`, className, {
                'cursor-pointer': data && data.length > 0,
            })}
            style={{ width }}
            onClick={handleClickNotes}
        >
            <Typography className={clsx(whiteSpaceNormal, typographyProps.className)}>{data}</Typography>
        </Box>
    );
};

export default MemoControllerBox;
