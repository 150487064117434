import React, { useCallback, useRef } from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import Tablenization from '../../components/Tablenization';
import { DateLabel } from '../components';
import { isValidDate } from '../../../util/moment';
// import { getRowsDataMaxValue, getRowsDataTodayValue, getRowsDataTotal } from '../staticData';

const rowsLabel = [
    {
        cellKey: 'date',
        headerLabel: '日期',
        width: 12,
        formatCell: (date) => {
            if (isValidDate(date)) return <DateLabel date={date} />;
            return date;
        },
    },
    {
        cellKey: 'newSales',
        headerLabel: '新銷售',
        align: 'center',
        minWidth: 98,
    },
    {
        cellKey: 'drop',
        headerLabel: '退會',
        align: 'center',
        minWidth: 98,
    },
    {
        cellKey: 'leave',
        headerLabel: '請假',
        align: 'center',
        minWidth: 98,
    },
    // {
    //     cellKey: 'renewed',
    //     headerLabel: '已續約',
    //     align: 'center',
    //     minWidth: 98,
    // },
    // {
    //     cellKey: 'notYetRenew',
    //     headerLabel: '應續約',
    //     align: 'center',
    //     minWidth: 98,
    // },
    {
        cellKey: 'moveOut',
        headerLabel: '移出',
        align: 'center',
        minWidth: 98,
    },
    {
        cellKey: 'moveIn',
        headerLabel: '移入',
        align: 'center',
        minWidth: 98,
    },
    {
        cellKey: 'increase',
        headerLabel: '純增',
        align: 'center',
        minWidth: 98,
    },
];

const MembershipDailyTable = (props) => {
    const { rowsData, cumulative, className } = props;
    const labelTitle = useRef(rowsLabel);

    const handleRowsData = useCallback(
        () => {
            let totalRow = [
                {
                    ...cumulative,
                    date: '最新數字',
                },
            ];
            let array = [...rowsData];
            if (!isEmpty(totalRow)) array = totalRow.concat(array);
            return array;
        },
        // eslint-disable-next-line
        [rowsData]
    );

    return (
        <Tablenization cardProps={{ className: clsx('daily-table', className) }}>
            <Tablenization.TableRowHeader headerRow={labelTitle.current} />
            <Tablenization.TableRowBody
                headerRow={labelTitle.current}
                rowsData={handleRowsData()}
                bodyRowClass={{ key: 'date', value: '總計', className: 'total-row' }}
            />
        </Tablenization>
    );
};

export default MembershipDailyTable;
