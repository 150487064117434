import React, { useState } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Button, Card, Typography, List, ListItemText, ListItem } from '../../../components';
import { formatCurrencyFn } from '../../../util/utils';
import { SettleCashDialog } from '../dialog';
import { getToday, parseDate } from '../../../util/moment';
import useMessageDialog from '../../../util/hook/useMessageDialog';
import useOrderAccountBookApi from '../../../apis/useOrderAccountBookApi';
import { useSettleCashContext } from '../context/SettleCashContext';

const SettleCashContent = (props) => {
    const { data = {}, isShowBtn = false, isShowSettleCashPrice = false, handleOpenDialog } = props;
    const {
        accountingDate,
        fundPrice = 0,
        cashTotalPrice = 0,
        cardTotalPrice = 0,
        cashReturnTotalPrice = 0,
        cardReturnTotalPrice = 0,
        creditPrice = 0,
        settleCashPrice = 0,
        totalPrice = 0,
    } = data;
    const today = getToday();

    // const calculateTotal = () => {
    //     let total = 0;
    //     let _fundPrice = parseInt(fundPrice);
    //     let _cashPrice = parseInt(cashTotalPrice);
    //     let _creditPrice = parseInt(creditPrice);
    //     if (!isNaN(_fundPrice)) total = total + _fundPrice;
    //     if (!isNaN(_cashPrice)) total = total + _cashPrice;
    //     if (!isNaN(_creditPrice)) total = total - _creditPrice;
    //     return total;
    // };

    return (
        <React.Fragment>
            <header className={'settle-cash-box-header'}>
                <Typography className={'font-weight-bold'} variant={'h6'}>
                    今日收支
                </Typography>
                {isShowBtn && parseDate(accountingDate) === parseDate(today) && (
                    <Button variant={'contained'} onClick={handleOpenDialog}>
                        進行結算
                    </Button>
                )}
            </header>
            <List>
                <ListItem className={clsx('settle-cash-box-item')} disableGutters>
                    <ListItemText primary={'零用金'} />
                    <ListItemText className={'text-right'} primary={formatCurrencyFn(fundPrice)} />
                </ListItem>
                <ListItem className={clsx('settle-cash-box-item')} disableGutters>
                    <ListItemText primary={'現金收入'} />
                    <ListItemText
                        className={'price text-right text-success'}
                        primary={formatCurrencyFn(cashTotalPrice)}
                    />
                </ListItem>
                <ListItem className={clsx('settle-cash-box-item')} disableGutters>
                    <ListItemText primary={'現金退費'} />
                    <ListItemText
                        className={'price text-right text-error'}
                        primary={formatCurrencyFn(cashReturnTotalPrice)}
                    />
                </ListItem>
                <ListItem className={clsx('settle-cash-box-item')} disableGutters>
                    <ListItemText primary={'支出'} />
                    <ListItemText
                        className={'price text-right text-error'}
                        primary={formatCurrencyFn(creditPrice)}
                    />
                </ListItem>
                <ListItem className={clsx('settle-cash-box-item total')} disableGutters>
                    <ListItemText primary={'總計'} />
                    <ListItemText className={'text-right'} primary={formatCurrencyFn(totalPrice)} />
                </ListItem>
                {isShowSettleCashPrice && (
                    <ListItem
                        className={clsx('settle-cash-box-item bg-success-light text-success py-2')}
                        disableGutters
                    >
                        <ListItemText primary={'結算金額'} />
                        <ListItemText
                            className={'text-right'}
                            primary={formatCurrencyFn(settleCashPrice)}
                        />
                    </ListItem>
                )}
                <ListItem className={clsx('settle-cash-box-item')} disableGutters>
                    <ListItemText primary={'信用卡收入'} />
                    <ListItemText
                        className={'price text-right'}
                        primary={formatCurrencyFn(cardTotalPrice)}
                    />
                </ListItem>
                <ListItem className={clsx('settle-cash-box-item')} disableGutters>
                    <ListItemText primary={'信用卡退費'} />
                    <ListItemText
                        className={'price text-right'}
                        primary={formatCurrencyFn(cardReturnTotalPrice)}
                    />
                </ListItem>
            </List>
        </React.Fragment>
    );
};
const SettleInformationContent = (props) => {
    const { data = {}, handleOpenDialog } = props;
    const { accountingDate, settleCashPrice = 0, settleEmployeeName, settleNotes } = data;
    const setMessageDialog = useMessageDialog();
    const { deleteOrderAccountBookSettleApi } = useOrderAccountBookApi();
    const { refresh } = useSettleCashContext();

    const doOrderAccountBookItemApi = async (onClose) => {
        let res = await deleteOrderAccountBookSettleApi();
        if (res) {
            refresh && refresh(accountingDate);
            onClose();
        }
    };

    const handleClickConfirm = (e, onClose) => doOrderAccountBookItemApi(onClose);
    const today = getToday();

    const handleDeleteButton = () => {
        setMessageDialog({
            open: true,
            title: '確認刪除',
            msg: '刪除後將無法恢復，請問確定要刪除今日的結帳紀錄嗎？',
            onAsyncConfirm: handleClickConfirm,
        });
    };
    return (
        <React.Fragment>
            <header className={'settle-cash-box-header'}>
                <Typography className={'font-weight-bold'} variant={'h6'}>
                    結算資訊
                </Typography>
                {parseDate(accountingDate) === parseDate(today) && (
                    <div className="btn-group">
                        <Button variant={'outlined'} onClick={handleOpenDialog}>
                            編輯
                        </Button>
                        <Button variant={'contained'} color={'error'} onClick={handleDeleteButton}>
                            刪除
                        </Button>
                    </div>
                )}
            </header>
            <List>
                <ListItem className={clsx('settle-cash-box-item')} disableGutters>
                    <ListItemText
                        className={'settle-info-item'}
                        primary={formatCurrencyFn(settleCashPrice)}
                        secondary={'結算金額'}
                    />
                </ListItem>
                <ListItem className={clsx('settle-cash-box-item')} disableGutters>
                    <ListItemText
                        className={'settle-info-item'}
                        primary={settleEmployeeName}
                        secondary={'擔當'}
                    />
                </ListItem>
                <ListItem className={clsx('settle-cash-box-item')} disableGutters>
                    <ListItemText
                        className={'settle-info-item note'}
                        primary={settleNotes}
                        secondary={'備註'}
                    />
                </ListItem>
            </List>
        </React.Fragment>
    );
};

const SettleCashBox = (props) => {
    const { className, data } = props;
    const { isSettled = false } = data;
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={isSettled ? 12 : 6}>
                    <Card className={clsx('settle-cash-box', className)}>
                        <Card.CardContent>
                            <Grid container spacing={0}>
                                <Grid className={'col'} item xs={isSettled ? 6 : 12}>
                                    <SettleCashContent
                                        data={data}
                                        isShowBtn={!isSettled}
                                        isShowSettleCashPrice={isSettled}
                                        handleOpenDialog={handleOpenDialog}
                                    />
                                </Grid>
                                {isSettled && (
                                    <Grid className={'col'} item xs={6}>
                                        <SettleInformationContent
                                            data={data}
                                            handleOpenDialog={handleOpenDialog}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Card.CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SettleCashDialog open={openDialog} onClose={handleCloseDialog} />
        </React.Fragment>
    );
};

export default SettleCashBox;
