import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import _ from 'lodash';
import { serialize } from '@util/utils';
import { setLoadingData } from '@redux/loading/action';

const inbodyCodePack = ['ME002', 'ME003'];
const exceptionsPack = ['0001', 'FE001', 'FE002', 'MXO001', 'MXT001', 'MXT002', 'MX051', 'MB003', 'MX007', 'MIX001', 'PR0005', 'FC001', 'FC002'];
const exceptionsNoSnacker = ['MX015'];

const httpMethodtemplet = (methodType) => (action = {}) => async (url, parameters, { headerOption = {}, ...other } = {}) => {
    if (methodType === 'delete') parameters = Object.assign({}, { data: parameters });
    if (methodType === 'get' && parameters) {
        const queryString = serialize(parameters);
        if (queryString) url += `?${queryString}`;
        parameters = {};
    }
    if (methodType === 'pdf') {
        methodType = 'get';
        parameters = {};
        headerOption['responseType'] = `arraybuffer`;
        other.fileName = other.fileName || `未設定`;
    }
    const requestInfo = {
        method: methodType,
        url,
        parameters,
        requestHeaderOption: headerOption,
    };

    return await ajaxApi(requestInfo, { ...other, ...action });
};

const ajaxGet = httpMethodtemplet('get');

const ajaxPost = httpMethodtemplet('post');

const ajaxPut = httpMethodtemplet('put');

const ajaxDelete = httpMethodtemplet('delete');

const ajaxPDFDownload = httpMethodtemplet('pdf');

const ajaxApi = async (
    { method, url, parameters, requestHeaderOption },
    { callbackfn = null, isLoadingVisible = true, _showLoading, _snackbar, _history, fileName: _fileName }
) => {
    let sourceData;
    let respondBody;
    let isError = false;
    isLoadingVisible && _showLoading(true);
    try {
        if (method === 'get') {
            respondBody = await Axios[method](url, requestHeaderOption);
        } else {
            respondBody = await Axios[method](url, parameters, requestHeaderOption);
        }
        const { data: resource } = respondBody;
        if (resource.success) {
            if (resource.statusCode === '0000') {
                sourceData = resource.result;
            }
        } else if (`application/vnd.ms-excel` === respondBody['headers']['content-type']) {
            // excel下載
            let excelFile;
            let filename;
            const filenameRegex = /filename\*[^;=\n]*=.*''((['"]).*?|[^;\n]*)/;
            const matches = filenameRegex.exec(respondBody['headers']['content-disposition']);
            if (matches != null && matches[1]) {
                filename = decodeURI(matches[1].replace(/['"]/g, ''));
            }
            if (method === 'post') {
                excelFile = new Blob([resource], { type: 'application/vnd.ms-excel;charset=utf-8' });
            } else {
                excelFile = resource;
            }
            const fileURL = URL.createObjectURL(excelFile);
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = fileURL;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            await link.click();
            document.body.removeChild(link);
            _snackbar('下載excel成功', { variant: 'success' });
            sourceData = true;
        } else if (`application/pdf` === respondBody['headers']['content-type']) {
            // pdf下載
            const blobPack = new Blob([resource], {
                type: 'application/octet-stream',
                'Content-Disposition': 'attachment',
            });
            const fileURL = URL.createObjectURL(blobPack);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `${_fileName}.pdf`;
            await link.click();
            sourceData = true;
        } else {
            let { statusCode, message } = resource;
            statusCode = String(statusCode);
            if (statusCode === '9001' && _history.location.pathname.split('/')[1] !== 'login') {
                _snackbar('已登出，請重新登入', { variant: 'info' });
                localStorage.clear();
                _history.go('/login');
            } else if (inbodyCodePack.indexOf(statusCode) !== -1) {
                // 例外處理 不彈跳錯誤訊息
                sourceData = { message, statusCode, isError: true };
            } else if (exceptionsNoSnacker.indexOf(statusCode) !== -1) {
                sourceData = { message, result: null };
            } else if (exceptionsPack.indexOf(statusCode) !== -1) {
                // 例外處理 彈跳錯誤訊息
                _snackbar(message, { variant: 'error' });
                sourceData = { result: resource.result, isError: true };
            } else if (statusCode === '0002') {
                // 驗證模組失敗 額外訊息捕捉 不與其他共用
                _snackbar('因您有些資料尚末填寫或填寫錯誤，故無法預覽！', { variant: 'error' });
                sourceData = { result: resource.result, isError: true };
            } else if (statusCode === '0006') {
                sourceData = null;
                _snackbar(message, { variant: 'error' });
                _history.push('/club/404');
            } 
            // else if (statusCode === '9999') {
            //     sourceData = resource;
            //     _snackbar(resource.result, { variant: 'error' });
            // }
             else {
                _snackbar(message, { variant: 'error' });
            }
        }
    } catch (error) {
        const { response } = error;
        respondBody = error;
        isError = true;
        if (response) {
            // 此段為測試，Allen todo
            _snackbar(`系統回應發生錯誤(${response.status})`, {
                variant: 'error',
            });
        } else {
            console.error(error);
        }
    }
    isLoadingVisible && _showLoading(false);
    return isError ? sourceData : _.isFunction(callbackfn) ? callbackfn(sourceData, respondBody) : sourceData;
};

const useAjax = () => {
    const _history = useHistory();
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const _dispatch = useDispatch();
    const _showLoading = useCallback(
        (isShow) => {
            _dispatch(setLoadingData(isShow));
        },
        [_dispatch]
    );

    const action = { _showLoading, _snackbar, _history };
    const getAction = useCallback(ajaxGet(action), []);
    const postAction = useCallback(ajaxPost(action), []);
    const putAction = useCallback(ajaxPut(action), []);
    const deleteAction = useCallback(ajaxDelete(action), []);
    const PDFAction = useCallback(ajaxPDFDownload(action), []);
    return {
        get: getAction,
        post: postAction,
        put: putAction,
        delete: deleteAction,
        getPDF: PDFAction,
    };
};

export default useAjax;
