import React, { useImperativeHandle, useRef } from 'react';
import { Grid } from '@mui/material';
import { memberSheet, moreSheet, cellPhoneSheet, contactSheet, rowLabelCheckboxSheet } from '@icoach/components/';
import { DateIntervalField, FormControl, FormLabel, Stack, Typography, TextField } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import { getToday, getSubDays, parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

// 次數區間
const TextInputRangeField = React.forwardRef((props, ref) => {
    const { className, label = '', paramsData, FormLabelTip = '' } = props;

    const startTimes = useRef(null);
    const endTimes = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => {
                    let error = false;
                    const sCounts = startTimes.current.getResult() * 1;
                    const eCounts = endTimes.current.getResult() * 1;
                    if (sCounts > eCounts) {
                        error = true;
                        startTimes.current.setError(error, '運動次數區間錯誤');
                        endTimes.current.setError(error, '');
                    } else if (sCounts < -1 || eCounts < -1) {
                        error = true;
                        !sCounts && startTimes.current.setError(error, '請輸入0以上的數值');
                        !eCounts && endTimes.current.setError(error, '請輸入0以上的數值');
                    } else {
                        startTimes.current.setError(error, '');
                        endTimes.current.setError(error, '');
                    }
                    return error;
                },
                getResult: () => {
                    return {
                        minTimes: startTimes.current.getResult() * 1,
                        maxTimes: endTimes.current.getResult() * 1,
                    };
                },
            };
        },
        // eslint-disable-next-line
        [],
    );

    const formlabelProperties = {
        lightColor: false,
        inline: 'row',
        FormLabelProps: {
            labelTip: FormLabelTip,
        },
    };

    return (
        <FormControl className={className} direction={'column'}>
            {label && <FormLabel {...formlabelProperties}>{label}</FormLabel>}
            <Stack direction="column" style={{ flexGrow: 1 }}>
                <Stack>
                    <TextField ref={startTimes} defaultValue={paramsData.minTimes || 1} maskType="MONEY" fullWidth />
                    <Typography component="div" className="px-1 mt-1">
                        {`-`}
                    </Typography>
                    <TextField ref={endTimes} defaultValue={paramsData.maxTimes || 10} maskType="MONEY" fullWidth />
                </Stack>
            </Stack>
        </FormControl>
    );
});

//********************** 次數名單 **********************//
export const FilterWorkOutTimesSearch = React.forwardRef(({ paramsData }, ref) => {
    const dateRangeRef = useRef(null);
    const excTimesRef = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(dateRangeRef, excTimesRef),
                getResult: () => {
                    const p1 = dateRangeRef.current.getResult() || {};
                    const p2 = excTimesRef.current.getResult() || {};
                    return {
                        ...p1,
                        ...p2,
                    };
                },
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Grid item xs={4}>
                <DateIntervalField
                    ref={dateRangeRef}
                    label="時間區間"
                    startDateProps={{
                        defaultValue: paramsData.startDate || getSubDays(getToday(), 1),
                    }}
                    endDateProps={{ defaultValue: paramsData.endDate || getToday() }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <TextInputRangeField ref={excTimesRef} paramsData={paramsData} label="運動次數" fullWidth />
            </Grid>
        </React.Fragment>
    );
});

export const FilterWorkOutTimesLabel = [
    {
        ...rowLabelCheckboxSheet,
        cellKey: 'memberID',
    },
    { ...memberSheet, isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
    { ...cellPhoneSheet },
    {
        cellKey: 'weeklyWorkoutQuantity',
        headerLabel: '本週WO',
        width: 9,
        align: 'center',
    },
    {
        cellKey: 'monthlyWorkoutFrequency',
        headerLabel: 'WO頻率',
        align: 'center',
    },
    {
        cellKey: 'workoutQuantity',
        headerLabel: '運動次數',
        width: 9,
        align: 'center',
    },
    {
        cellKey: 'lastWorkoutDate',
        headerLabel: '最近來店日',
        align: 'center',
        width: 17,
        formatCell: (value) => {
            return parseDate(value, DateTimeStandard.DisplayFullBySlash);
        },
    },
    { ...contactSheet },
    { ...moreSheet },
];
