import React, { useRef, useState, useMemo } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import InBodyInterviewDialogBox from '@icoach/inbody/interview/InBodyInterviewDialogBox';
import InBodyInterviewMeasureNormalBox, {
    InBodyInterviewMeasureCompareBox,
    inBodyInterviewMeasureItems,
} from '@icoach/inbody/interview/InBodyInterviewMeasureNormalBox';
import InBodyExtendDiv from '@icoach/inbody/components/InBodyExtendDiv';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';

const InBodyInterviewMeasureTable = React.forwardRef((props, ref) => {
    const { sourceData = [] } = props;
    const divRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(1);
    const measureDateOptions = useMemo(
        () => {
            let opts = sourceData.map(({ measureDate }, index) => ({
                text: measureDate,
                value: index,
            }));
            opts.shift();
            return opts;
        },
        // eslint-disable-next-line
        [sourceData]
    );
    return (
        <div className="inbody_measure">
            <div className="container" ref={divRef}>
                {inBodyInterviewMeasureItems.map(({ value, key, rowClass, formatCell, formatCompare, formatMutipleCell, style: styleProps = {} }) => {
                    let styles = { ...styleProps };
                    return (
                        // row
                        <div key={value} className={clsx('row', rowClass, sourceData.length === 0 && 'full-width')} style={{ ...styles }}>
                            {/* Column 標題 */}
                            <div className={clsx('item', 'sticky')}>{value}</div>
                            {/* 比較內容 */}
                            {!_.isEmpty(sourceData) && sourceData.length > 1 && (
                                <InBodyInterviewMeasureCompareBox
                                    datumData={sourceData[0]}
                                    compareData={sourceData[selectedItem]}
                                    measureDateOptions={measureDateOptions}
                                    itemKey={key}
                                    formatCell={formatCell}
                                    formatCompare={formatCompare}
                                    formatMutipleCell={formatMutipleCell}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    styles={styles}
                                />
                            )}
                            {/* 全部內容 */}
                            <InBodyInterviewMeasureNormalBox sourceData={sourceData} itemKey={key} styles={styles} formatCell={formatCell} />
                            <InBodyExtendDiv />
                        </div>
                    );
                })}
            </div>
            <ScrollerArrowButton arrowLeftPos={600} parentRef={divRef} />
            <InBodyInterviewDialogBox />
        </div>
    );
});

export default InBodyInterviewMeasureTable;
