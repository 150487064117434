import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import InBodyInterviewAddMeasureDialog from './InBodyInterviewAddMeasureDialog';
import InBodyInterviewNotMeasureDialog from './InBodyInterviewNotMeasureDialog';
import { INBODY_RECORD_ROUTER } from '@icoach/router/routerPath';
import SimpleContentItem from '@icoach/members/components/SimpleContentItem';
import MembershipProgressBar from '@icoach/members/components/MembershipProgressBar';
import { Stack, Button, Grid } from '@common/components/';
import { BusinessCardBox } from '@icoach/components';

const InBodyInterviewInfo = React.forwardRef((props, ref) => {
    const { className, sourceData = {}, status } = props;

    const history = useHistory();
    const { memberName, nickName, memberNo, membershipStatus, picturePath, membershipText, membershipBar = {} } = sourceData;

    const handleReturnHistoryOnClick = () => {
        history.push(INBODY_RECORD_ROUTER);
    };

    return (
        <Grid container className={clsx(className)}>
            <Grid item xs={3}>
                <BusinessCardBox
                    rowData={{
                        memberImg: picturePath,
                        memberName,
                        nickName,
                        memberNo,
                        membershipStatus,
                    }}
                    memberAvatarWidth={7}
                />
            </Grid>
            <Grid item xs={6}>
                <MembershipProgressBar progressBarData={membershipBar} />
                <SimpleContentItem className="mt-3" label="會籍類別">
                    {membershipText}
                </SimpleContentItem>
            </Grid>
            <Grid item xs={3}>
                <Stack justifyContent="end" spacing={2}>
                    <Button onClick={handleReturnHistoryOnClick}>返回列表</Button>
                    {status ? <InBodyInterviewNotMeasureDialog /> : <InBodyInterviewAddMeasureDialog />}
                </Stack>
            </Grid>
        </Grid>
    );
});

export default InBodyInterviewInfo;
