import React, { useState, useEffect, useRef, useMemo } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, Form, RadioField, Stack, Typography } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';
import { ACHBankDialogContent } from '@icoach/members/memberDetail/dialog/ACHBankDialog';
import { CreditCardDialogContent } from '@icoach/members/memberDetail/dialog/CreditCardDialog';

const MutiplePaymentDialog = (props) => {
    const { className, open, memberID, onClose: onCloseProps, sourceData: sourceDataProps } = props;
    const [sourceData, setSourceData] = useState({});
    const [options, setOptions] = useState([]);
    const contentRef = useRef(null);
    const [paymentTypeState, setPaymentTypeState] = useState(null);
    const { getMemberPaymentOptionsApi, postMemberPaymentBankApi, postMemberPaymentCreditCardApi } = useMemberProfileApi();
    const { refresh } = useMemberDetailContext();
    const { paymentType: paymentTypeProps } = sourceData || {};

    const handleOnChange = (_e, value) => {
        setPaymentTypeState(value);
    };

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = contentRef.current && contentRef.current.getResult();
            doMemberPaymentApi(params, memberID);
        }
    };

    const doMemberPaymentApi = async (params, mID) => {
        const resp = await commonPaymentEle[paymentTypeState].doApi(mID, params);
        if (resp) {
            refresh.current && refresh.current(memberID);
            onCloseProps && onCloseProps();
        }
    };

    const initPaymentDataApi = async (mID) => {
        const resp = await getMemberPaymentOptionsApi(mID);
        if (resp) {
            setOptions(resp.paymentTypeOptions);
        }
    };

    const commonPaymentEle = useMemo(
        () => {
            return {
                1: {
                    Component: <CreditCardDialogContent ref={contentRef} sourceData={sourceData} />,
                    doApi: postMemberPaymentCreditCardApi,
                },
                2: {
                    Component: <ACHBankDialogContent ref={contentRef} sourceData={sourceData} />,
                    doApi: postMemberPaymentBankApi,
                },
            };
        },
        // eslint-disable-next-line
        [sourceData]
    );

    useEffect(
        () => {
            if (open && options.length === 0) {
                initPaymentDataApi(memberID);
            }
            setPaymentTypeState(paymentTypeProps);
        },
        // eslint-disable-next-line
        [open, paymentTypeProps]
    );

    useEffect(
        () => {
            setSourceData(sourceDataProps);
        },
        // eslint-disable-next-line
        [sourceDataProps]
    );

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onCloseProps}>編輯付款資訊</GradientColorHeader>
            <DialogContent>
                {!isEmpty(options) && !isEmpty(sourceData) && (
                    <Stack direction="row">
                        <RadioField key={paymentTypeState} value={paymentTypeState} onChange={handleOnChange} row required>
                            {options.map(({ value, text }) => {
                                return <RadioField.Radio key={value} value={value} label={text} />;
                            })}
                        </RadioField>
                        <Typography className="text-gray payment-card-type-select" variant="body2" component="span">
                            (<span className="text-error">**</span>只能選擇一種付款方式)
                        </Typography>
                    </Stack>
                )}
            </DialogContent>
            {commonPaymentEle[paymentTypeState]?.Component ?? null}
            <DialogActions>
                <Button onClick={onCloseProps} variant="outlined">
                    取消編輯
                </Button>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MutiplePaymentDialog;
