import React, { useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, MenuItem, SelectField, TextField, Typography } from '../../../components';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { useSettleCashContext } from '../context/SettleCashContext';
import useOrderAccountBookApi from '../../../apis/useOrderAccountBookApi';
import { formatCurrencyFn, refIsRequiredError } from '../../../util/utils';
import { parseDate } from '../../../util/moment';

const SettleCashDialogContent = React.forwardRef((props, ref) => {
    const { data = {}, resource = {}, total = 0 } = props;
    const { settleCashPrice, settleType, settleNotes, settleEmployeeID } = data;
    const { coachOptions: employeeOptions = [], settleNotesOptions = [] } = resource;
    const settleCashPriceRef = useRef();
    const employeeRef = useRef();
    const settleTypeRef = useRef();
    const notesRef = useRef();

    useImperativeHandle(ref, () => ({
        getResult: () => {
            return {
                settleCashPrice: settleCashPriceRef.current.getResult(),
                settleType: settleTypeRef.current.getResult(),
                settleNotes: notesRef.current.getResult(),
                employeeID: employeeRef.current.getResult(),
            };
        },
        isError: () => refIsRequiredError(settleCashPriceRef, settleTypeRef, notesRef, employeeRef),
    }));

    return (
        <DialogContent>
            <Typography className={'mb-1'} variant={'h6'}>
                <span className={'mr-3'}>現金總計：</span>
                <strong>{formatCurrencyFn(total)}</strong>
            </Typography>
            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <TextField maskType={'MONEY'} label={'結算金額'} ref={settleCashPriceRef} defaultValue={settleCashPrice} fullWidth required />
                </Grid>
                <Grid xs={6} item>
                    <SelectField label={'擔當'} ref={employeeRef} defaultValue={settleEmployeeID} fullWidth required>
                        {Array.isArray(employeeOptions) &&
                            employeeOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
                <Grid xs={12} item>
                    <SelectField label={'常見說明'} ref={settleTypeRef} defaultValue={settleType} required fullWidth>
                        {Array.isArray(settleNotesOptions) &&
                            settleNotesOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
                <Grid xs={12} item>
                    <TextField label={'備註'} ref={notesRef} defaultValue={settleNotes} minRows={2} multiline fullWidth />
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const SettleCashDialog = (props) => {
    const { className, open, onClose } = props;
    const { postOrderAccountBookSettleApi } = useOrderAccountBookApi();
    const { accountingDate, main = {}, resource, refresh } = useSettleCashContext();
    const { fundPrice = 0, debitPrice = 0, creditPrice = 0, cashTotalPrice = 0, cardTotalPrice = 0 } = main;
    const fieldRef = useRef();

    const getParams = () => {
        return {
            accountingDate: parseDate(accountingDate, 'YYYY-MM-DDTHH:mm:ss'),
            debitPrice,
            creditPrice,
            cashTotalPrice,
            cardTotalPrice,
            ...fieldRef.current.getResult(),
        };
    };

    const checkError = () => fieldRef.current.isError();

    const doOrderAccountBookFundApi = async () => {
        let params = getParams();
        let res = await postOrderAccountBookSettleApi(params);
        if (res) {
            refresh && refresh(accountingDate);
            onClose();
        }
    };

    const handleSubmit = () => {
        if (!checkError()) doOrderAccountBookFundApi();
    };

    const calculateTotal = () => {
        let total = 0;
        let _fundPrice = parseInt(fundPrice);
        let _cashPrice = parseInt(cashTotalPrice);
        let _creditPrice = parseInt(creditPrice);
        if (!isNaN(_fundPrice)) total = total + _fundPrice;
        if (!isNaN(_cashPrice)) total = total + _cashPrice;
        if (!isNaN(_creditPrice)) total = total + _creditPrice;
        return total;
    };

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>進行結算</GradientColorHeader>
            <SettleCashDialogContent ref={fieldRef} data={main} resource={resource} total={calculateTotal()} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettleCashDialog;
