// pageIndex: 第幾頁
// pageSize:  一頁幾筆
// sortBy:  排序 int
export const initBookmark = (sortBy) => ({
    pageIndex: 1,
    pageSize: 50,
    sortBy: sortBy || 1,
});

export const dialogTypeMap = {
    manualGift: 'manualGift',
    workOut: 'workOut',
};

export const getOptionData = (text, value, disabled = false) => {
    return {
        text,
        value,
        disabled,
    };
};

export const quickEnumMap = {
    workOut: '1',
    extension: '2',
    leave: '3',
    invalid: '4',
    birthday: '5',
    hundredTShirt: '6',
    newMember: '7',
    healthCare: '8',
};

export const giftDispatchByMemberList = [quickEnumMap.workOut, quickEnumMap.hundredTShirt, quickEnumMap.newMember, quickEnumMap.birthday];
