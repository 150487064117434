import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import {
    Alert,
    AutocompleteField,
    Box,
    Button,
    Card,
    Checkbox,
    CheckboxField,
    DateField,
    DateIntervalField,
    MenuItem,
    SelectField,
    Stack,
    TextField,
    Typography,
} from '@common/components/';
import { invoiceCarrierTypeMap, issuingTypeMap, ReadTextField, SignBox } from '@icoach/components';
import useMessageDialog from '@util/hook/useMessageDialog';
import useCyclePurchaseApi from '@apis/useCyclePurchaseApi';
import usePeopleApi from '@apis/usePeopleApi';
import { getRadioFieldResult, refIsRequiredError } from '@util/utils';
import {
    getNowTime,
    displayDateText,
    getAddMonths,
    getSubMonths,
    getFirstOfMonth,
    getLastOfMonth,
    initialDate,
    isValidDate,
    parseDate,
    isAfter,
} from '@util/moment';
import { CartTable } from '@icoach/documents/table';
import { effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import { getCheckboxValue, setCheckboxValue } from '@icoach/documents/tools';
import { AddressField, CreditCard, TermContent } from '@icoach/documents/components';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';
import useAuthUserApi from '@apis/useAuthUserApi';
import { ConfirmTemporaryContractDialog } from '@icoach/documents/dialog';
import useShowLoading from '@util/hook/useShowLoading';
import CyclePurchaseInvoiceBox from '@icoach/documents/components/CyclePurchaseInvoiceBox';

export const isAfterDeadline = (date, shippingDay) => {
    let deadline = shippingDay - 5;
    deadline = parseDate(moment(date).date(deadline));
    return isAfter(date, deadline);
};
// 是否過截止日

// 取得生效日 日期
export const getEffectiveDate = (date, shippingDay) => {
    let _date = date;
    if (isAfterDeadline(_date, shippingDay)) {
        _date = getAddMonths(_date, 1);
    }
    return _date;
};

// 取得最後出貨日
export const getLastShippingMonth = (date, shippingDay) => {
    let _date = date;

    if (!isAfterDeadline(date, shippingDay)) {
        _date = getSubMonths(date, 1);
    }
    return _date;
};

// 取得下次出貨日
export const getNextShippingDate = (effectDate, shippingDayData) => {
    let nextShippingDate;
    if (isValidDate(effectDate) && shippingDayData) {
        nextShippingDate = parseDate(moment(effectDate).date(shippingDayData));
        if (isAfterDeadline(effectDate, shippingDayData)) {
            nextShippingDate = getAddMonths(nextShippingDate, 1);
        }
    }
    return nextShippingDate;
};

// 訂購單資訊 唯讀
export const OrderStateSectionByRead = (props) => {
    const { data = {} } = props;
    const { cycleOrderNo, cycleOrderTypeText, cycleOrderDate, shippingDayText, firstShipmentMonth } = data;
    return (
        <React.Fragment>
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'訂單編號'}>{cycleOrderNo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'訂單狀態'}>{cycleOrderTypeText}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'建單日期'}>{displayDateText(cycleOrderDate)}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'出貨週期'}>{`${shippingDayText}日`}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'首次出貨月份'}>{displayDateText(firstShipmentMonth, null, { format: 'YYYY/MM' })}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};
// 訂購人資訊 唯讀
export const ConsumerSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { memberName, memberNo, cellPhone, birthday } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title="訂購人資訊" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'會員姓名'}>{memberName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'手機/電話'}>{cellPhone}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'會員編號'}>{memberNo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'生日'}>{displayDateText(birthday)}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};
// 訂購商品資訊 唯讀
export const ProductSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { items } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title={'訂購商品'} className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'px-4 pt-2 pb-4'}>
                <CartTable className={'mb-4'} data={items} readOnly />
            </Card.CardContent>
        </React.Fragment>
    );
};
// 收件人資訊 唯讀
export const ReceiverSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { receiverName, receiverMobile, receiverPostCode = '', receiverAddress = '', receiverNotes } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title={'收件人資訊'} className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'收件人'}>{receiverName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'收件手機'}>{receiverMobile}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'收件地址'}>{`${receiverPostCode} ${receiverAddress}`}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'備註'}>{receiverNotes}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

// 付款資訊 唯讀
export const PaymentSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {}, isCreate } = props;
    const { payment = {}, noDeductNow } = data;
    const { creditCardNo, cardExpiryDate, cardName, cardRelation, creditCardTypeText } = payment;

    return (
        <React.Fragment>
            <Card.CardHeader title="付款資訊" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    {!isCreate && (
                        <Grid xs={12} item>
                            <CheckboxField defaultChecked={setCheckboxValue(noDeductNow)} readOnly>
                                <CheckboxField.Checkbox label={<span className={'font-weight-bold'}>當月不補扣</span>} />
                            </CheckboxField>
                        </Grid>
                    )}
                    <Grid xs={4} item>
                        <ReadTextField label={'信用卡號'}>{creditCardNo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'有效期限'}>{cardExpiryDate}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'持卡人姓名'}>{cardName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'卡片關係人'}>{cardRelation}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'卡別'}>{creditCardTypeText}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

// 發票資訊 唯讀
export const InvoiceSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { issuingType, issuingTypeText, carrierType, carrierTypeText, carrierNo, taxTitle, taxID, donationCode, cellPhone, mail } = data.invoice || {};

    return (
        <React.Fragment>
            <Card.CardHeader title="發票資訊" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'發票類型'}>{issuingTypeText}</ReadTextField>
                    </Grid>
                    {issuingType === 1 && (
                        <Grid item xs={4}>
                            <ReadTextField label={'愛心碼'}>{donationCode}</ReadTextField>
                        </Grid>
                    )}
                    {issuingType === 2 && (
                        <React.Fragment>
                            <Grid item xs={4}>
                                <ReadTextField label={'發票載具'}>{carrierTypeText}</ReadTextField>
                            </Grid>
                            {(String(carrierType) === invoiceCarrierTypeMap.mobile ||
                                String(carrierType) === invoiceCarrierTypeMap.citizenDigitalCertificate1) && (
                                <Grid item xs={4}>
                                    <ReadTextField label={'載具號碼'}>{carrierNo}</ReadTextField>
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                    {String(issuingType) === issuingTypeMap.triplicateUniform && (
                        <React.Fragment>
                            <Grid item xs={4}>
                                <ReadTextField label={'統一編號'}>{taxID}</ReadTextField>
                            </Grid>
                            <Grid item xs={4}>
                                <ReadTextField label={'發票抬頭'}>{taxTitle}</ReadTextField>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid xs={4} item>
                        <ReadTextField label={'寄送載具信箱'}>{mail}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'通知手機號碼'}>{cellPhone}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

// 店舖資料 唯讀
export const ClubSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { clubName, saleClubName, employeeName } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title="店舖教練資訊" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'所屬店舖'}>{clubName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'販售店舖'}>{saleClubName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'擔當'}>{employeeName}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

// 變更單狀態 唯讀
export const OrderEffectStateSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { effectNo, effectStatusText, effectDate, effectCreateDate, employeeName } = data;
    return (
        <React.Fragment>
            <Card.CardHeader title="變更單資訊" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'變更單編號'}>{effectNo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'生效日期'}>{displayDateText(effectDate)}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'建單日期'}>{displayDateText(effectCreateDate)}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'變更單狀態'}>{effectStatusText}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'建單擔當'}>{employeeName}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

// 出貨週期 唯讀
export const ShippingSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { shippingDayText } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title="出貨週期" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'出貨週期'}>{shippingDayText}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

// 暫停 唯讀
export const SuspensionSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { suspendStartDate, suspendEndDate, suspendReasonText, suspendNote } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title="暫停" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={6} item>
                        <ReadTextField label={'暫停期間'}>
                            {displayDateText(suspendStartDate, suspendEndDate, {
                                format: 'YYYY/MM',
                            })}
                        </ReadTextField>
                    </Grid>
                    <Grid xs={6} item>
                        <ReadTextField label={'暫停原因'}>{suspendReasonText}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'暫停原因補充說明'}>{suspendNote}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

// 退訂 唯讀
export const QuitSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { unsubscribeDate, unsubscribeReasonText, unsubscribeNote } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title="退訂" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    {unsubscribeDate && (
                        <Grid xs={6} item>
                            <ReadTextField label={'退訂日期'}>{displayDateText(unsubscribeDate)}</ReadTextField>
                        </Grid>
                    )}

                    <Grid xs={6} item>
                        <ReadTextField label={'退訂原因'}>{unsubscribeReasonText}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'退訂原因補充說明'}>{unsubscribeNote}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

// 暫停恢復 唯讀
export const ResuspensionSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { originalSuspendStartDate, originalSuspendEndDate, reSuspendDate } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title="暫停恢復" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={6} item>
                        <ReadTextField label={'原暫停期間'}>
                            {displayDateText(originalSuspendStartDate, originalSuspendEndDate, {
                                format: 'YYYY/MM',
                            })}
                        </ReadTextField>
                    </Grid>
                    <Grid xs={6} item>
                        <ReadTextField label={'原恢復出貨月份'}>
                            {displayDateText(getAddMonths(originalSuspendEndDate, 1), null, {
                                format: 'YYYY/MM',
                            })}
                        </ReadTextField>
                    </Grid>
                    <Grid xs={6} item>
                        <ReadTextField label={'恢復出貨月份'}>{displayDateText(reSuspendDate, null, { format: 'YYYY/MM' })}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

// 選取項目
export const PickItem = React.forwardRef((props, ref) => {
    const { defaultChecked, title, handleChange: handleChangeProps } = props;
    const [helperTextData, setHelperTextData] = useState({
        isError: false,
        text: '錯了送不出去',
    });
    const checkRef = useRef();
    const handleChange = (e, checked) => {
        handleChangeProps && handleChangeProps(checked);
    };
    useImperativeHandle(
        ref,
        () => ({
            getResult: () => checkRef.current && checkRef.current.checked,
            setHelperText: (isError, text) => {
                if (isError) {
                    window.scrollTo({
                        top: checkRef.current.offsetTop,
                        behavior: 'smooth',
                    });
                }
                setHelperTextData((prev) => ({
                    ...prev,
                    isError,
                    text,
                }));
            },
        }),
        [],
    );

    return (
        <Stack alignItems={'center'}>
            <Checkbox key={defaultChecked} className={'mr-1'} ref={checkRef} defaultChecked={defaultChecked} color={'primary'} onChange={handleChange} />
            <Typography className={'m-0 font-weight-bold'}>{title}</Typography>
            {helperTextData.isError && (
                <Typography className={'m-0 ml-1'} variant={'body2'} color={'error'}>
                    {helperTextData.text}
                </Typography>
            )}
        </Stack>
    );
});

// 店舖資料
export const ClubSection = React.forwardRef((props = {}, ref) => {
    const { effectDate, setEffectDate } = props;
    const { employeeID, clubID, saleClubID, cycleTimeType: cycleTimeTypeProps, cycleToolType: cycleToolTypeProps } = props.data || {};
    const { coachOptions: employeeOptions, clubOptions, cycleTimeTypeOptions, cycleToolTypeOptions } = props.resource || {};
    const dateRef = useRef(null);
    const timeRef = useRef(null);
    const toolRef = useRef(null);
    const clubRef = useRef(null);
    const saleClubRef = useRef(null);
    const employeeRef = useRef(null);

    const getResult = () => {
        let result = {};
        if (dateRef.current && dateRef.current.getResult) {
            result.cycleOrderDate = parseDate(dateRef.current.getResult(), DateTimeStandard.DisplayUtcFull);
        }
        if (timeRef.current && timeRef.current.getResult) {
            result.cycleTimeType = timeRef.current.getResult();
        }
        if (toolRef.current && toolRef.current.getResult) {
            result.cycleToolType = toolRef.current.getResult();
        }
        if (clubRef.current && clubRef.current.getResult) result.clubID = clubRef.current.getResult();
        if (saleClubRef.current && saleClubRef.current.getResult) result.saleClubID = saleClubRef.current.getResult();
        if (employeeRef.current && employeeRef.current.getResult) result.employeeID = employeeRef.current.getResult();
        return result;
    };

    const handleChangeDate = (node, val) => {
        if (typeof setEffectDate === 'function') setEffectDate(val);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(clubRef, saleClubRef, employeeRef),
            getEffectDate: () => dateRef.current && dateRef.current.getResult(),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Card.CardHeader title="店舖教練確認" className={'bg-light-active px-4'} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <DateField
                            key={initialDate(effectDate)}
                            ref={dateRef}
                            label="訂購日期"
                            defaultValue={initialDate(effectDate)}
                            onChange={handleChangeDate}
                            minDate={parseDate()}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <SelectField ref={timeRef} defaultValue={cycleTimeTypeProps || null} label="TIME" displayEmpty fullWidth required>
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {Array.isArray(cycleTimeTypeOptions) &&
                                cycleTimeTypeOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={4} item>
                        <SelectField ref={toolRef} defaultValue={cycleToolTypeProps || null} label="TOOL" displayEmpty fullWidth required>
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {Array.isArray(cycleToolTypeOptions) &&
                                cycleToolTypeOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={4} item>
                        <SelectField key={clubID} ref={clubRef} label={'所屬店舖'} defaultValue={clubID} fullWidth required disabled>
                            {Array.isArray(clubOptions) &&
                                clubOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={4} item>
                        <SelectField key={saleClubID} ref={saleClubRef} label={'販售店舖'} defaultValue={saleClubID} fullWidth required disabled>
                            {Array.isArray(clubOptions) &&
                                clubOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={4} item>
                        <SelectField key={employeeID} ref={employeeRef} label={'擔當'} defaultValue={employeeID} fullWidth required>
                            {Array.isArray(employeeOptions) &&
                                employeeOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
});

// 訂購人資訊
export const ConsumerSection = React.forwardRef((props, ref) => {
    const { memberID, sourceData: sourceDataProps = {}, setContractData } = props;
    const { defaultData, birthday: memberBirthday } = sourceDataProps;
    const { getTempararyHasCycleOrderApi, getCyclePurchaseMemberOrderApi, deleteTempararyCycleOrderApi } = useCyclePurchaseApi();
    // 所有會員 6
    const { people: peopleOption = [] } = usePeopleApi(6);
    const showLoading = useShowLoading();
    const [openDialog, setOpenDialog] = useState({ isOpen: false, contractID: 0, memberID: 0 });
    const memberDataRef = useRef({});
    const memberRef = useRef(null);

    const handleChangeMember = (memberData) => {
        if (!isEmpty(memberData)) {
            doCheckHasTempararyContractIDApi(memberData.id);
        } else {
            setContractData({});
        }
    };

    // 關閉視窗
    const handleCloseDialog = () => {
        setOpenDialog({ isOpen: false, contractID: 0, memberID: 0 });
        setContractData({});
    };

    // 確定編輯抓取上次資料
    const handleClickYesDialog = () => {
        getTempContractID(openDialog.memberID, openDialog.contractID);
    };

    // 刪除暫存資料並直接抓新資料
    const handleClickNoDialog = () => {
        deleteTempContractID(openDialog.memberID, openDialog.contractID);
    };

    const getResult = () => {
        let result = {};

        if (memberRef.current && memberRef.current.getResult) {
            const memberData = memberRef.current.getResult();
            result.memberID = memberData.id;
        }

        return result;
    };

    const getTempContractID = async (memberID, contractID) => {
        showLoading(true);
        await doCyclePurchaseMemberOrderApi(memberID, contractID);
        setOpenDialog({ isOpen: false, contractID: 0, memberID: 0 });
    };

    // 刪除暫存資料
    const deleteTempContractID = async (memberID, contractID) => {
        showLoading(true);
        await deleteTempararyCycleOrderApi(contractID);
        await doCyclePurchaseMemberOrderApi(memberID, 0);
        setOpenDialog({ isOpen: false, contractID: 0, memberID: 0 });
    };

    // 檢查是否有暫存資料api
    const doCheckHasTempararyContractIDApi = async (memberID) => {
        showLoading(true);
        let contractID = 0;
        // 檢查是否有暫存資料
        const checkHasTempID = await getTempararyHasCycleOrderApi({ memberID });
        if (checkHasTempID !== 0) {
            showLoading(false);
            setOpenDialog({
                isOpen: true,
                contractID: checkHasTempID,
                memberID,
            });
        } else {
            await doCyclePurchaseMemberOrderApi(memberID, contractID);
        }
    };

    // 取得循環訂單資料
    const doCyclePurchaseMemberOrderApi = async (memberID, contractID) => {
        let resp = await getCyclePurchaseMemberOrderApi(memberID, contractID);
        if (resp) {
            setContractData(resp);
        }
        showLoading(false);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(memberRef),
        }),
        // eslint-disable-next-line
        [memberDataRef.current],
    );

    useEffect(
        () => {
            if (memberID) {
                doCheckHasTempararyContractIDApi(memberID);
            }
        },
        // eslint-disable-next-line
        [memberID],
    );

    return (
        <React.Fragment>
            <Card.CardHeader title={'訂購人資訊'} className={'bg-light-active px-4'} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <AutocompleteField
                            key={`${defaultData?.memberID}_${peopleOption.length}`}
                            label={'訂購人'}
                            ref={memberRef}
                            defaultValue={defaultData?.memberID}
                            options={peopleOption}
                            optionKeys={['name', 'mobile', 'no']}
                            primaryKey={'id'}
                            inputProps={{
                                placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可)',
                            }}
                            onChange={handleChangeMember}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <DateField key={memberBirthday} label={'生日'} defaultValue={memberBirthday || defaultData?.birthday} fullWidth disabled />
                    </Grid>
                </Grid>
            </Card.CardContent>
            <ConfirmTemporaryContractDialog
                open={openDialog?.isOpen ?? false}
                handleClose={handleCloseDialog}
                handleClickYes={handleClickYesDialog}
                handleClickNo={handleClickNoDialog}
            />
        </React.Fragment>
    );
});

// 訂購商品資訊 商品口味數量
export const ProductSection = React.forwardRef((props, ref) => {
    const { title = '訂購商品', sourceData = {}, options = {}, isCreate = false, setShowTip, onChangeEffectType: onChangeEffectTypeProps } = props;
    const { items } = sourceData;
    const { products } = options;
    const setMessageDialog = useMessageDialog();
    const [isShow, setShow] = useState(Boolean(isCreate));
    const pickItemRef = useRef(null);
    const itemsRef = useRef(null);
    const confirmRef = useRef(null);
    const effectTypeID = effectTypeMap.product;

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const getResult = () => {
        let result = { items: itemsRef.current && itemsRef.current.getResult() };
        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => {
                let isError = refIsRequiredError(itemsRef, confirmRef);
                let items = itemsRef.current && itemsRef.current.getResult();
                if (Array.isArray(items) && items < 1) {
                    if (!isError) isError = true;
                    setMessageDialog({
                        open: true,
                        title: '提醒',
                        msg: '至少要購買一項商品才可以送單喔，謝謝。',
                    });
                }
                return isError;
            },
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            setShowTip && setShowTip();
            onChangeEffectTypeProps && onChangeEffectTypeProps(isShow, effectTypeID);
        },
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            if (!isEmpty(sourceData.effectType)) {
                setShow(sourceData.effectType.includes(effectTypeID));
            }
        },
        // eslint-disable-next-line
        [sourceData],
    );

    return (
        <React.Fragment>
            <Card.CardHeader
                title={isCreate ? title : <PickItem ref={pickItemRef} title={title} defaultChecked={isShow} handleChange={handleShowContent} />}
                className={'bg-light-active px-4'}
            />
            {isShow && (
                <Card.CardContent className={'px-4 pt-2 pb-4'}>
                    <CartTable key={items} className={'mb-4'} ref={itemsRef} data={items} resource={products} />
                    <div className="bg-info-light px-4 py-2 mb-4">
                        <CheckboxField label="" ref={confirmRef} required>
                            <CheckboxField.Checkbox value={true} label={'我同意於提出變更口味申請後，刷卡金額會依品項單價而不同。'} required />
                        </CheckboxField>
                    </div>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

// 出貨資訊 出貨週期
export const ShippingSection = React.forwardRef((props, ref) => {
    const {
        title = '出貨資訊',
        sourceData = {},
        effectDate = parseDate(),
        options = {},
        isCreate = false,
        setShippingDayData,
        setShowTip,
        onChangeEffectType: onChangeEffectTypeProps,
    } = props;
    const effectTypeID = effectTypeMap.shippingDay;
    // const { shippingDay, firstShipmentMonth } = sourceData;
    const { shippingDay } = sourceData;
    const { shippingDayOptions } = options;
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [minDate, setMinDate] = useState(parseDate());
    const [firstShippingDate, setFirstShippingDate] = useState(parseDate());
    const pickItemRef = useRef(null);
    const shippingDayRef = useRef(null);
    const firstShippingMonthRef = useRef(null);

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const getResult = () => {
        const result = {};
        if (firstShippingMonthRef.current && firstShippingMonthRef.current.getResult) {
            result.firstShipmentMonth = getFirstOfMonth(firstShippingMonthRef.current.getResult());
        }
        if (shippingDayRef.current && shippingDayRef.current.getResult) {
            result.shippingDay = shippingDayRef.current.getResult() || 0;
        }

        return result;
    };

    const handleMinDate = (effectDate, shippingDay) => {
        setMinDate(getEffectiveDate(effectDate, shippingDay));
    };

    const getShippingDayText = (ShippingDayType) => {
        return shippingDayOptions.find((item) => item.value === ShippingDayType);
    };

    const handleChangeShippingDay = (e, n, v) => {
        let currentData = getShippingDayText(v);
        if (!isEmpty(currentData)) {
            handleMinDate(effectDate, currentData.text);
        }
        setShippingDayData && setShippingDayData(currentData);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(firstShippingMonthRef, shippingDayRef),
            getShippingDay: () => {
                let result;
                let shippingDay = shippingDayRef.current && shippingDayRef.current.getResult();
                if (shippingDay) {
                    result = shippingDayOptions.find((item) => item.value === shippingDay);
                }
                return result;
            },
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [shippingDayOptions, isShow],
    );

    useEffect(() => {
        setShowTip && setShowTip();
        onChangeEffectTypeProps && onChangeEffectTypeProps(isShow, effectTypeID);
        // eslint-disable-next-line
    }, [isShow]);

    useEffect(() => {
        if (!isEmpty(sourceData.effectType)) {
            setShow(sourceData.effectType.includes(effectTypeID));
        }

        if (sourceData.firstShipmentMonth && moment(sourceData.firstShipmentMonth) > moment()) {
            setFirstShippingDate(sourceData.firstShipmentMonth);
        } else {
            setFirstShippingDate(parseDate());
        }
        // eslint-disable-next-line
    }, [sourceData]);

    useEffect(() => {
        if (effectDate) {
            let shippingDay;
            if (shippingDayRef.current && shippingDayRef.current.getResult) {
                shippingDay = shippingDayRef.current.getResult();
            }
            if (shippingDay) {
                handleMinDate(effectDate, shippingDay);
            }
        }
    }, [effectDate]);

    const isShowAlert = !isCreate && moment().date() > 5;

    return (
        <React.Fragment>
            <Card.CardHeader
                title={isCreate ? title : <PickItem ref={pickItemRef} title={title} defaultChecked={isShow} handleChange={handleShowContent} />}
                className={'bg-light-active px-4'}
            />
            {isShow && (
                <Card.CardContent className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={4} item>
                            <SelectField
                                key={shippingDay}
                                ref={shippingDayRef}
                                label={'出貨週期'}
                                defaultValue={shippingDay || null}
                                onChange={handleChangeShippingDay}
                                fullWidth
                                required
                            >
                                {Array.isArray(shippingDayOptions) &&
                                    shippingDayOptions
                                        .filter((item) => item || !item.disabled)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {`${item.text}日`}
                                            </MenuItem>
                                        ))}
                            </SelectField>
                            {isShowAlert && (
                                <React.Fragment>
                                    <p />
                                    <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                        變更出貨週期，訂單將會在下個月1號才生效
                                    </Alert>
                                </React.Fragment>
                            )}
                        </Grid>
                        {isCreate && (
                            <Grid xs={4} item>
                                <DateField
                                    key={firstShippingDate}
                                    ref={firstShippingMonthRef}
                                    label={'首次出貨月份'}
                                    defaultValue={firstShippingDate}
                                    views={['year', 'month']}
                                    openTo={'month'}
                                    format={'YYYY/MM'}
                                    mask={'____/__'}
                                    minDate={minDate}
                                    placeholder={'請選擇月份'}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        )}
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});
// 收件資訊
export const ReceiverSection = React.forwardRef((props, ref) => {
    const { title = '收件人資訊', sourceData = {}, membershipData, isCreate = false, setShowTip, onChangeEffectType: onChangeEffectTypeProps } = props;
    const effectTypeID = effectTypeMap.receiver;
    const setMessageDialog = useMessageDialog();
    const { getAuthClubApi } = useAuthUserApi();
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [defaultData, setDefaultData] = useState({
        source: sourceData,
        key: uuid(),
    });
    const [clubData, setClubData] = useState({});
    const { receiverName, receiverPostCode, receiverAddress, receiverMobile, receiverNotes } = defaultData.source;
    const pickItemRef = useRef(null);
    const receiverNameRef = useRef(null);
    const addressRef = useRef(null);
    const receiverMobileRef = useRef(null);
    const receiverNotesRef = useRef(null);
    const useClubAddressRef = useRef(null);

    const doAuthClubApi = async () => {
        let resp = await getAuthClubApi();
        if (resp) {
            setClubData(resp);
        }
    };

    const handleClick = () => {
        if (isEmpty(membershipData)) {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '很抱歉！無法取得相關資料，謝謝。',
            });
        } else {
            setDefaultData({
                source: membershipData,
                key: uuid(),
            });
        }
    };

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const handleChangeClub = (e, value) => {
        if (value) {
            setDefaultData((prev) => ({
                ...prev,
                source: {
                    ...prev.source,
                    receiverAddress: clubData.cyclePurchaseAddress,
                },
            }));
        }
    };

    const getResult = () => {
        let result = {};
        if (receiverNameRef.current && receiverNameRef.current.getResult) result.receiverName = receiverNameRef.current.getResult();
        if (addressRef.current && addressRef.current.getResult) {
            const { postCode, address } = addressRef.current.getResult();
            result = {
                ...result,
                receiverPostCode: postCode,
                receiverAddress: address,
            };
        }
        if (receiverMobileRef.current && receiverMobileRef.current.getResult) result.receiverMobile = receiverMobileRef.current.getResult();
        if (receiverNotesRef.current && receiverNotesRef.current.getResult) result.receiverNotes = receiverNotesRef.current.getResult();
        if (useClubAddressRef.current && useClubAddressRef.current.getResult)
            result.isSendToStore = getCheckboxValue(useClubAddressRef.current.getResult(), {
                isMultiple: false,
            });

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(receiverNameRef, addressRef, receiverMobileRef, receiverNotesRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            setShowTip && setShowTip();
            onChangeEffectTypeProps && onChangeEffectTypeProps(isShow, effectTypeID);
            doAuthClubApi();
        },
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            if (!isEmpty(sourceData)) {
                setDefaultData({
                    source: sourceData,
                    key: uuid(),
                });
                if (!isEmpty(sourceData.effectType)) {
                    setShow(sourceData.effectType.includes(effectTypeID));
                }
            } else {
                setDefaultData({
                    source: {},
                    key: uuid(),
                });
            }
        },
        // eslint-disable-next-line
        [sourceData],
    );
    return (
        <React.Fragment>
            <Card.CardHeader
                title={isCreate ? title : <PickItem ref={pickItemRef} title={title} defaultChecked={isShow} handleChange={handleShowContent} />}
                className={'bg-light-active px-4'}
            />
            {isShow && (
                <Card.CardContent key={defaultData.key} className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={12} item>
                            <div className="tool-btn-group">
                                <Button color={'grey'} onClick={handleClick}>
                                    同會籍資料
                                </Button>
                            </div>
                        </Grid>
                        <Grid xs={4} item>
                            <TextField ref={receiverNameRef} label={'收件人'} defaultValue={receiverName} fullWidth required />
                        </Grid>
                        <Grid xs={4} item>
                            <TextField ref={receiverMobileRef} label={'收件手機'} defaultValue={receiverMobile} maskType={'MOB'} fullWidth required />
                        </Grid>
                        <Grid xs={12} item>
                            <CheckboxField ref={useClubAddressRef} onChange={handleChangeClub}>
                                <CheckboxField.Checkbox label={<span className={'font-weight-bold'}>寄至所屬店舖</span>} value={true} />
                            </CheckboxField>
                            <AddressField
                                ref={addressRef}
                                label={'收件地址'}
                                postCode={receiverPostCode}
                                address={receiverAddress}
                                addressPlaceholder={'請勿填寫便利商店地址、郵政信箱'}
                                containerSpacing={3}
                                required
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField ref={receiverNotesRef} label={'備註'} defaultValue={receiverNotes} minRows={3} multiline fullWidth />
                        </Grid>
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

// 付款資訊
export const PaymentSection = React.forwardRef((props, ref) => {
    const {
        title = '付款資訊',
        sourceData = {},
        membershipData,
        errorModule,
        isCreate = false,
        setShowTip,
        onChangeEffectType: onChangeEffectTypeProps,
    } = props;
    const { noDeductNow } = sourceData;
    const effectTypeID = effectTypeMap.payment;
    const setMessageDialog = useMessageDialog();
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [isSign, setShowSing] = useState(false);
    const [defaultData, setDefaultData] = useState({
        source: {},
        key: uuid(),
    });
    const pickItemRef = useRef(null);
    const creditCardRef = useRef(null);
    const isPaymentRef = useRef(null);

    const getResult = () => {
        const creditData = creditCardRef.current && creditCardRef.current.getResult();
        let result = {
            paymentType: 1, // 2022/11/10 只提供信用卡
        };

        if (!isEmpty(creditData)) {
            const { signImg, ...other } = creditData;
            result.payment = other;
            result.cardSignImg = signImg;
        }
        if (isPaymentRef.current && isPaymentRef.current.getResult) result.noDeductNow = getRadioFieldResult(isPaymentRef.current.getResult(), 'checked');

        return result;
    };

    const handleOverwriteOnClick = () => {
        let payment, cardRelation;

        if (!isEmpty(membershipData)) {
            payment = membershipData.payment;
            cardRelation = payment && payment.cardRelation;
        }
        if (isEmpty(membershipData) || cardRelation !== '本人') {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '很抱歉！無法取得相關資料，可能是沒有設定常用信用卡或不是信用卡持卡人，謝謝。',
            });
        } else {
            setDefaultData({ source: payment, key: uuid() });
        }
    };

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const handleChangeCardRelation = (e, value) => {
        setShowSing(!value);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(creditCardRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            setShowTip && setShowTip();
            onChangeEffectTypeProps && onChangeEffectTypeProps(isShow, effectTypeID);
        },
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            if (!isEmpty(sourceData)) {
                if (sourceData.payment) {
                    setDefaultData({
                        source: sourceData.payment,
                        key: uuid(),
                    });
                }
                if (!isEmpty(sourceData.effectType)) {
                    setShow(sourceData.effectType.includes(effectTypeID));
                }
            }
        },
        // eslint-disable-next-line
        [sourceData],
    );

    useEffect(
        () => {
            if (!isEmpty(defaultData)) {
                const cardRelation = defaultData?.source?.cardRelation;
                const isOwner = cardRelation === '本人';
                setShowSing(!isOwner);
            }
        },
        // eslint-disable-next-line
        [defaultData],
    );

    return (
        <React.Fragment>
            <Card.CardHeader
                title={isCreate ? title : <PickItem ref={pickItemRef} title={title} defaultChecked={isShow} handleChange={handleShowContent} />}
                className={'bg-light-active px-4'}
            />
            {isShow && (
                <Card.CardContent key={defaultData.key} className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={12} item>
                            <div className="tool-btn-group">
                                <Button color={'grey'} onClick={handleOverwriteOnClick}>
                                    套用常用信用卡
                                </Button>
                            </div>
                        </Grid>
                        {!isCreate && (
                            <Grid xs={12} item>
                                <CheckboxField key={noDeductNow} ref={isPaymentRef} defaultChecked={setCheckboxValue(noDeductNow)}>
                                    <CheckboxField.Checkbox label={<span className={'font-weight-bold'}>當月不補扣</span>} />
                                </CheckboxField>
                                <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                    當月補扣，信用卡資訊變更將立即生效，並通知HC單位進行補扣。若有其他選項變更將會以變更生效日為主
                                </Alert>
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <CreditCard
                                ref={creditCardRef}
                                defaultValue={defaultData.source}
                                isSign={isSign}
                                ownerProps={{
                                    onChange: handleChangeCardRelation,
                                }}
                                errorModule={errorModule?.payment}
                                isShowCardRelation
                            />
                        </Grid>
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

// 發票資訊
export const InvoiceSection = React.forwardRef((props, ref) => {
    const {
        title = '發票資訊',
        sourceData = {},
        membershipData,
        errorModule,
        isCreate = false,
        options,
        setShowTip,
        onChangeEffectType: onChangeEffectTypeProps,
    } = props;
    const effectTypeID = effectTypeMap.invoice;
    const setMessageDialog = useMessageDialog();
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [defaultData, setDefaultData] = useState({
        source: {},
        key: uuid(),
    });
    const pickItemRef = useRef(null);
    const invoiceRef = useRef(null);

    const getResult = () => {
        let result = {};

        if (invoiceRef.current && invoiceRef.current.getResult) {
            const { issuingEmail, ...invoice } = invoiceRef.current.getResult();
            result = Object.assign(result, {
                invoice: {
                    ...invoice,
                    mail: issuingEmail,
                },
            });
        }

        return result;
    };

    const handleClick = () => {
        const { invoice } = membershipData;

        if (isEmpty(invoice)) {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '很抱歉！無法取得相關資料，謝謝。',
            });
        } else {
            setDefaultData({
                source: {
                    ...invoice,
                    issuingEmail: invoice.mail,
                    issuingPhone: invoice.cellPhone,
                },
                key: uuid(),
            });
        }
    };

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(invoiceRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            setShowTip && setShowTip();
            onChangeEffectTypeProps && onChangeEffectTypeProps(isShow, effectTypeID);
        },
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            if (!isEmpty(sourceData)) {
                if (sourceData) {
                    setDefaultData({
                        source: sourceData,
                        key: uuid(),
                    });
                }
                if (!isEmpty(sourceData.effectType)) {
                    setShow(sourceData.effectType.includes(effectTypeID));
                }
            }
        },
        // eslint-disable-next-line
        [sourceData],
    );

    return (
        <React.Fragment>
            <Card.CardHeader
                title={isCreate ? title : <PickItem ref={pickItemRef} title={title} defaultChecked={isShow} handleChange={handleShowContent} />}
                className={'bg-light-active px-4'}
            />
            {isShow && (
                <Card.CardContent key={defaultData.key} className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={12} item>
                            <Box className="tool-btn-group">
                                <Button color={'grey'} onClick={handleClick}>
                                    同會籍資料
                                </Button>
                            </Box>
                            <br />
                            <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                發票資訊變更將立即生效，若有其他選項變更將會以變更生效日為主
                            </Alert>
                        </Grid>
                        <Grid xs={8} item>
                            <CyclePurchaseInvoiceBox
                                className={'mb-2'}
                                ref={invoiceRef}
                                options={{
                                    invoiceCarrierTypeOptions: options.invoiceCarrierType || [],
                                    invoiceIssuingTypeOptions: options.invoiceIssuingType || [],
                                }}
                                sourceData={{
                                    ...defaultData.source,
                                    issuingPhone: defaultData.source.cellPhone || '',
                                    issuingEmail: defaultData.source.mail || '',
                                }}
                                errorModule={errorModule}
                            />
                            <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                若要修改手機號碼，請至會員總覽基本資料處理。
                            </Alert>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});
// 訂購條款
export const TermSection = React.forwardRef((props, ref) => {
    const { className, data, nextShippingDate } = props;
    const confirmRef = useRef(null);
    const confirmRef2 = useRef(null);
    const signImgRef = useRef(null);

    const getResult = () => {
        let result = {};
        if (signImgRef.current && signImgRef.current.getResult) result = Object.assign(result, { signImg: signImgRef.current.getResult() });

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(confirmRef, confirmRef2, signImgRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <TermContent className={className} title="訂購注意事項" data={data}>
            <div className="bg-info-light px-4 py-2 mb-4">
                <CheckboxField label="" ref={confirmRef} required>
                    <CheckboxField.Checkbox
                        value={true}
                        label={
                            '我已詳細閱讀商品訂購注意事項，且同意授權每月信用卡扣款及辦理退貨時，由韻智股份有限公司代為處理發票銷貨退回證明單，以加速退貨退款作業。'
                        }
                    />
                </CheckboxField>
            </div>
            {isValidDate(nextShippingDate) && (
                <div className="bg-info-light px-4 py-2 mb-4">
                    <CheckboxField label="" ref={confirmRef2} required>
                        <CheckboxField.Checkbox value={true} label={`預計下次出貨日為：${parseDate(nextShippingDate, DateTimeStandard.DisplayDateBySlash)}`} />
                    </CheckboxField>
                </div>
            )}
            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <SignBox ref={signImgRef} title="訂購人簽名" />
                </Grid>
            </Grid>
        </TermContent>
    );
});

// 變更單 訂單日期與經辦人
export const EditTopSection = React.forwardRef((props, ref) => {
    const { effectDate, data = {}, resource = {}, setEffectDate, effectDateIsLock, minEffectDate } = props;
    const { effectCreateDate = parseDate(), employeeID } = data;
    const { coachOptions: employeeOptions } = resource;
    const createDateRef = useRef(null);
    const dateRef = useRef(null);
    const employeeRef = useRef(null);

    const getResult = () => {
        const result = {};
        if (createDateRef.current && createDateRef.current.getResult)
            result.effectCreateDate = parseDate(createDateRef.current.getResult(), DateTimeStandard.DisplayUtcFull);
        if (dateRef.current && dateRef.current.getResult) result.effectDate = parseDate(dateRef.current.getResult(), DateTimeStandard.DisplayUtcFull);
        if (employeeRef.current && employeeRef.current.getResult) result.employeeID = employeeRef.current.getResult();

        return result;
    };

    const handleChangeDate = (node, val) => {
        setEffectDate(val);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(dateRef, employeeRef),
            getEffectDate: () => dateRef.current && dateRef.current.getResult(),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <DateField
                            key={initialDate(effectCreateDate)}
                            ref={createDateRef}
                            label="建單日期"
                            defaultValue={initialDate(effectCreateDate)}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <DateField
                            key={initialDate(effectDate)}
                            ref={dateRef}
                            label="變更生效日期"
                            defaultValue={initialDate(effectDate)}
                            minDate={minEffectDate}
                            onChange={handleChangeDate}
                            fullWidth
                            disabled={effectDateIsLock}
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <SelectField key={employeeID} ref={employeeRef} label={'擔當'} defaultValue={employeeID} fullWidth required>
                            {Array.isArray(employeeOptions) &&
                                employeeOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
});

// 暫停
export const SuspensionSection = React.forwardRef((props, ref) => {
    const {
        title = '暫停(限單獨申請)',
        shippingDayData,
        data = {},
        isCreate = false,
        resource = {},
        minEffectDate,
        setShowTip,
        setEffectDate,
        setEffectDateIsLock,
        onChangeEffectType: onChangeEffectTypeProps,
    } = props;
    const effectTypeID = effectTypeMap.suspension;
    const { suspendReasonOptions: reasonOptions } = resource;
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [shippingMonth, setShippingMonth] = useState(); // 下次出貨月份

    const pickItemRef = useRef();
    const dateRef = useRef();
    const reasonRef = useRef();
    const otherRef = useRef();
    const isConfirmRef = useRef();

    const { effectID = '0' } = data;
    const isCreateEffect = String(effectID) === '0';
    const suspendStartDate = isCreateEffect ? '' : data?.suspendStartDate ?? '';
    const suspendEndDate = isCreateEffect ? '' : data?.suspendEndDate ?? '';
    const suspendReason = isCreateEffect ? '' : data?.suspendReason ?? '';
    const suspendNote = isCreateEffect ? '' : data?.suspendNote ?? '';

    const handleShowContent = (checked) => {
        setShow(checked);
        setEffectDate(checked ? null : minEffectDate);
        setEffectDateIsLock(checked);
    };

    const getEffectiveMonth = (endDate) => {
        return moment(endDate).add(1, 'months').month() + 1;
    };

    const handleChangeStartDate = (date) => {
        if (isValidDate(date)) {
            const suspendStartDate = getFirstOfMonth(date);
            const effectShipDayNum = parseInt(shippingDayData.text, 10) - 5;

            const today = moment();
            const suspendMoment = moment(suspendStartDate);

            setEffectDate(today.date() <= effectShipDayNum && suspendMoment <= today ? today.format('YYYY-MM-DD') : suspendStartDate);
        }
    };

    const handleChangeEndDate = (date) => {
        if (isValidDate(date)) {
            let month = getEffectiveMonth(date);
            setShippingMonth(month);
        }
    };

    const getResult = () => {
        let result = {};
        refIsRequiredError(dateRef, reasonRef, otherRef, isConfirmRef);
        if (dateRef.current && dateRef.current.getResult) {
            let dateRange = dateRef.current.getResult();
            result.suspendStartDate = getFirstOfMonth(dateRange.startDate);
            result.suspendEndDate = getLastOfMonth(dateRange.endDate);
        }
        if (reasonRef.current && reasonRef.current.getResult) result.suspendReason = reasonRef.current.getResult();
        if (otherRef.current && otherRef.current.getResult) result.suspendNote = otherRef.current.getResult();
        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(dateRef, reasonRef, otherRef, isConfirmRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(() => {
        setShowTip && setShowTip();
        onChangeEffectTypeProps && onChangeEffectTypeProps(isShow, effectTypeID);
        // eslint-disable-next-line
    }, [isShow]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (!isEmpty(data.effectType)) setShow(data.effectType.includes(effectTypeID));
            if (isValidDate(suspendEndDate)) setShippingMonth(getEffectiveMonth(suspendEndDate));
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <React.Fragment>
            <Card.CardHeader
                title={isCreate ? title : <PickItem ref={pickItemRef} title={title} defaultChecked={isShow} handleChange={handleShowContent} />}
                className={'bg-light-active px-4'}
            />
            {isShow && (
                <Card.CardContent className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={6} item>
                            <DateIntervalField
                                ref={dateRef}
                                label={'暫停期間'}
                                startDateProps={{
                                    defaultValue: suspendStartDate,
                                    minDate: getFirstOfMonth(getEffectiveDate(getNowTime(), shippingDayData.text)),
                                    onChange: handleChangeStartDate,
                                    placeholder: '請選擇月份',
                                }}
                                endDateProps={{
                                    defaultValue: suspendEndDate,
                                    minDate: getFirstOfMonth(getEffectiveDate(getNowTime(), shippingDayData.text)),
                                    onChange: handleChangeEndDate,
                                    placeholder: '請選擇月份',
                                }}
                                views={['year', 'month']}
                                openTo="month"
                                format="YYYY/MM"
                                mask="____/__"
                                placeholder={'請選擇月份'}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid xs={6} item>
                            <SelectField key={suspendReason} ref={reasonRef} label={'暫停原因'} defaultValue={suspendReason} fullWidth required>
                                <MenuItem value={''}>
                                    <em>請選擇</em>
                                </MenuItem>
                                {Array.isArray(reasonOptions) &&
                                    reasonOptions.map(({ text, value }) => (
                                        <MenuItem value={value} key={value}>
                                            {text}
                                        </MenuItem>
                                    ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={12} item>
                            <TextField key={suspendNote} ref={otherRef} label={'暫停原因補充說明'} defaultValue={suspendNote} minRows={3} multiline fullWidth />
                        </Grid>
                        {shippingMonth && (
                            <Grid xs={12} item>
                                <CheckboxField ref={isConfirmRef} required>
                                    <CheckboxField.Checkbox value={true} label={`我已確認將於${shippingMonth}月，恢復出貨。`} />
                                </CheckboxField>
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <Alert severity={'info'} variant={'filled'} color={'info'}>
                                如暫停超過三個月，請先辦理退訂
                            </Alert>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

// 退訂
export const QuitSection = React.forwardRef((props, ref) => {
    const {
        title = '退訂(限單獨申請)',
        effectDate,
        shippingDayData,
        data = {},
        resource = {},
        isCreate = false,
        setShowTip,
        onChangeEffectType: onChangeEffectTypeProps,
    } = props;
    const effectTypeID = effectTypeMap.quit;
    const { unsubscribeReasonOptions: reasonOptions } = resource;
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [lastShippingMonth, setLastShippingMonth] = useState();
    const pickItemRef = useRef();
    const reasonRef = useRef();
    const otherRef = useRef();
    const isConfirmRef = useRef();

    const { effectID = '0' } = data;
    const isCreateEffect = String(effectID) === '0';
    const unsubscribeReason = isCreateEffect ? '' : data?.unsubscribeReason ?? '';
    const unsubscribeNote = isCreateEffect ? '' : data?.unsubscribeNote ?? '';

    const getMonth = (date) => {
        return moment(date).month() + 1;
    };

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const getResult = () => {
        let result = {};
        if (reasonRef.current && reasonRef.current.getResult) result.unsubscribeReason = reasonRef.current.getResult();
        if (otherRef.current && otherRef.current.getResult) result.unsubscribeNote = otherRef.current.getResult();

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(reasonRef, otherRef, isConfirmRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            if (isValidDate(effectDate) && !isEmpty(shippingDayData)) {
                let _month = getLastShippingMonth(effectDate, shippingDayData.text);
                _month = getMonth(_month);
                setLastShippingMonth(_month);
            }
        },
        // eslint-disable-next-line
        [effectDate, shippingDayData],
    );

    useEffect(
        () => {
            if (isShow) {
                setShowTip && setShowTip();
                onChangeEffectTypeProps && onChangeEffectTypeProps(isShow, effectTypeID);
            }
        },
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            if (!isEmpty(data.effectType)) {
                setShow(data.effectType.includes(effectTypeID));
            }
        },
        // eslint-disable-next-line
        [data],
    );

    return (
        <React.Fragment>
            <Card.CardHeader
                title={isCreate ? title : <PickItem ref={pickItemRef} title={title} defaultChecked={isShow} handleChange={handleShowContent} />}
                className={'bg-light-active px-4'}
            />
            {isShow && (
                <Card.CardContent className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={6} item>
                            <SelectField key={unsubscribeReason} ref={reasonRef} label={'退訂原因'} defaultValue={unsubscribeReason} fullWidth required>
                                <MenuItem value={''}>
                                    <em>請選擇</em>
                                </MenuItem>
                                {Array.isArray(reasonOptions) &&
                                    reasonOptions.map(({ text, value }) => (
                                        <MenuItem value={value} key={value}>
                                            {text}
                                        </MenuItem>
                                    ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={12} item>
                            <TextField
                                key={unsubscribeNote}
                                ref={otherRef}
                                label={'退訂原因補充說明'}
                                defaultValue={unsubscribeNote}
                                minRows={3}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        {lastShippingMonth && (
                            <Grid xs={12} item>
                                <CheckboxField ref={isConfirmRef} required>
                                    <CheckboxField.Checkbox value={true} label={`我已確認將於${lastShippingMonth}月，收到最後乙次出貨。`} />
                                </CheckboxField>
                            </Grid>
                        )}
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});
// 暫停恢復
export const ResuspensionSection = React.forwardRef((props, ref) => {
    const { title = '暫停恢復', effectDate, shippingDayData, data = {}, isCreate = false, setShowTip, onChangeEffectType: onChangeEffectTypeProps } = props;
    const { originalSuspendStartDate, originalSuspendEndDate, suspendStartDate, suspendEndDate, reSuspendDate } = data;
    const effectTypeID = effectTypeMap.resuspension;
    const [isShow, setShow] = useState(Boolean(isCreate));
    const pickItemRef = useRef();
    const lastShippingMonthRef = useRef();

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const getResult = () => {
        let result = {
            originalSuspendStartDate: originalSuspendStartDate || suspendStartDate,
            originalSuspendEndDate: originalSuspendEndDate || suspendEndDate,
        };
        if (lastShippingMonthRef.current && lastShippingMonthRef.current.getResult) {
            let reSuspendDate = lastShippingMonthRef.current.getResult();
            reSuspendDate = parseDate(moment(reSuspendDate).date(1), DateTimeStandard.DisplayUtcFull);

            result = Object.assign(result, { reSuspendDate });
        }
        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(lastShippingMonthRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            setShowTip && setShowTip();
            onChangeEffectTypeProps && onChangeEffectTypeProps(isShow, effectTypeID);
        },
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            if (!isEmpty(data.effectType)) {
                setShow(data.effectType.includes(effectTypeID));
            }
        },
        // eslint-disable-next-line
        [data],
    );

    return (
        <React.Fragment>
            <Card.CardHeader
                title={isCreate ? title : <PickItem ref={pickItemRef} title={title} defaultChecked={isShow} handleChange={handleShowContent} />}
                className={'bg-light-active px-4'}
            />
            {isShow && (
                <Card.CardContent className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={6} item>
                            <ReadTextField label={'原暫停期間'}>
                                {displayDateText(originalSuspendStartDate || suspendStartDate, originalSuspendEndDate || suspendEndDate, {
                                    format: 'YYYY/MM',
                                })}
                            </ReadTextField>
                        </Grid>
                        <Grid xs={6} item>
                            <ReadTextField label={'原恢復出貨月份'}>
                                {displayDateText(getFirstOfMonth(getAddMonths(originalSuspendEndDate || suspendEndDate, 1)), null, {
                                    format: 'YYYY/MM',
                                })}
                            </ReadTextField>
                        </Grid>
                        <Grid xs={6} item>
                            <DateField
                                key={getEffectiveDate(effectDate, shippingDayData.text)}
                                ref={lastShippingMonthRef}
                                label={'恢復出貨月份'}
                                defaultValue={initialDate(reSuspendDate)}
                                views={['year', 'month']}
                                openTo={'month'}
                                format={'YYYY/MM'}
                                mask={'____/__'}
                                minDate={getEffectiveDate(effectDate, shippingDayData.text)}
                                maxDate={getEffectiveDate(originalSuspendEndDate || suspendEndDate, shippingDayData.text)}
                                placeholder={'請選擇月份'}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});
