import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Button, Card, TextField, CheckboxField, RadioField, Alert, Typography, DateField, Box } from '@common/components/';
import PromptDialog from '@icoach/components/dialog/PromptDialog';
import { refIsRequiredError, parseBoolean } from '@util/utils';
import { parseDate } from '@util/moment';
import useDocMembershipTransferOutApi from '@apis/useDocMembershipTransferOutApi';
import MembershipTransferOutTransferClubCard from '@icoach/documents/membership/transferOut/MembershipTransferOutTransferClubCard';
import MembershipTransferOutExecuteClubCard from '@icoach/documents/membership/transferOut/MembershipTransferOutExecuteClubCard';
import { AddressField } from '@icoach/documents/components';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_PATH } from '@icoach/router/routerPath';

const MembershipTransferOutContent = (props) => {
    const { onReturnHistory, isReadOnly = false } = props;
    const history = useHistory();
    const { getTransferOutContractApi, postTransferOutContractApi, postDocTemporaryTransferOutContractApi } = useDocMembershipTransferOutApi();
    const { referenceID: referenceIDProps, contractID: contractIDProps, peopleType: peopleTypeProps } = useParams();
    const [showPrompt, setShowPrompt] = useState(true);
    const [sourceData, setSourceData] = useState({});
    const [isMounted, setMounted] = useState(false);
    const {
        address = '',
        cellPhone = '',
        memberNo = '',
        name = '',
        tel = '',
        postCode = '',
        originalClubID = 0,
        isOrderHC,
        employeeID,
        survey = {},
        applyDate,
        contractVersion,
        contractVersionText,
        contractClubID,
        contractID,
        mainID,
        isInTransferOutClub,
        targetClubID,
        transferOutReason,
        isCompleteContract, // 是否為正式文件
    } = sourceData;

    const memberNoRef = useRef(null);
    const nameRef = useRef(null);
    const cellPhoneRef = useRef(null);
    const telRef = useRef(null);
    const addressRef = useRef(null);
    const transferClubRef = useRef(null);
    const checkedItem_4_Ref = useRef(null);
    const orderHCRef = useRef(null);
    const executeClubRef = useRef(null);
    const transferOutReasonRef = useRef(null);
    const applyDateRef = useRef(null);
    const apiSendingRef = useRef(false);

    const getAllParams = () => {
        const item_4 = checkedItem_4_Ref.current.getResult().map(({ checked }) => checked);
        const items = [false, false, false, false, ...item_4];
        const traClubParams = transferClubRef.current.getResult();
        const addressParams = addressRef.current.getResult();
        // 資料型別是布林值需要額外作類型轉換,component拿出來的都會是string要做轉換
        const radiosHC = orderHCRef.current.getResult().find(({ checked }) => checked);
        const executeClub = executeClubRef.current.getResult();

        return {
            ...traClubParams,
            ...addressParams,
            ...executeClub,
            memberID: referenceIDProps,
            memberNo: memberNoRef.current.getResult(),
            name: nameRef.current.getResult(),
            cellPhone: cellPhoneRef.current.getResult(),
            tel: telRef.current.getResult(),
            survey: { items },
            isOrderHC: radiosHC ? parseBoolean(radiosHC.value) : false,
            transferOutReason: transferOutReasonRef.current.getResult(),
            applyDate: applyDateRef.current.getResult(),
            contractVersion,
            contractID,
            mainID,
            isInTransferOutClub,
        };
    };

    const handleSubmitClick = () => {
        if (apiSendingRef.current) return false;
        if (!refIsRequiredError(transferClubRef, checkedItem_4_Ref, transferOutReasonRef, executeClubRef, applyDateRef)) {
            let params = getAllParams();
            apiSendingRef.current = true;
            postTransferOutContractDataApi(contractID, params);
        }
    };

    const handleTemporaryStorageClick = () => {
        if (apiSendingRef.current) return false;
        const params = getAllParams();
        apiSendingRef.current = true;
        postTemporaryTransferOutContractDataApi(params);
    };

    // 暫存會籍移出資料
    const postTemporaryTransferOutContractDataApi = async (params) => {
        const resp = await postDocTemporaryTransferOutContractApi(params);
        if (resp) {
            setShowPrompt(false);
            window.setTimeout(() => {
                onReturnHistory && onReturnHistory();
            }, 5);
        } else {
            apiSendingRef.current = false;
        }
    };

    // 送出會籍移出申請書
    const postTransferOutContractDataApi = async (id, params) => {
        const resp = await postTransferOutContractApi(id, params);
        if (resp && resp.contractID) {
            setShowPrompt(false);
            window.setTimeout(() => {
                history.push(
                    DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_PATH(peopleTypeProps, referenceIDProps, resp.contractID),
                );
            }, 5);
        } else {
            apiSendingRef.current = false;
        }
    };

    const doInitTransferOutDataApi = async () => {
        const resp = await getTransferOutContractApi(contractIDProps, {
            id: referenceIDProps,
        });
        if (resp) {
            setSourceData(resp);
            setMounted(true);
        } else {
            setShowPrompt(false);
            window.setTimeout(() => {
                onReturnHistory && onReturnHistory();
            }, 5);
        }
    };

    useEffect(
        () => {
            doInitTransferOutDataApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [],
    );

    const { clubOptions = [] } = sourceData.resource || {}; // 防止意外
    const { items = [] } = survey;

    return (
        <React.Fragment>
            <Card>
                {isMounted && (
                    <Card.CardContent className={'pt-4'}>
                        <FieldGroup label={'會員資料'}>
                            <Grid xs={4} item>
                                <TextField
                                    ref={memberNoRef}
                                    label="會員編號"
                                    defaultValue={memberNo}
                                    readOnly={isReadOnly}
                                    disabled={!isReadOnly}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={4} item>
                                <TextField ref={nameRef} label="會員姓名" defaultValue={name} readOnly={isReadOnly} disabled={!isReadOnly} fullWidth required />
                            </Grid>
                            <Grid xs={4} item></Grid>
                            <Grid xs={4} item>
                                <TextField
                                    ref={cellPhoneRef}
                                    label="手機"
                                    defaultValue={cellPhone}
                                    readOnly={isReadOnly}
                                    disabled={!isReadOnly}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={4} item>
                                <TextField ref={telRef} label="住家電話" defaultValue={tel} readOnly={isReadOnly} disabled={!isReadOnly} fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <AddressField
                                    ref={addressRef}
                                    label="通訊地址"
                                    address={address}
                                    postCode={postCode}
                                    readOnly={isReadOnly}
                                    disabled={!isReadOnly}
                                    fullWidth
                                />
                            </Grid>
                        </FieldGroup>
                        <FieldGroup label={'移籍資訊'}>
                            <Grid item xs={12}>
                                <MembershipTransferOutTransferClubCard
                                    ref={transferClubRef}
                                    options={clubOptions}
                                    originalClubID={originalClubID}
                                    transferClubID={targetClubID}
                                    isReadOnly={isReadOnly}
                                />
                            </Grid>
                        </FieldGroup>
                        <FieldGroup label={'確認事項'}>
                            <Grid xs={12} item>
                                <ol className="lists ol">
                                    <li className="lists-item">
                                        <p>
                                            移籍原因 (必要欄位請會員務必親填，空白無效)
                                            <span className="asterisk">*</span>
                                        </p>
                                        <TextField
                                            ref={transferOutReasonRef}
                                            defaultValue={transferOutReason}
                                            minRows={3}
                                            multiline
                                            readOnly={isReadOnly}
                                            required
                                            fullWidth
                                        />
                                    </li>
                                    <li className="lists-item">
                                        <RadioField
                                            defaultValue={isOrderHC}
                                            ref={orderHCRef}
                                            label="目前我是否有循環訂購 Curves 健康產品？ (如蛋白、沛菌多...等。)"
                                            className="form-control-align-horizontal"
                                            required
                                            readOnly={isReadOnly}
                                            disabled={!isReadOnly}
                                            row
                                        >
                                            <RadioField.Radio value={true} label="是" />
                                            <RadioField.Radio value={false} label="否" />
                                        </RadioField>
                                    </li>
                                </ol>
                            </Grid>
                            <Grid xs={12} item>
                                <CheckboxField className={'pl-2'} ref={checkedItem_4_Ref} defaultChecked={[items[4]]} readOnly={isReadOnly}>
                                    <CheckboxField.Checkbox value={true} label={'會員會籍狀態有效，不在暫停期間內，且需要結清所有款項'} />
                                </CheckboxField>
                            </Grid>
                        </FieldGroup>
                        <FieldGroup label={'教練填寫'}>
                            <Grid xs={12} item>
                                <Alert severity="info" variant="filled" color="info">
                                    提醒接收店舖，如會員為健康產品循環訂戶，請將此張申請書掃描後，寄至健康產品客服信箱:service@curves.com.tw。
                                </Alert>
                            </Grid>
                            <MembershipTransferOutExecuteClubCard
                                ref={executeClubRef}
                                options={clubOptions}
                                readOnly={isReadOnly}
                                clubID={contractClubID}
                                employeeID={employeeID}
                            />
                            <Grid xs={4} item>
                                <DateField label="申請日期" ref={applyDateRef} defaultValue={parseDate(applyDate)} required fullWidth readOnly={isReadOnly} />
                            </Grid>
                        </FieldGroup>
                        <Box className="btn-group bg-light py-3 text-center">
                            {isReadOnly ? (
                                <Button variant="contained" color="secondary" className="btn-minWidth" onClick={() => onReturnHistory(referenceIDProps)}>
                                    返回
                                </Button>
                            ) : (
                                <React.Fragment>
                                    {!isCompleteContract && (
                                        <Button variant="outlined" color="secondary" className="btn-minWidth" onClick={handleTemporaryStorageClick}>
                                            暫存檔案
                                        </Button>
                                    )}
                                    <Button variant="contained" color="secondary" className="btn-minWidth" onClick={handleSubmitClick}>
                                        確認送出
                                    </Button>
                                </React.Fragment>
                            )}
                        </Box>
                        <Typography variant="body2" className="bg-light text-center mb-0 pb-2 px-2 font-color-3">
                            {contractVersionText}
                        </Typography>
                    </Card.CardContent>
                )}
            </Card>
            {!isReadOnly && <PromptDialog when={showPrompt} />}
        </React.Fragment>
    );
};

export default MembershipTransferOutContent;
