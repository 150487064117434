import React, { useMemo, useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { Card, Alert } from '@common/components/';
import InBodyInterviewMeasureNormalBox, {
    inBodyInterviewMeasureItems,
    inBodyInterviewNotesItems,
} from '@icoach/inbody/interview/InBodyInterviewMeasureNormalBox';
import InBodyInterviewDialogBox from '@icoach/inbody/interview/InBodyInterviewDialogBox';
import InBodyExtendDiv from '@icoach/inbody/components/InBodyExtendDiv';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import InBodyContext from '@icoach/inbody/components/InBodyContext';
import useInBodyApi from '@apis/useInBodyApi';
import useEmployeeApi from '@apis/useEmployeeApi';
import useShowLoading from '@util/hook/useShowLoading';

const MemberDetailInbodyContent = React.forwardRef((props, ref) => {
    const { memberID, setTab } = props;
    const tabs = props.match.params.tabs;
    const { getInterviewMemberMeasureApi } = useInBodyApi();
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const [sourceData, setSourceData] = useState({});
    const [options, setOptions] = useState({});
    const [isMounted, setMounted] = useState(false);
    const dialogRef = useRef(null);
    const measureContentRef = useRef(null);
    const showLoading = useShowLoading();

    const doInitInbodyMeasureDataApi = async () => {
        showLoading(true);
        const resp = await getInterviewMemberMeasureApi(memberID);
        const employeesOptions = await getEmployeesOptionsApi();
        if (employeesOptions) {
            setOptions({ employeesOptions: employeesOptions });
        }
        setSourceData(resp);
        setMounted(true);
        showLoading(false);
    };

    const inbodyInterviewValue = {
        dialogRef: dialogRef,
        setDialogRef: (el) => (dialogRef.current = el),
        peopleID: memberID,
        setSourceData: setSourceData,
        options: options,
    };

    const inbodyMeasureLabelMemo = useMemo(
        () => {
            return [...inBodyInterviewMeasureItems, ...inBodyInterviewNotesItems.slice(1)];
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            doInitInbodyMeasureDataApi();
            if (tabs) {
                setTab(tabs);
            }
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <InBodyContext.Provider value={inbodyInterviewValue}>
            <React.Fragment>
                <Card>
                    <Card.CardContent className="p-4">
                        {isMounted && !_.isEmpty(sourceData.measureData) ? (
                            <div className="inbody_measure">
                                <div className="container" ref={measureContentRef}>
                                    {inbodyMeasureLabelMemo.map(({ uuid, value, rowClass, key, formatCell, style: styleProps = {} }) => {
                                        let styles = { ...styleProps };
                                        return (
                                            <div key={uuid} className={clsx('row', rowClass, sourceData.length === 0 && 'full-width')} style={{ ...styles }}>
                                                {/* Column 標題 */}
                                                <div className={clsx('item', 'sticky')}>{value}</div>
                                                <InBodyInterviewMeasureNormalBox
                                                    sourceData={sourceData.measureData}
                                                    itemKey={key}
                                                    styles={styles}
                                                    formatCell={formatCell}
                                                />
                                                <InBodyExtendDiv />
                                            </div>
                                        );
                                    })}
                                </div>
                                <ScrollerArrowButton arrowLeftPos={150} moveDistance={584} parentRef={measureContentRef} />
                            </div>
                        ) : (
                            <Alert color="info" severity="info" variant="filled">
                                尚無量身資料
                            </Alert>
                        )}
                    </Card.CardContent>
                </Card>
                <InBodyInterviewDialogBox />
            </React.Fragment>
        </InBodyContext.Provider>
    );
});

export default MemberDetailInbodyContent;
