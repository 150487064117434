import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import GradientColorHeader from '../icoach/components/dialog/GradientColorHeader';
import useAjax from '../apis/useAjax';
import { Button, TextField, Alert, Form } from '../components/index';
import { localUser } from '../util/utils';
// img
import '../mockjs/MockJs';
import LoginHeader_1 from '../../images/login_1.svg';
import LoginHeader_2 from '../../images/login_2.svg';
import LoginLogo from '../../images/logo-white.svg';
import LoginFooterBg from '../../images/footer-bg.svg';

const entrancePathRoot = {
    1: '/admin',
    2: '/club',
};

const MessageDialog = (props) => {
    const { open = false, text: textProps, onClose: onCloseProps } = props;
    const handleClose = (e) => {
        onCloseProps(e, false);
    };
    return (
        <Dialog open={open} fullWidth>
            <GradientColorHeader>訊息</GradientColorHeader>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12}>
                        <Alert variant="filled" severity="error" color="error">
                            {textProps}
                        </Alert>
                    </Grid>
                    <Grid item xs={12} className="flex flex-y-flex-center my-2">
                        <Button
                            size="large"
                            variant="contained"
                            color="secondary"
                            onClick={handleClose}
                        >
                            確定
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const LoginPage = () => {
    const history = useHistory();
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const Ajax = useCallback(useAjax(), []);
    const [isShowDialog, setShowDialog] = useState({
        open: false,
        text: null,
    });
    const usernameRef = useRef('');
    const passwordRef = useRef('');

    const handleShowDialog = (e, isOpen) => {
        setShowDialog((pre) => ({ ...pre, open: isOpen }));
    };

    const handleLoginApi = (e) => {
        e.preventDefault();
        e.stopPropagation();
        getResult();
    };

    const getResult = () => {
        if (!usernameRef.current.isError() & !passwordRef.current.isError()) {
            const usr = usernameRef.current.getResult();
            const pwd = passwordRef.current.getResult();
            doLoginApi(usr, pwd);
        }
    };

    const doLoginApi = async (usr, pwd) => {
        await Ajax.post(
            '/api/login/',
            { username: usr, password: pwd },
            {
                callbackfn: (oriData, apiData) => {
                    const { data } = apiData;
                    if (!data.success) {
                        setShowDialog((pre) => ({
                            ...pre,
                            open: true,
                            text: apiData.data.message,
                        }));
                    } else {
                        loginAction(data.result);
                    }
                },
            }
        );
    };

    const loginAction = async (_result) => {
        const { routerType, ...others } = _result;
        const firstPath = entrancePathRoot[routerType];
        if (firstPath) {
            localUser.set({ ...others });
            history.push(entrancePathRoot[routerType]);
        } else {
            _snackbar('權限錯誤,請通知IT人員協助排解', {
                variant: 'error',
            });
            await Ajax.get('/api/logout/');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            let resp = await Ajax.get('/api/me/check/');
            if (resp) {
                resp = await Ajax.get('/api/me/');
                resp && loginAction(resp);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className="login-header">
                <div className="login-header-container">
                    <div className="login-header-bg">
                        <img src={LoginHeader_1} className="login-header-1" alt="curves" />
                        <img src={LoginHeader_2} className="login-header-2" alt="curves" />
                        <img src={LoginLogo} className="login-logo" alt="logo" />
                    </div>
                </div>
            </div>
            <div className="login-body">
                <div className="login-body-container">
                    <Form className="login-form" onSubmit={handleLoginApi}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    ref={usernameRef}
                                    label="帳號"
                                    helperText="帳號不得為空值"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    ref={passwordRef}
                                    label="密碼"
                                    helperText="密碼不得為空值"
                                    type="password"
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                        <div className="login-btn-confirm">
                            <Button variant="outlined" type="submit">
                                登入
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="login-footer">
                <img src={LoginFooterBg} alt="FooterBackground" />
                <div className="login-copywrite ban-select">
                    <span>版權所有 © 2022 Curves 可爾姿女性30分鐘環狀運動</span>
                </div>
            </div>
            <MessageDialog
                open={isShowDialog.open}
                text={isShowDialog.text}
                onClose={handleShowDialog}
            />
        </React.Fragment>
    );
};

export default LoginPage;
