import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, Box } from '@common/components/';
import {
    Developer as DeveloperIcon,
    Store as StoreIcon,
    UserAlt as UserAltIcon,
    UserPlus as UserPlusIcon,
    ListUl as ListUlIcon,
    ChartPie as ChartPieIcon,
    Star as StarIcon,
} from '@common/SvgIcon/';
import {
    DATA_ANALYSIS_EMPLOYEES_URL_ROUTER,
    DATA_ANALYSIS_GOAL_URL_ROUTER,
    DATA_ANALYSIS_GUEST_PRODUCTION_URL_ROUTER,
    DATA_ANALYSIS_SALES_URL_ROUTER,
    DATA_ANALYSIS_SERVICE_URL_ROUTER,
    DATA_ANALYSIS_TASK_MATTER_URL_ROUTER,
    DATA_ANALYSIS_MEMBERSHIP_URL_ROUTER,
} from '@icoach/router/routerPath';
import { parseDate } from '@util/moment';
import DataAnalysisContentMembership from './DataAnalysisContentMembership';
import DataAnalysisContentTaskMatter from './DataAnalysisContentTaskMatter';
import DataAnalysisContentService from './DataAnalysisContentService';
import DataAnalysisContentEmployees from './DataAnalysisContentEmployees';
import DataAnalysisContentGuestProduction from './DataAnalysisContentGuestProduction';
import DataAnalysisContentSales from './DataAnalysisContentSales';
import DataAnalysisContentKpi from './DataAnalysisContentKpi';

const DataAnalysisPage = (props) => {
    const { tabs } = useParams();
    const [tabsIndex, setTabsIndex] = useState('');
    const searchDateRef = React.useRef(parseDate());
    const handleTabChange = (_, value) => {
        setTabsIndex(value);
    };

    useEffect(
        () => {
            let tabsPath = tabs;
            if (!tabs) {
                tabsPath = 'goal';
            }
            setTabsIndex(tabsPath);
        },
        // eslint-disable-next-line
        [],
    );

    // if (!propsTabsIndex) return null;
    return (
        <Box className="container main-container-spacing">
            <Box className="page-tabs tabs-bottom-line mb-4">
                <Tabs value={tabsIndex} onChange={handleTabChange}>
                    <Tab className={'py-3'} label={'目標進度'} value={'goal'} icon={<DeveloperIcon />} component={NavLink} to={DATA_ANALYSIS_GOAL_URL_ROUTER} />
                    <Tab
                        className={'py-3'}
                        label={'會籍'}
                        value={'membership'}
                        icon={<UserAltIcon />}
                        component={NavLink}
                        to={DATA_ANALYSIS_MEMBERSHIP_URL_ROUTER}
                    />
                    <Tab className={'py-3'} label={'服務'} value={'service'} icon={<StarIcon />} component={NavLink} to={DATA_ANALYSIS_SERVICE_URL_ROUTER} />
                    <Tab
                        className={'py-3'}
                        label={'顧客製造'}
                        value={'guest-production'}
                        icon={<UserPlusIcon />}
                        component={NavLink}
                        to={DATA_ANALYSIS_GUEST_PRODUCTION_URL_ROUTER}
                    />
                    <Tab className={'py-3'} label={'非會籍'} value={'sales'} icon={<ChartPieIcon />} component={NavLink} to={DATA_ANALYSIS_SALES_URL_ROUTER} />
                    <Tab
                        className={'py-3'}
                        label={'教練'}
                        value={'employees'}
                        icon={<StoreIcon />}
                        component={NavLink}
                        to={DATA_ANALYSIS_EMPLOYEES_URL_ROUTER}
                    />
                    <Tab
                        className={'py-3'}
                        label={'管理'}
                        value={'task-matter'}
                        icon={<ListUlIcon />}
                        component={NavLink}
                        to={DATA_ANALYSIS_TASK_MATTER_URL_ROUTER}
                    />
                </Tabs>
            </Box>
            {tabsIndex === 'goal' && <DataAnalysisContentKpi defaultDate={searchDateRef} />}
            {tabsIndex === 'membership' && <DataAnalysisContentMembership defaultDate={searchDateRef} />}
            {tabsIndex === 'service' && <DataAnalysisContentService defaultDate={searchDateRef} />}
            {tabsIndex === 'guest-production' && <DataAnalysisContentGuestProduction defaultDate={searchDateRef} />}
            {tabsIndex === 'sales' && <DataAnalysisContentSales defaultDate={searchDateRef} />}
            {tabsIndex === 'employees' && <DataAnalysisContentEmployees defaultDate={searchDateRef} />}
            {tabsIndex === 'task-matter' && <DataAnalysisContentTaskMatter />}
        </Box>
    );
};

export default DataAnalysisPage;
