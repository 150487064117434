import React, { useEffect, useRef, useState } from 'react';
import { Card, Tab, Tabs, Typography } from '@common/components/';
import { ReportTableHeader, SearchBar } from '@icoach/dataAnalysis/components/';
import { HCDailyTable, HCMonthlyTable, GoodsMonthlyTable } from '@icoach/dataAnalysis/tables/';
import useReportApi from '@apis/useReportApi';
import { salesTabItemTypeMap, tableTargetMap } from './staticData';


const SalesReportTableContent = (props) => {
    const { data = {} } = props;
    const [tableTarget, setTable] = useState(tableTargetMap.monthly);
    const [tabsIndex, setTabsIndex] = useState(salesTabItemTypeMap.hc);

    const handleTabChange = (_, value) => {
        setTabsIndex(value);
    };
    const handleToggleTable = (target) => {
        setTable(target);
    };
    return (
        <Card className={'mb-4'}>
            <Card.CardContent className={'px-4 pt-1 pb-4'}>
                <div className="page-tabs tabs-bottom-line mb-4">
                    <Tabs
                        value={tabsIndex}
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={handleTabChange}
                    >
                        <Tab className={'py-3'} label={'HC'} value={salesTabItemTypeMap.hc} />
                        <Tab className={'py-3'} label={'商品'} value={salesTabItemTypeMap.goods} />
                    </Tabs>
                </div>
                {tabsIndex === salesTabItemTypeMap.hc && (
                    <React.Fragment>
                        <ReportTableHeader
                            className={'mb-2'}
                            tableTarget={tableTarget}
                            onToggleTable={handleToggleTable}
                            groupButtonData={[
                                {
                                    title: '每日記錄',
                                    text: '零售',
                                    value: tableTargetMap.daily,
                                },
                                {
                                    title: '月統計',
                                    text: '循環',
                                    value: tableTargetMap.monthly,
                                },
                            ]}
                        />
                        {tableTarget === tableTargetMap.daily && (
                            <HCDailyTable data={data[`${tabsIndex}Daily`] || []} />
                        )}
                        {tableTarget === tableTargetMap.monthly && (
                            <HCMonthlyTable rowsData={data[`${tabsIndex}Cumulative`] || []} />
                        )}
                    </React.Fragment>
                )}
                {tabsIndex === salesTabItemTypeMap.goods && (
                    <React.Fragment>
                        <Typography className={'font-weight-bold mb-2'} variant={'h5'}>
                            月統計
                        </Typography>
                        <GoodsMonthlyTable
                            rowsData={data[`${tabsIndex}Weekly`] || []}
                            cumulative={data[`${tabsIndex}Cumulative`] || []}
                        />
                    </React.Fragment>
                )}
            </Card.CardContent>
        </Card>
    );
};

const DataAnalysisContentSales = (props) => {
    const { getReportSalesApi } = useReportApi();
    const [data, setData] = useState({});
    const searchRef = useRef();

    const doReportSalesApi = async (params) => {
        let res = await getReportSalesApi(params);
        if (res) {
            setData(res);
        }
    };

    const getParams = () => {
        let result = {};
        if (searchRef.current && searchRef.current.getResult) {
            result = Object.assign(result, searchRef.current.getResult());
        }

        return result;
    };

    const handleSubmit = () => {
        let isError = searchRef.current && searchRef.current.isError();
        if (!isError) {
            let params = getParams();
            doReportSalesApi(params);
        }
    };

    useEffect(() => {
        handleSubmit();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <SearchBar ref={searchRef} className={'mb-4'} onSubmit={handleSubmit} defaultDate={props.defaultDate}/>
            <SalesReportTableContent data={data} />
        </React.Fragment>
    );
};

export default DataAnalysisContentSales;
