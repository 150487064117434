import { getFirstOfMonth, isBefore, isValidDate, parseDate } from '@util/moment';

export const ON_SITE_ORDER = '1';
export const BATCH_ORDER = '2';
export const COLOR_MATCH = {
    1: 'success',
    2: 'info',
    3: 'grey',
    4: 'warning',
    9: 'error',
};

export const initBookmark = () => ({
    pageIndex: 1,
    pageSize: 10,
});

export const filterTypeMap = new Map([
    ['newSalePrice', 1],
    ['membershipPrice', 2],
    ['productSalePrice', 3],
    ['healthcareSalePrice', 4],
    ['cabinetPrice', 5],
    ['offsite', 39],
    ['bonus', 51],
]);

export const orderStatusColorMap = new Map([
    [0, 'grey'],
    [1, 'success'],
    [2, 'info'],
    [3, 'grey'],
    [4, 'error'],
]);

export const billingStatusColorMap = new Map([
    [0, 'grey'],
    [1, 'success'],
    [2, 'info'],
    [3, 'grey'],
    [4, 'error'],
]);

export const orderItemTypeMap = new Map([
    [1, 'fee'],
    [2, 'fee'],
    [3, 'fee'],
    [4, 'fee'],
    [5, 'fee'],
    [6, 'fee'],
    [8, 'product'],
    [11, 'product'],
    [12, 'product'],
    [21, 'fee'],
    [22, 'fee'],
    [51, 'product'],
    [52, 'product'],
]);
export const billingCategoryMap = new Map([
    [1, '會籍月費'],
    [2, '租櫃月費'],
    [3, '健康產品'],
]);

export const dialogType = {
    allowance: 'allowance',
    invoiceAbandon: 'invoiceAbandon',
    allowanceAbandon: 'allowanceAbandon',
    deleteRemit: 'deleteRemit',
    resetOrderInvoice: 'resetOrderInvoice',
    deleteOrder: 'deleteOrder',
    reinitOrder: 'reinitOrder',
    payment: 'payment',
    shoppingMenu: 'shoppingMenu',
};

export const orderSourceTypeMap = {
    spot: 1,
    bulk: 2,
};

export const allowanceStatusMap = {
    abandon: 11,
};

export const receiverTypeMap = {
    member: '1',
    nonMember: '2',
    otherClubMember: '3',
    employee: '4',
    club: '5',
    headquarters: '6',
};

// 日期是否為當月之前
export const isBeforeOfCurrentMonth = (date) => {
    if (isValidDate(date)) {
        return isBefore(date, getFirstOfMonth(parseDate()));
    }
};

// 訂單或匯款的日期提示訊息
export const checkoutDateMessageConfig = {
    open: true,
    title: '資料檢核',
    msg: '依據會計原則不修改歷史報表，請在本月調整！',
};

// 收入與退費的類型，用於(現場/總)收入總覽訂單明細
export const paymentTypeTabs = {
    income: '1',
    refund: '2',
    incomeByRemit: '3',
    refundByRemit: '4',
};

// 統計方式
export const statisticalType = {
    orderType: '1',
    paymentType: '2',
};

export const bulkDebitBillingType = {
    billing: '1',
    currentOrders: '2',
    exemptedOrders: '3',
    overdueOrders: '4',
};

export const orderTypeMap = {
    membership: '1',
    rentCabinet: '2',
    cycleOrder: '3',
    1: 'membership',
    2: 'rent-cabinet',
    3: 'cycle-order',
};
export const orderType = {
    membership: 'membership',
    rentCabinet: 'rent-cabinet',
    cycleOrder: 'cycle-order',
};

// 資料求和
export const sumTotal = (data, keys) => {
    let initValue = {};
    keys.forEach((key) => {
        if (!initValue.hasOwnProperty(key)) {
            initValue[key] = 0;
        }
    });
    return data.reduce((previousValue, currentValue) => {
        let result = {};
        keys.forEach((key) => {
            let total = previousValue[key];
            if (currentValue[key]) {
                total = parseFloat(previousValue[key]) + parseFloat(currentValue[key]);
            }
            result[key] = total;
        });
        return result;
    }, initValue);
};
