export const dialogTypeMap = {
    membershipRecord: 'membershipRecord',
    gift: 'gift',
    suspension: 'suspension',
    membershipBillingRecord: 'membershipBillingRecord',
    extension: 'extension',
    deleteDoc: 'deleteDoc',
    temporaryDoc: 'temporaryDoc',
    quitAllCabinets: 'quitallcabinets',
};

export const membershipItemReasonsFilterTypeMap = {
    suspension: 1,
    gift: 2,
    extension: 4,
    all: 7,
};

// 對象搜尋欄位的資料
export const CHOOSE_TARGET = {
    1: {
        label: '顧客',
        placeholder: '請輸入姓名/手機號碼(擇一即可)',
    },
    2: { label: '會員', placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可)' },
    3: { label: '無效會員', placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可)' },
    4: { label: '承接會員', placeholder: '請輸入姓名/手機號碼(擇一即可)' },
    5: { label: '名單', placeholder: '請輸入姓名/手機號碼(擇一即可)' },
    6: { label: '會員', placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可)' }, //全會員
    7: { label: '新入會', placeholder: '' },
    8: { label: 'FA顧客', placeholder: '請輸入姓名/手機號碼(擇一即可)' },
    10: { label: '移入會員', placeholder: '請輸入姓名/手機號碼(擇一即可)' },
    13: { label: '暫存名單', placeholder: '請輸入姓名/手機號碼(擇一即可)' },
    14: { label: '已移入會員', placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可))' },
    16: { label: '待確認移出會員', placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可))' },
    17: { label: '已移出會員', placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可))' },
    99: {label:'非顧客', placeholder: '請輸入姓名/手機號碼'}
};

// 付款方式
export const PAYMENT_TYPE_OPTIONS = [
    {
        text: '信用卡自動扣款',
        value: 1,
        disabled: false,
    },
    {
        text: '銀行自動扣款',
        value: 2,
        disabled: false,
    },
    {
        text: '現金',
        value: 3,
        disabled: false,
    },
];

// ACH欄位 初始值
export const ACH_INITIAL_STATE = {
    bankCode: '',
    bankName: '',
    bankAccount: '',
    bankAccountName: '',
    bankAccountID: '',
};

// 信用卡欄位 初始值
export const CREDIT_INITIAL_STATE = {
    creditCardBankName: '',
    creditCardType: '',
    creditCardNo: '',
    cardExpiryDate: '',
    cardName: '',
};

export const CONTRACT_VERSION_MAP = [
    {
        text: '版本101',
        value: 101,
    },
    {
        text: '版本102',
        value: 102,
    },
    {
        text: '版本103',
        value: 103,
    },
];

// 空白表單的連結
export const DOC_MEMBERSHIP_LINK = [
    {
        text: '2020.09',
        link: '/doc/membership/template.pdf?version=101',
    },
    {
        text: '2022.03',
        link: '/doc/membership/template.pdf?version=102',
    },
];

export const DOC_MEMBERSHIP_TRANSFEROVER_LINK = [
    {
        text: '2020.09',
        link: '/doc/membership-transfer/template.pdf?version=101',
    },
    {
        text: '2022.03',
        link: '/doc/membership-transfer/template.pdf?version=102',
    },
];

export const DOC_MEMBERSHIP_SUSPEND_LINK = [
    {
        text: '2020.09',
        link: '/doc/membership-suspend/template.pdf?version=101',
    },
    {
        text: '2022.03',
        link: '/doc/membership-suspend/template.pdf?version=102',
    },
];
export const DOC_MEMBERSHIP_QUIT_LINK = [
    {
        text: '2020.09',
        link: '/doc/membership-quit/template.pdf?version=101',
    },
    {
        text: '2022.03',
        link: '/doc/membership-quit/template.pdf?version=102',
    },
];
export const DOC_HEALTH_DECLARATION_LINK = [
    {
        text: '2020.09',
        link: '/doc/health-declaration/template.pdf?version=101',
    },
    {
        text: '2022.03',
        link: '/doc/health-declaration/template.pdf?version=102',
    },
];
export const DOC_MEMBERSHIP_TRANSFER_IN_LINK = [
    {
        text: '2020.09',
        link: '/doc/membership-transfer-in/template.pdf?version=101',
    },
    {
        text: '2022.03',
        link: '/doc/membership-transfer-in/template.pdf?version=102',
    },
];

export const DOC_MEMBERSHIP_TRANSFER_OUT_LINK = [
    {
        text: '2020.09',
        link: '/doc/membership-transfer-out/template.pdf?version=101',
    },
    {
        text: '2022.03',
        link: '/doc/membership-transfer-out/template.pdf?version=102',
    },
];

export const DOC_INTERNATIONAL_TRANSFER_LINK = [
    {
        text: '通用版本',
        link: '/doc/international-transfer/template.pdf',
    },
];

export const DOC_TRAVEL_PASS_LINK = [
    {
        text: '通用版本',
        link: '/doc/travel-pass/template.pdf',
    },
];
