import React, { useRef, useEffect, useMemo, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import MemberOverviewTabs from '@icoach/members/overview/MemberOverviewTabsHeader';
import { initBookmark } from '@icoach/members/staticData';
import {
    FilterBirthdayLabel,
    FilterBirthdaySearch,
    FilterExtensionLabel,
    FilterHundredTShirtLabel,
    FilterHundredTShirtSearch,
    FilterInvalidLabel,
    FilterInvalidSearch,
    FilterNewMemberLabel,
    FilterLeaveLabel,
    FilterLeaveSearch,
    FilterWorkOutTimesLabel,
    FilterWorkOutTimesSearch,
    FilterHealthCareOrderLabel,
    FilterHealthCareOrderSearch,
    FilterNewMemberSearch,
} from '@icoach/members/overview/searchIndex';
import useMembers from '@icoach/members/components/useMembers';
import { refIsRequiredError, objectToQueryString, paramsToObject } from '@util/utils';
import { isNumber } from '@util/math';
import { SelectField, MenuItem, Card, Button, Form, Typography } from '@common/components/';
import useMemberFilterApi from '@apis/useMemberFilterApi';
import useMemberFilterExportApi from '@apis/useMemberFilterExportApi';
import { MEMBERS_MEMBER_OVERVIEW_QUICK_URL } from '@icoach/router/MembersRouter';
import useMessageDialog from '@util/hook/useMessageDialog';
import useWindowScrollPositions from '@util/hook/useWindowScrollPositions';

const quickEnums = [
    { value: 1, text: '次數名單' },
    { value: 2, text: '續約名單' },
    { value: 3, text: '請假名單' },
    { value: 4, text: '無效名單' },
    { value: 5, text: '生日名單' },
    { value: 6, text: '百T名單' },
    { value: 7, text: '新會員名單' },
    { value: 8, text: '健康產品名單' },
];

const OverviewFilterSearchBar = (props) => {
    const { tabsIndex, targetType: quickType } = props;
    const history = useHistory();
    const {
        setSourceData,
        setRowsLabel,
        setSortOption,
        setBookmarkSortBy,
        setBookmarkNum,
        setResource,
        bookmark,
        resource = {},
        reSetCheckedItems,
    } = useMembers();
    const {
        workoutFrequencyOrderBy: workoutFrequencyOrderOptions = [],
        healthcareOrderBy: healthcareOrderOptions = [],
        reNewOrderBy: extensionSortOption = [],
        birthdayOrderBy: birthdaySortOption = [],
        invalidOrderBy: invalidSortOption = [],
        suspensionOrderBy: leaveSortOption = [],
        hundredTShirtOrderBy: hundredTShirtSortOption = [],
        newOrderBy: newMemberSortOption = [],
    } = resource;
    const {
        getMemberFilterOptionsApi,
        getWorkOutFrequencyDataApi,
        getMemberBirthDayDataApi,
        getRenewFilterDataApi,
        getLeaveFilterDataApi,
        getInvalidFilterDataApi,
        getHundredTShirtDataApi,
        getNewMemberFilterDataApi,
        postHealthcareFilterDataApi,
    } = useMemberFilterApi();
    const {
        getMemberFilterWorkoutFrequencyApi,
        getMemberFilterHundredTShirtApi,
        getMemberFilterNewApi,
        getMemberFilterInvalidApi,
        getMemberFilterBirthdayApi,
        getMemberFilterLeaveApi,
        getMemberFilterRenewApi,
        postMemberFilterHealthcareApi,
    } = useMemberFilterExportApi();
    const [paramsData, setParamsData] = useState({}); // 初始預設搜尋條件使用,其他狀況下無用
    const [isMounted, setMounted] = useState(false);
    const memberFilterTypeRef = useRef(null);
    const searchPanelsRef = useRef(null);
    const preloadRef = useRef(false);
    const setMessageDialog = useMessageDialog();
    useWindowScrollPositions();

    const getParams = (targetPage = {}) => {
        let paramsJson = { ...targetPage };
        if (searchPanelsRef.current && searchPanelsRef.current.getResult) {
            const target = searchPanelsRef.current.getResult() || {};
            Object.assign(paramsJson, target);
        }
        return paramsJson;
    };

    const handleSearchOnClick = (e) => {
        if (!refIsRequiredError(memberFilterTypeRef, searchPanelsRef)) {
            reSetCheckedItems();
            const params = getParams(initBookmark(bookmark?.sortBy));
            setBookmarkNum(1);
            doSearchApi(params);
            preloadRef.current = true; // 防止切換名單後無法更多與排序
        }
    };

    // 下載excel 寄信給店舖
    const handleExcelOnClick = () => {
        if (!refIsRequiredError(memberFilterTypeRef, searchPanelsRef)) {
            const params = getParams(initBookmark(bookmark?.sortBy));
            doExcelSendEmailApi(params);
            preloadRef.current = true; // 防止切換名單後無法更多與排序
        }
    };

    // 切換類型
    const handleSelectOnChange = (e, dom, value) => {
        history.push(MEMBERS_MEMBER_OVERVIEW_QUICK_URL(value));
        preloadRef.current = false;
    };

    const memberFilterMap = useMemo(
        () => {
            return {
                1: {
                    description: '次數名單',
                    rowLabel: FilterWorkOutTimesLabel,
                    sortOption: workoutFrequencyOrderOptions,
                    Component: FilterWorkOutTimesSearch,
                    excelApi: getMemberFilterWorkoutFrequencyApi,
                    doApi: getWorkOutFrequencyDataApi,
                },
                2: {
                    description: '續約名單',
                    rowLabel: FilterExtensionLabel,
                    sortOption: extensionSortOption,
                    Component: null,
                    excelApi: getMemberFilterRenewApi,
                    doApi: getRenewFilterDataApi,
                },
                3: {
                    description: '請假名單',
                    rowLabel: FilterLeaveLabel,
                    sortOption: leaveSortOption,
                    Component: FilterLeaveSearch,
                    excelApi: getMemberFilterLeaveApi,
                    doApi: getLeaveFilterDataApi,
                },
                4: {
                    description: '無效名單',
                    rowLabel: FilterInvalidLabel,
                    sortOption: invalidSortOption,
                    Component: FilterInvalidSearch,
                    excelApi: getMemberFilterInvalidApi,
                    doApi: getInvalidFilterDataApi,
                },
                5: {
                    description: '生日名單',
                    rowLabel: FilterBirthdayLabel,
                    sortOption: birthdaySortOption,
                    Component: FilterBirthdaySearch,
                    excelApi: getMemberFilterBirthdayApi,
                    doApi: getMemberBirthDayDataApi,
                },
                6: {
                    description: '百T名單',
                    rowLabel: FilterHundredTShirtLabel,
                    sortOption: hundredTShirtSortOption,
                    Component: FilterHundredTShirtSearch,
                    excelApi: getMemberFilterHundredTShirtApi,
                    doApi: getHundredTShirtDataApi,
                },
                7: {
                    description: '新會員名單',
                    rowLabel: FilterNewMemberLabel,
                    sortOption: newMemberSortOption,
                    Component: FilterNewMemberSearch,
                    excelApi: getMemberFilterNewApi,
                    doApi: getNewMemberFilterDataApi,
                },
                8: {
                    description: '健康產品名單',
                    rowLabel: FilterHealthCareOrderLabel,
                    sortOption: healthcareOrderOptions,
                    Component: FilterHealthCareOrderSearch,
                    excelApi: postMemberFilterHealthcareApi,
                    doApi: postHealthcareFilterDataApi,
                },
            };
        },
        // eslint-disable-next-line
        [resource]
    );

    // 快速名單初始排序options、重刷頁面進來
    const doInitSortOptionsApi = async () => {
        const resp = await getMemberFilterOptionsApi();
        if (resp) {
            setResource(resp);
        }
        // 重新載入頁面才會觸發，當下的頁面切換quickType不會進來
        const { location } = history;
        const par = paramsToObject(location.search);
        let pageModel = initBookmark(); // bookmark跟不上同步,故用此參數
        if (!_.isEmpty(par)) {
            setBookmarkNum(par.pageIndex);
            setBookmarkSortBy(par.sortBy);
            pageModel.sortBy = par.sortBy;
            pageModel.pageIndex = par.pageIndex;
            setParamsData(par);
        }
        setMounted(true);
        preloadRef.current = true;
        !_.isEmpty(par) && doSearchApi(getParams(pageModel));
    };

    // 快速名單總覽
    const doSearchApi = async (params) => {
        const doApi = memberFilterMap[quickType]['doApi'];
        const resp = await doApi(params);
        if (resp) {
            let { list, sortBy, ...pageParams } = resp;
            // 簡訊使用name當作key
            list = _.map(list, (par) => ({ name: par.memberName, ...par }));
            setSourceData((prev) => {
                let targetList = [...list];
                if (String(params.pageIndex) !== '1') {
                    targetList = [...(Array.isArray(prev.list) ? prev.list : []), ...targetList];
                }
                return {
                    ...pageParams,
                    list: targetList,
                };
            });
            const _r = `${MEMBERS_MEMBER_OVERVIEW_QUICK_URL(quickType)}${objectToQueryString(params)}`;
            history.replace(_r);
        }
        if (!_.isEmpty(paramsData)) {
            setParamsData({}); // 防止換回同類型初始參數
        }
    };

    const doExcelSendEmailApi = async (params) => {
        const doApi = memberFilterMap[quickType]['excelApi'];
        const resp = await doApi(params);

        if (resp) {
            let { message, url } = resp;
            setMessageDialog({
                open: true,
                title: '個資資訊安全原則',
                MsgComp: () => (
                    <div>
                        <Typography className="word-break-break-word">{message}</Typography>
                        <p />
                        <Button to={url} color="error">
                            保管責任書
                        </Button>
                    </div>
                ),
            });
        }
    };

    // 換頁
    useEffect(
        () => {
            if (preloadRef.current) {
                const params = getParams(bookmark);
                if (bookmark && String(bookmark.pageIndex) !== '1') {
                    const _r = `${MEMBERS_MEMBER_OVERVIEW_QUICK_URL(quickType)}${objectToQueryString(params)}`;
                    history.replace(_r);
                    doSearchApi(params);
                }
            }
        },
        // eslint-disable-next-line
        [bookmark.pageIndex]
    );

    // 變更排序
    useEffect(
        () => {
            if (preloadRef.current) {
                const params = getParams(bookmark);
                const _r = `${MEMBERS_MEMBER_OVERVIEW_QUICK_URL(quickType)}${objectToQueryString(params)}`;
                history.replace(_r);
                doSearchApi(params);
            }
        },
        // eslint-disable-next-line
        [bookmark.sortBy]
    );

    useEffect(
        () => {
            if (isNumber(quickType)) {
                reSetCheckedItems();
                const { rowLabel = [], sortOption = [] } = memberFilterMap[quickType];
                setRowsLabel(rowLabel);
                setSortOption(sortOption);
            }
            return () => {
                setSourceData([]);
                setRowsLabel([]);
                setBookmarkSortBy(1);
                setBookmarkNum(1);
            };
        },
        // eslint-disable-next-line
        [quickType, memberFilterMap]
    );

    // 初始排序options
    useEffect(
        () => {
            if (_.isEmpty(resource)) {
                doInitSortOptionsApi();
            }
            return () => {
                setResource({});
                setBookmarkSortBy(1);
                setBookmarkNum(1);
            };
        },
        // eslint-disable-next-line
        []
    );

    const CompPanel = quickType ? memberFilterMap[quickType]['Component'] : null;

    return (
        <Form onSubmit={handleSearchOnClick}>
            <Card>
                <Card.CardContent className="members-tabs tabs-bottom-line">
                    <MemberOverviewTabs value={tabsIndex} />
                </Card.CardContent>
                <Card.CardContent className="pb-4 px-2">
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <SelectField label="篩選名單" value={quickType} onChange={handleSelectOnChange} ref={memberFilterTypeRef} fullWidth>
                                {quickEnums.map(({ value, text }) => {
                                    return (
                                        <MenuItem key={value} value={value}>
                                            {text}
                                        </MenuItem>
                                    );
                                })}
                            </SelectField>
                        </Grid>
                        {isMounted && CompPanel ? <CompPanel ref={searchPanelsRef} resource={resource} paramsData={paramsData} /> : null}
                        <Grid xs={3} item>
                            <Stack direction={'row'} spacing={1} className="pt-4">
                                <Button variant="contained" type="submit">
                                    搜尋
                                </Button>
                                <Button variant="contained" color="success" onClick={handleExcelOnClick}>
                                    下載Excel
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Card>
        </Form>
    );
};

export default OverviewFilterSearchBar;
