import React, { useState, useRef, useImperativeHandle } from 'react';
import { Button, Card, DateField, Stack, Form, MenuItem, ListItemText, Menu, Typography } from '@common/components/';
import { parseDate, isValidDate, getNowTime, getDiffDate } from '@util/moment';
import { refIsRequiredError } from '@util/utils';
import useReportExportApi from '@apis/useReportExportApi';
import moment from 'moment';

const DownloadButtonGroup = (props) => {
    const { className, getParams, checkError } = props;
    const { getReportCAExcelApi, getReportPJExcelApi } = useReportExportApi();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const doReportPJExcelApi = () => {
        let params;
        let isError = checkError();
        if (!isError) {
            params = getParams();
            getReportPJExcelApi(params);
        }
    };

    const doReportCAExcelApi = () => {
        let params;
        let isError = checkError();
        if (!isError) {
            params = getParams();
            getReportCAExcelApi(params);
        }
    };

    return (
        <React.Fragment>
            <Button className={className} variant="outlined" color="secondary" onClick={handleOpenMenu}>
                下載報表
            </Button>
            <Menu open={open} onClose={handleCloseMenu} anchorEl={anchorEl}>
                <MenuItem onClick={doReportPJExcelApi}>
                    <ListItemText>PJ 報表</ListItemText>
                </MenuItem>
                <MenuItem onClick={doReportCAExcelApi}>
                    <ListItemText>CA 報表</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

const SearchBar = React.forwardRef((props, ref) => {
    const { className, onSubmit, defaultDate } = props;
    const monthRef = useRef(null);

    const compareMin10 = parseDate(getNowTime(), 'YYYY-MM-DDTHH:10:00');
    const compareMin40 = parseDate(getNowTime(), 'YYYY-MM-DDTHH:40:00');
    const prevMin40 = parseDate(moment().subtract(1, 'hour'), 'YYYY-MM-DDTHH:40:00');

    const updateDate = moment(
        getDiffDate(getNowTime(), compareMin10, 'seconds') < 0
            ? prevMin40
            : getDiffDate(getNowTime(), compareMin40, 'seconds') < 0
            ? compareMin10
            : compareMin40
    ).format('HH:mm');
    const getResult = () => {
        let result = {};
        if (monthRef.current && monthRef.current.getResult) {
            result = Object.assign(result, {
                date: parseDate(monthRef.current.getResult(), 'YYYY-MM'),
            });
        }
        return result;
    };

    const handleOnDateChange = (_e, dateValue) => {
        if (isValidDate(dateValue)) {
            defaultDate.current = dateValue;
        }
    };

    const checkError = () => refIsRequiredError(monthRef);

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: checkError,
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <Form onSubmit={onSubmit}>
            <Card className={className}>
                <Card.CardContent className={'px-4 pt-2 pb-3'}>
                    <Stack className={'mb-1'} alignItems={'start'} spacing={3}>
                        <DateField
                            ref={monthRef}
                            label="報表月份"
                            defaultValue={parseDate(defaultDate?.current ?? null)}
                            views={['year', 'month']}
                            openTo="month"
                            format="YYYY/MM"
                            mask="____/__"
                            maxDate={parseDate()}
                            onChange={handleOnDateChange}
                            required
                        />
                        <Button className={'mt-4'} type={'submit'} variant={'contained'}>
                            查詢
                        </Button>
                        <DownloadButtonGroup className={'mt-4'} getParams={getResult} checkError={checkError} />
                    </Stack>
                    <Typography className={'mb-0 font-color-3'} variant={'body2'}>
                        {`數據更新時間為${updateDate}`}
                    </Typography>
                </Card.CardContent>
            </Card>
        </Form>
    );
});

export default SearchBar;
