import React, { useState } from 'react';
import { Button } from '../../../components';
import { BlankFormMenu } from './index';

const BlankFormBox = (props) => {
    const { source } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="secondary" onClick={handleOpenMenu}>
                列印空白表單
            </Button>
            <BlankFormMenu
                anchorEl={anchorEl}
                source={source}
                open={open}
                handleClose={handleCloseMenu}
            />
        </React.Fragment>
    );
};

export default BlankFormBox;
