import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty, groupBy } from 'lodash';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Card, Typography, Stack, Button, Box } from '@common/components/';
import { CounterBoxByKpi, SearchBar } from '@icoach/dataAnalysis/components/';
import { MembershipKpiGoalDialog, ServiceKpiGoalDialog, GuestProductionKpiGoalDialog, SalesKpiGoalDialog, HcKpiGoalDialog } from '@icoach/dataAnalysis/dialog/';
import {
    DATA_ANALYSIS_GUEST_PRODUCTION_URL_ROUTER,
    DATA_ANALYSIS_MEMBERSHIP_URL_ROUTER,
    DATA_ANALYSIS_SALES_URL_ROUTER,
    DATA_ANALYSIS_SERVICE_URL_ROUTER,
} from '@icoach/router/routerPath';
import useReportApi from '@apis/useReportApi';
import { floatToPercentage, percentageToFloat } from '@util/utils';
import useToggleDialog from '@util/hook/useToggleDialog';
import { dialogTypeMap } from './staticData';

export const kpiData = {
    membership: [
        {
            title: '有效會員數 TAM',
            tip: '用來計算本月每週有效會員數',
            key: 'totalActiveMembership',
        },
        {
            title: '在籍會員數 TM',
            tip: '用來計算本月每週在籍會員數',
            key: 'totalMembership',
        },
        {
            title: '請假率',
            tip: '請假會員數 / TM',
            goal: percentageToFloat(3),
            goalTitle: '目標 ≤',
            key: 'leaveRate',
            condition: '<=',
        },
        {
            title: '退會率',
            tip: '退會數 / TM',
            goal: percentageToFloat(2),
            goalTitle: '目標 ≤',
            key: 'dropRate',
            condition: '<=',
        },
    ],
    service: [
        {
            title: '總WO次數',
            tip: '當月累計WO總次數',
            goal: 10,
            goalTitle: '目標 >',
            goalAppendText: ' x TAM',
            key: 'totalWo',
            condition: '>',
        },
        {
            title: '每人每週WO數',
            tip: '每週WO總數 / 每週TAM',
            goal: 2.5,
            goalTitle: '目標 >',
            key: 'everyOneWeeklyWo',
            condition: '>',
        },
        {
            title: '量身率',
            tip: '量身數 / TAM',
            goal: percentageToFloat(70),
            goalTitle: '目標 ≥',
            goalAppendText: ' TAM',
            key: 'measureRate',
            condition: '>=',
        },
        {
            title: 'A+B會員比率',
            tip: '',
            goal: percentageToFloat(65),
            goalTitle: '目標 ≥',
            key: 'catAWithB',
            condition: '>=',
        },
    ],
    gp: [
        {
            title: 'APO數',
            tip: '',
            key: 'apoAmount',
        },
        {
            title: 'FA數',
            tip: '',
            key: 'faAmount',
        },
        {
            title: '入會數',
            tip: '入會總數',
            key: 'totalSales',
        },
        {
            title: '名單數',
            tip: '',
            key: 'listAmount',
        },
        {
            title: 'APO率',
            tip: '所有來電或來店APO / 所有來電或來店詢問',
            key: 'apoRate',
        },
        {
            title: '來店率',
            tip: '來店FA / APO總數',
            key: 'showUpRate',
        },
        {
            title: '結案率',
            tip: '入會數 / FA數',
            key: 'salesRate',
        },
    ],
    sales: [
        {
            title: '商品營收',
            tip: '',
            key: 'productSale',
        },
        {
            title: '循環訂單滲透率',
            tip: '滲透率 = (循環盒數/TAM)',
            key: 'totalCycleOrderPenetrationRate',
        },
        {
            title: '循環訂單滲透率',
            tip: '當月退訂盒數 / (前個月循環出貨盒數)',
            key: 'totalCycleUnsubscribeRate',
        },
    ],
};

const handleRateValue = (value = 0) => {
    let val = floatToPercentage(value);
    return `${val}%`;
};

// 分級會員比率
const MemberRankCard = (props) => {
    const { className, data = {} } = props;
    const { catA, catB, catC, catD, catE } = data;

    return (
        <Card className={clsx('member-rank-card', className)}>
            <Card.CardContent>
                <Typography className={'member-rank-card-title'}>分級會員比率</Typography>
                <Grid spacing={3} columns={5} container>
                    <Grid xs={1} item>
                        <Box className={'member-rank-item'}>
                            <Typography className="member-rank-item-title tip">A會員</Typography>
                            <Typography variant="h6" className="member-rank-item-text">
                                {handleRateValue(catA?.actualData ?? 0)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={1} item>
                        <Box className={'member-rank-item'}>
                            <Typography className="member-rank-item-title tip">B會員</Typography>
                            <Typography variant="h6" className="member-rank-item-text">
                                {handleRateValue(catB?.actualData ?? 0)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={1} item>
                        <Box className={'member-rank-item'}>
                            <Typography className="member-rank-item-title tip">C會員</Typography>
                            <Typography variant="h6" className="member-rank-item-text">
                                {handleRateValue(catC?.actualData ?? 0)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={1} item>
                        <Box className={'member-rank-item'}>
                            <Typography className="member-rank-item-title tip">D會員</Typography>
                            <Typography variant="h6" className="member-rank-item-text">
                                {handleRateValue(catD?.actualData ?? 0)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={1} item>
                        <Box className={'member-rank-item'}>
                            <Typography className="member-rank-item-title tip">E會員</Typography>
                            <Typography variant="h6" className="member-rank-item-text">
                                {handleRateValue(catE?.actualData ?? 0)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
};
// 會籍指標
const MembershipSummaryContent = (props) => {
    const { className, data = {}, handleOpenDialog } = props;
    // const { renewed, notYetRenew } = data;

    const handleClick = () => {
        handleOpenDialog(dialogTypeMap.membership);
    };

    const makeKpiItem = useCallback(() => {
        return kpiData.membership.map((item) => {
            return (
                <Grid key={item.key} xs={3} item>
                    <CounterBoxByKpi
                        title={item.title}
                        tip={item.tip}
                        goal={item.goal}
                        goalTitle={item.goalTitle}
                        goalAppendText={item.goalAppendText}
                        goalPrependText={item.goalPrependText}
                        condition={item.condition}
                        data={data[item.key]}
                        onClick={handleClick}
                    />
                </Grid>
            );
        });
        // eslint-disable-next-line
    }, [data]);

    return (
        <Card className={className}>
            <Card.CardContent className={'px-4 pt-3 pb-4'}>
                <Stack justifyContent={'space-between'}>
                    <Typography className={'font-weight-bold mb-2'} variant={'h5'}>
                        會籍
                    </Typography>
                    <Box className={'btn-group'}>
                        <Button variant={'outlined'} onClick={() => handleOpenDialog(dialogTypeMap.membership)}>
                            修改目標
                        </Button>
                        <Button variant={'contained'} href={DATA_ANALYSIS_MEMBERSHIP_URL_ROUTER}>
                            前往查看
                        </Button>
                    </Box>
                </Stack>
                <Grid spacing={3} container>
                    {makeKpiItem()}
                </Grid>
            </Card.CardContent>
        </Card>
    );
};
// 服務指標
const ServiceSummaryContent = (props) => {
    const { className, data = {}, handleOpenDialog } = props;
    const handleClick = () => {
        handleOpenDialog(dialogTypeMap.service);
    };

    const makeKpiItem = useCallback(() => {
        return kpiData.service.map((item) => {
            return (
                <Grid key={item.key} xs={3} item>
                    <CounterBoxByKpi
                        title={item.title}
                        tip={item.tip}
                        goal={item.goal}
                        goalTitle={item.goalTitle}
                        goalAppendText={item.goalAppendText}
                        goalPrependText={item.goalPrependText}
                        condition={item.condition}
                        data={data[item.key]}
                        onClick={handleClick}
                    />
                </Grid>
            );
        });
        // eslint-disable-next-line
    }, [data]);

    return (
        <Card className={className}>
            <Card.CardContent className={'px-4 pt-3 pb-4'}>
                <Stack justifyContent={'space-between'}>
                    <Typography className={'font-weight-bold mb-2'} variant={'h5'}>
                        服務
                    </Typography>
                    <div className={'btn-group'}>
                        <Button variant={'outlined'} onClick={() => handleOpenDialog(dialogTypeMap.service)}>
                            修改目標
                        </Button>
                        <Button variant={'contained'} href={DATA_ANALYSIS_SERVICE_URL_ROUTER}>
                            前往查看
                        </Button>
                    </div>
                </Stack>
                <Grid spacing={3} container>
                    {makeKpiItem()}
                    <Grid xs={12} item>
                        <MemberRankCard data={data} />
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
};
// 顧客製造指標
const GuestProductionSummaryContent = (props) => {
    const { className, data = {}, handleOpenDialog } = props;

    const handleClick = () => {
        handleOpenDialog(dialogTypeMap.gp);
    };

    const makeKpiItem = useCallback(() => {
        return kpiData.gp.map((item) => {
            return (
                <Grid key={item.key} xs={3} item>
                    <CounterBoxByKpi
                        title={item.title}
                        tip={item.tip}
                        goal={item.goal}
                        goalTitle={item.goalTitle}
                        goalAppendText={item.goalAppendText}
                        goalPrependText={item.goalPrependText}
                        condition={item.condition}
                        data={data[item.key]}
                        onClick={handleClick}
                    />
                </Grid>
            );
        });
        // eslint-disable-next-line
    }, [data]);

    return (
        <Card className={className}>
            <Card.CardContent className={'px-4 pt-3 pb-4'}>
                <Stack justifyContent={'space-between'}>
                    <Typography className={'font-weight-bold mb-2'} variant={'h5'}>
                        顧客製造
                    </Typography>
                    <div className={'btn-group'}>
                        <Button variant={'outlined'} onClick={() => handleOpenDialog(dialogTypeMap.gp)}>
                            修改目標
                        </Button>
                        <Button variant={'contained'} href={DATA_ANALYSIS_GUEST_PRODUCTION_URL_ROUTER}>
                            前往查看
                        </Button>
                    </div>
                </Stack>
                <Grid spacing={3} container>
                    {makeKpiItem()}
                </Grid>
            </Card.CardContent>
        </Card>
    );
};
// 非會籍指標
const SalesSummaryContent = (props) => {
    const { className, data = {}, handleOpenDialog } = props;
    const { healthcare } = data;

    const handleClickKpi = () => {
        handleOpenDialog(dialogTypeMap.sales);
    };

    const handleClickHcKpi = () => {
        handleOpenDialog(dialogTypeMap.hc);
    };

    const chunkHealthcareData = useCallback(() => groupBy(healthcare, (item) => item.saleCategoryID), [healthcare]);
    const healthcareData = chunkHealthcareData();

    const makeKpiItem = useCallback(() => {
        return kpiData.sales.map((item) => {
            return (
                <Grid key={item.key} xs={12} item>
                    <CounterBoxByKpi
                        title={item.title}
                        tip={item.tip}
                        goal={item.goal}
                        goalTitle={item.goalTitle}
                        goalAppendText={item.goalAppendText}
                        goalPrependText={item.goalPrependText}
                        condition={item.condition}
                        data={data[item.key]}
                        onClick={handleClickKpi}
                    />
                </Grid>
            );
        });
        // eslint-disable-next-line
    }, [data]);
    const makeHcKpiItem = useCallback(() => {
        return Object.values(healthcareData).map((group, i) => {
            return (
                <Grid key={i} xs={3} item>
                    <Grid spacing={3} container>
                        {group.map((item) => {
                            return (
                                <Grid key={item.dataType} xs={12} item>
                                    <CounterBoxByKpi title={item.itemName} data={item.detail} onClick={handleClickHcKpi} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            );
        });
        // eslint-disable-next-line
    }, [healthcare]);

    return (
        <Card className={className}>
            <Card.CardContent className={'px-4 pt-3 pb-4'}>
                <Stack justifyContent={'space-between'}>
                    <Typography className={'font-weight-bold mb-2'} variant={'h5'}>
                        非會籍
                    </Typography>
                    <Box className={'btn-group'}>
                        <Button variant={'outlined'} onClick={() => handleOpenDialog(dialogTypeMap.sales)}>
                            修改目標
                        </Button>
                        {!isEmpty(healthcareData) && (
                            <Button variant={'outlined'} onClick={() => handleOpenDialog(dialogTypeMap.hc)}>
                                修改HC目標
                            </Button>
                        )}
                        <Button variant={'contained'} href={DATA_ANALYSIS_SALES_URL_ROUTER}>
                            前往查看
                        </Button>
                    </Box>
                </Stack>
                <Box className="sales-wrap">
                    <Grid className={'grid-nowrap'} spacing={3} container>
                        <Grid xs={3} item>
                            <Grid spacing={3} container>
                                {makeKpiItem()}
                            </Grid>
                        </Grid>
                        {!isEmpty(healthcareData) && makeHcKpiItem()}
                    </Grid>
                </Box>
            </Card.CardContent>
        </Card>
    );
};

const DataAnalysisContentKpi = (props) => {
    const { getReportKpiApi } = useReportApi();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const [data, setData] = useState({});
    const searchRef = useRef();

    const doReportKpiApi = async (params) => {
        let res = await getReportKpiApi(params);
        if (res) {
            setData(res);
        }
    };

    const getParams = () => {
        let result = {};
        if (searchRef.current && searchRef.current.getResult) {
            result = Object.assign(result, searchRef.current.getResult());
        }

        return result;
    };

    const handleSubmit = () => {
        let isError = searchRef.current && searchRef.current.isError();
        if (!isError) {
            let params = getParams();
            doReportKpiApi(params);
        }
    };

    useEffect(
        () => {
            handleSubmit();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <SearchBar ref={searchRef} className={'mb-4'} onSubmit={handleSubmit} defaultDate={props.defaultDate} />
            <MembershipSummaryContent className={'mb-4'} data={data} handleOpenDialog={handleOpenDialog} />
            <ServiceSummaryContent className={'mb-4'} data={data} handleOpenDialog={handleOpenDialog} />
            <GuestProductionSummaryContent className={'mb-4'} data={data} handleOpenDialog={handleOpenDialog} />
            <SalesSummaryContent className={'mb-4'} data={data} handleOpenDialog={handleOpenDialog} />
            <MembershipKpiGoalDialog
                open={openTarget === dialogTypeMap.membership}
                data={data}
                getDate={getParams}
                onClose={handleCloseDialog}
                refresh={handleSubmit}
            />
            <ServiceKpiGoalDialog
                open={openTarget === dialogTypeMap.service}
                data={data}
                getDate={getParams}
                onClose={handleCloseDialog}
                refresh={handleSubmit}
            />
            <GuestProductionKpiGoalDialog
                open={openTarget === dialogTypeMap.gp}
                data={data}
                getDate={getParams}
                onClose={handleCloseDialog}
                refresh={handleSubmit}
            />
            <SalesKpiGoalDialog open={openTarget === dialogTypeMap.sales} data={data} getDate={getParams} onClose={handleCloseDialog} refresh={handleSubmit} />
            <HcKpiGoalDialog open={openTarget === dialogTypeMap.hc} data={data} getDate={getParams} onClose={handleCloseDialog} refresh={handleSubmit} />
        </React.Fragment>
    );
};

export default DataAnalysisContentKpi;
