import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { isEmpty } from 'lodash';
import { Table, TableBody, TableRow, TableCell, SelectField, MenuItem, TableHead, IconButton, Button, Stack, Typography } from '@common/components/';
import { ErrorCircle as ErrorCircleIcon } from '@common/SvgIcon/';
import { formatCurrencyFn } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';
import clsx from 'clsx';

const makeCartItem = (productData = {}) => {
    const { id, productID, variantID, price, salePrice, ...other } = productData;
    const _price = price || salePrice;
    return {
        ...other,
        productID,
        variantID,
        salePrice: _price,
        id: id || uuid(),
        value: `${productID}-${variantID}`,
        quantity: 1,
        total: _price * 1,
    };
};

const CountController = (props) => {
    const { qty, setQty } = props;
    const setMessageDialog = useMessageDialog();

    const handlePlus = () => {
        let _qty = qty;
        _qty += 1;
        setQty(_qty);
    };

    const handleMinus = () => {
        let _qty = qty;
        _qty -= 1;
        if (_qty <= 0) {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '購買數量不可以小於1。',
            });
        } else {
            setQty(_qty);
        }
    };

    return (
        <Stack className={'count-controller'} justifyContent={'center'} alignItems={'center'}>
            <IconButton className={'minus'} onClick={handleMinus}>
                <span className={'icon'}></span>
            </IconButton>
            <div className={'count'}>{qty}</div>
            <IconButton className={'plus'} onClick={handlePlus}>
                <span className={'icon'}></span>
            </IconButton>
        </Stack>
    );
};

const CartItem = React.forwardRef((props, ref) => {
    const { data, menu, readOnly, deleteCartItem, updateCartItem } = props;
    const { id, value, salePrice, quantity, total, productName } = data;
    const productRef = useRef();

    const handleDeleteProduct = (e) => {
        deleteCartItem(id);
    };

    const getProductData = (value) => {
        let productData = {};
        if (value) {
            const [productID, variantID] = value.split('-');
            productData = menu.find((item) => item.productID.toString() === productID && item.variantID.toString() === variantID);
        }
        return productData;
    };

    const setProductData = (productData) => {
        let newData = makeCartItem(productData);
        updateCartItem(newData);
    };

    const handleChangeProduct = (event, node, value) => {
        let productData = {
            ...data,
            ...getProductData(value),
        };
        setProductData(productData);
    };

    const setQty = (qty) => {
        let newData = {
            id,
            quantity: qty,
            total: salePrice * qty,
        };

        updateCartItem(newData);
    };

    return (
        <TableRow>
            <TableCell>
                {readOnly ? (
                    <Typography className={'mb-0'}>{productName}</Typography>
                ) : (
                    <SelectField key={value} ref={productRef} defaultValue={value} onChange={handleChangeProduct} fullWidth required>
                        {Array.isArray(menu) &&
                            menu.map((item) => {
                                let disabled = item.isLimited && item.stock === 0;
                                let value = `${item.productID}-${item.variantID}`;
                                return (
                                    <MenuItem key={value} value={value} disabled={disabled}>
                                        {item.productName}
                                    </MenuItem>
                                );
                            })}
                    </SelectField>
                )}
            </TableCell>
            <TableCell className={'text-right'} style={{ width: '125px' }}>
                {formatCurrencyFn(salePrice)}
            </TableCell>
            <TableCell className={'text-center'} style={{ width: '70px' }}>
                X
            </TableCell>
            <TableCell className={'text-center'}>{readOnly ? quantity : <CountController qty={quantity} setQty={setQty} />}</TableCell>
            <TableCell className={'text-right'} style={{ width: '125px' }}>
                {formatCurrencyFn(total)}
            </TableCell>
            <TableCell style={{ width: '70px' }}>
                {!readOnly && (
                    <IconButton onClick={handleDeleteProduct}>
                        <ErrorCircleIcon />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    );
});

const CartHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>商品</TableCell>
                <TableCell className={'text-right'}>單價</TableCell>
                <TableCell className={'text-center'}></TableCell>
                <TableCell className={'text-center'}>盒數</TableCell>
                <TableCell className={'text-right'}>總計</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    );
};

const CartTable = React.forwardRef((props, ref) => {
    const { className, data = [], resource = [], readOnly = false } = props;
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);

    const getProductMenu = (id) => {
        if (readOnly) return [];
        let selected = cartItems
            .filter((item, i) => {
                if (id) {
                    return item.id !== id && item;
                }
                return item;
            })
            .map((item) => {
                let { productID, variantID } = item;
                if (productID && productID) return `${productID}-${variantID}`;
                return null;
            });

        // 過濾不能賣的
        let filterMenu = resource.filter((item) => {
            return !(item.isLimited && item.stock === 0);
        });

        return filterMenu.filter(({ productID, variantID }) => {
            let key = `${productID}-${variantID}`;
            return selected.indexOf(key) === -1;
        });
    };

    const addCartItem = () => {
        let currentMenu = getProductMenu();
        let max = resource.length;
        let newItems = cartItems;

        if (newItems.length < max) {
            newItems = [...cartItems, makeCartItem(currentMenu[0])];
            setCartItems(newItems);
        }
    };

    const updateCartItem = (data) => {
        let newItems = cartItems.map((item) => {
            let _item = item;
            if (item.id === data.id) {
                _item = {
                    ..._item,
                    ...data,
                };
            }
            return _item;
        });
        setCartItems(newItems);
    };

    const deleteCartItem = (id) => {
        let newItems = cartItems.filter((item) => id !== item.id);
        setCartItems(newItems);
    };

    const calculateTotal = () => {
        let total = cartItems.reduce((total, current) => {
            return total + current.total;
        }, 0);
        setTotal(total);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return _.cloneDeep(cartItems).map((item) => {
                    let data = item;
                    delete data.total;
                    delete data.id;
                    return data;
                });
            },
        }),
        // eslint-disable-next-line
        [cartItems]
    );

    useEffect(
        () => {
            calculateTotal();
        },
        // eslint-disable-next-line
        [cartItems]
    );

    useEffect(
        () => {
            if (!isEmpty(data)) {
                setCartItems(() => {
                    return data.map((item) => {
                        const { productID, variantID, salePrice, price, quantity } = item;
                        const _price = price || salePrice;
                        return {
                            id: uuid(),
                            ...item,
                            value: `${productID}-${variantID}`,
                            total: _price * quantity,
                        };
                    });
                });
            }
        },
        // eslint-disable-next-line
        [data]
    );

    return (
        <Table className={clsx('cycle-purchase-cart', className)}>
            <CartHead />
            <TableBody>
                {Array.isArray(cartItems) &&
                    cartItems.map((item, i) => {
                        return (
                            <CartItem
                                key={item.id}
                                data={item}
                                menu={getProductMenu(item.id)}
                                readOnly={readOnly}
                                deleteCartItem={deleteCartItem}
                                updateCartItem={updateCartItem}
                            />
                        );
                    })}
                <TableRow>
                    <TableCell colSpan={3}>
                        {!readOnly && (
                            <Button variant={'outlined'} onClick={addCartItem}>
                                新增商品
                            </Button>
                        )}
                    </TableCell>
                    <TableCell className={'text-right'} colSpan={3}>
                        <Typography className={'text-primary'} variant={'h5'}>
                            <strong>合計：{formatCurrencyFn(total, { symbol: '$' })}</strong>
                        </Typography>
                        <Typography className={'mb-0'} variant={'body2'}>
                            售價已包含5%加值營業稅及運費(僅配送台灣本島)
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
});

export default CartTable;
