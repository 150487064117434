import React from 'react';
const signalR = require('@microsoft/signalr');
const REACT_APP_SECRET_CODE = (process.env['REACT_APP_SECRET_CODE'] || '').trim();

const GATEWAP = {
    CHECKIN_ADD: 'ReceiveCheckInMember',
    CHECKIN_UPDATE: 'UpdateCheckInMember',
    RECEIVE_INBODY_PROCESS: 'ReceiveInbodyProcessing',
    RECEIVE_INBODY_PDF_PATH: 'ReceiveInBodyPdfPath',
};

const useSignalr = () => {
    const signalRef = React.useRef(null);
    const connectRef = React.useRef(null);

    const build = React.useCallback(
        async () => {
            connectRef.current = signalRef.current.withUrl('/hubs').build();
        },
        // eslint-disable-next-line
        []
    );

    const start = React.useCallback(
        async () => {
            if (REACT_APP_SECRET_CODE.trim() === 'CRA') return;
            connectRef.current &&
                connectRef.current.start().catch((error) => {
                    console.error(error.toString());
                });
        },
        // eslint-disable-next-line
        []
    );

    const stop = React.useCallback(
        async () => {
            if (REACT_APP_SECRET_CODE.trim() === 'CRA') return;
            connectRef.current && connectRef.current.stop();
        },
        // eslint-disable-next-line
        []
    );

    //接收後台訊息
    const on = React.useCallback(
        async (type, fn) => {
            if (REACT_APP_SECRET_CODE.trim() === 'CRA') return;
            if (!GATEWAP[type]) {
                console.error(`No match type: ${type}`);
                return;
            }
            connectRef.current &&
                connectRef.current.on(GATEWAP[type], (args) => {
                    fn && fn(args);
                });
        },
        // eslint-disable-next-line
        []
    );

    const invoke = React.useCallback(
        async () => {
            if (REACT_APP_SECRET_CODE.trim() === 'CRA') return;
        },
        // eslint-disable-next-line
        []
    );

    // 固定送最新時間給 Client

    React.useEffect(
        () => {
            signalRef.current = new signalR.HubConnectionBuilder();
            if (REACT_APP_SECRET_CODE.trim() !== 'CRA') build();
            return () => {
                stop();
            };
        },
        // eslint-disable-next-line
        [stop, build]
    );

    return { start, stop, on, invoke };
};

export default useSignalr;
