import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Box, Card, List, ListItem, ListItemIcon, ListItemText, Tab, Tabs, Typography } from '@common/components/';
import { NavigateNext as NavigateNextIcon } from '@common/SvgIcon/';
import { ReportTableHeader, SearchBar, TableLabel } from '@icoach/dataAnalysis/components/';
import { GuestProductionDailyTable, GuestProductionMonthlyTable } from '@icoach/dataAnalysis/tables/';
import useReportApi from '@apis/useReportApi';
import { tableTargetMap, guestProductionTabsItemType } from './staticData';

const taskMatterData = [
    {
        text: 'Staff電話話術教育訓練',
        isStar: false,
    },
    {
        text: '使用會員手冊及禮物以索取3個人名',
        isStar: false,
    },
    {
        text: '練習發招待卡以及追蹤發卡後續',
        isStar: false,
    },
];

const GuestProductionRemindersContent = () => {
    return (
        <Box className={'bg-light px-4 pt-2 pb-3'}>
            <Typography className={'font-weight-bold'} variant={'h5'}>
                Reminders
            </Typography>
            <Typography className={'font-color-3 mb-0'}>請確認下列項目是否有執行</Typography>
            <List className={'reminders-list'}>
                {!isEmpty(taskMatterData) &&
                    taskMatterData.map(({ text, isStar }) => (
                        <ListItem key={text}>
                            <ListItemIcon>
                                <NavigateNextIcon />
                            </ListItemIcon>
                            <ListItemText primary={<TableLabel title={text} isStar={isStar} />} />
                        </ListItem>
                    ))}
            </List>
        </Box>
    );
};

const GuestProductionDailyContent = (props) => {
    const { data } = props;
    const [tabsIndex, setTabsIndex] = useState(guestProductionTabsItemType.apo);

    const handleTabChange = (_, value) => {
        setTabsIndex(value);
    };
    return (
        <React.Fragment>
            <div className="page-tabs tabs-bottom-line mb-4">
                <Tabs value={tabsIndex} variant="scrollable" scrollButtons="auto" onChange={handleTabChange}>
                    <Tab className={'py-3'} label={'APO'} value={guestProductionTabsItemType.apo} />
                    <Tab className={'py-3'} label={'名單數'} value={guestProductionTabsItemType.list} />
                    <Tab className={'py-3'} label={'詢問數'} value={guestProductionTabsItemType.ask} />
                    <Tab className={'py-3'} label={'FA數'} value={guestProductionTabsItemType.fa} />
                    <Tab className={'py-3'} label={'入會'} value={guestProductionTabsItemType.totalSales} />
                </Tabs>
            </div>
            {!isEmpty(data) && <GuestProductionDailyTable tabsIndex={tabsIndex} rowsData={data[`${tabsIndex}Daily`]} />}
        </React.Fragment>
    );
};

const GuestProductionMonthlyContent = (props) => {
    const { data } = props;
    const [tabsIndex, setTabsIndex] = useState(guestProductionTabsItemType.agp);

    const handleTabChange = (_, value) => {
        setTabsIndex(value);
    };

    return (
        <React.Fragment>
            <div className="page-tabs tabs-bottom-line mb-4">
                <Tabs value={tabsIndex} variant="scrollable" scrollButtons="auto" onChange={handleTabChange}>
                    <Tab className={'py-3'} label={'AGP'} value={guestProductionTabsItemType.agp} />
                    <Tab className={'py-3'} label={'Calls'} value={guestProductionTabsItemType.calls} />
                    <Tab className={'py-3'} label={'APO'} value={guestProductionTabsItemType.apo} />
                    <Tab className={'py-3'} label={'入會'} value={guestProductionTabsItemType.totalSales} />
                </Tabs>
            </div>
            {!isEmpty(data) && (
                <React.Fragment>
                    <GuestProductionMonthlyTable
                        className={'mb-4'}
                        tabsIndex={tabsIndex}
                        rowsData={data[`${tabsIndex}Weekly`]}
                        cumulative={data[`${tabsIndex}Cumulative`]}
                    />
                    <GuestProductionRemindersContent />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const GuestProductionReportTableContent = (props) => {
    const { data = {} } = props;
    const [tableTarget, setTable] = useState(tableTargetMap.monthly);
    const handleToggleTable = (target) => {
        setTable(target);
    };

    return (
        <Card className={'mb-4'}>
            <Card.CardContent className={'px-4 pt-3 pb-4'}>
                <ReportTableHeader tableTarget={tableTarget} onToggleTable={handleToggleTable} />
                {tableTarget === tableTargetMap.daily && <GuestProductionDailyContent data={data} />}
                {tableTarget === tableTargetMap.monthly && <GuestProductionMonthlyContent data={data} />}
            </Card.CardContent>
        </Card>
    );
};

const DataAnalysisContentGuestProduction = (props) => {
    const { getReportGuestProductionApi } = useReportApi();
    const [data, setData] = useState({});
    const searchRef = useRef();

    const doReportGuestProductionApi = async (params) => {
        let res = await getReportGuestProductionApi(params);
        if (res) {
            setData(res);
        }
    };

    const getParams = () => {
        let result = {};
        if (searchRef.current && searchRef.current.getResult) {
            result = Object.assign(result, searchRef.current.getResult());
        }

        return result;
    };

    const handleSubmit = () => {
        let isError = searchRef.current && searchRef.current.isError();
        if (!isError) {
            let params = getParams();
            doReportGuestProductionApi(params);
        }
    };

    useEffect(() => {
        handleSubmit();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <SearchBar ref={searchRef} className={'mb-4'} onSubmit={handleSubmit} defaultDate={props.defaultDate} />
            <GuestProductionReportTableContent data={data} />
        </React.Fragment>
    );
};

export default DataAnalysisContentGuestProduction;
