import React, { useRef } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import _ from 'lodash';
import { TableHead, TableRow, TableBody, TableCell, TableContainer, Table, Pagination, Alert, Card, Stack } from '@common/components/';
import { isEmpty } from '@util/utils';
import useForkRef from '@util/hook/useForkRef';

const checkTypeof = (_value) => {
    if (Array.isArray(_value)) return [];
    if (Object.prototype.toString.call(_value) === '[object Object]') return {};
    if (typeof _value === 'number' && !isNaN(_value)) return _value;
    return '';
};

const viewTableCell = (...props) => {
    const [_value, _key, _fn, _rowData, other] = props;
    if (_.isFunction(_fn)) {
        return _fn(_value, _rowData, {
            cellKey: _key,
            ...other,
        });
    } else if (typeof _value === 'object') {
        console.error(`${_key} is object ,please use formatCell`);
        return '';
    } else {
        return _value;
    }
};

const Tablenization = (props) => {
    const {
        children,
        cardProps = {},
        isPagination = false,
        Pagination: PaginationProps = null,
        onChange: onPageChangeProps,
        tableContainerRef: tableContainerRefProps,
        count = 0,
        page = 1,
        height,
        isCustomized = false,
        isFixed = false,
        cellWidth,
    } = props;
    const handleRef = useRef(null);
    const tableContainerRef = useForkRef(handleRef, tableContainerRefProps);

    const handlePageChange = (e, num) => {
        e.preventDefault();
        onPageChangeProps && onPageChangeProps(e, num);
    };

    const tableProperty = {};
    if (!isCustomized && children[1].props.rowsData.length === 0 && height) tableProperty.height = height;

    return (
        <React.Fragment>
            <Card {...cardProps}>
                <Card.CardContent>
                    <TableContainer ref={tableContainerRef} height={height} isSkipOverflow={cardProps.isOverflowHidden}>
                        <Table
                            {...tableProperty}
                            className={clsx(tableProperty.className, {
                                'table-fixed': isFixed,
                            })}
                        >
                            {Array.isArray(children)
                                ? children.map((child, i) => {
                                      if (!React.isValidElement(child)) return null;
                                      return React.cloneElement(child, {
                                          ...child.props,
                                          key: uuid(),
                                          cellWidth: cellWidth,
                                      });
                                  })
                                : React.cloneElement(children, { cellWidth: cellWidth })}
                        </Table>
                    </TableContainer>
                </Card.CardContent>
            </Card>
            {isPagination &&
                !!count &&
                (PaginationProps ? (
                    <PaginationProps count={count} page={page} onChange={handlePageChange} />
                ) : (
                    <Stack direction="row-reverse" className="mt-4">
                        <Pagination count={count} page={page} color="secondary" shape="rounded" onChange={handlePageChange} />
                    </Stack>
                ))}
        </React.Fragment>
    );
};

// Header Iterator Element
Tablenization.TableRowHeader = ({ headerRow = [], className: headerClassNameProps, isFixed = false, cellWidth, ...props }) => {
    let offset = {
        left: 0,
        right: 0,
    };
    return (
        <TableHead>
            <TableRow
                className={clsx(headerClassNameProps, {
                    'table-head-fixed': isFixed,
                })}
            >
                {headerRow.map((target) => {
                    const {
                        align = 'left',
                        minWidth = 50,
                        headerLabel,
                        width,
                        style: propsStyle = {},
                        isIcon,
                        maxWidth,
                        className,
                        isFixed: isFixedTarget = false,
                        isFixedLeft = false,
                    } = target;
                    const direction = isFixedLeft ? 'left' : 'right';
                    let defaultProperty;
                    let _width = width || cellWidth;
                    let style = {
                        ...propsStyle,
                        minWidth,
                    };

                    if (isFixedTarget) {
                        style = Object.assign(style, {
                            [direction]: `${offset[direction]}rem`,
                        });
                    }
                    if (isIcon) style = Object.assign(style, { minWidth: 0, width: 0 });
                    if (maxWidth) {
                        style = Object.assign({}, style, {
                            maxWidth: `${maxWidth}rem`,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        });
                    }
                    if (_width) {
                        _width = parseFloat(_width);
                        if (isNaN(_width)) {
                            throw new Error('width 只接受數值，請確認');
                        } else {
                            style = Object.assign(style, {
                                width: `${_width}rem`,
                            });
                            if (isFixedTarget) offset[direction] = offset[direction] + _width;
                        }
                    }
                    defaultProperty = {
                        align,
                        style,
                        key: uuid(),
                        className: clsx('ban-select', className, {
                            'table-cell-fixed': isFixedTarget,
                            [`table-cell-fixed-${direction}`]: isFixedTarget,
                        }),
                    };
                    return <TableCell {...defaultProperty}>{_.isFunction(headerLabel) ? headerLabel({ ...props }) : headerLabel}</TableCell>;
                })}
            </TableRow>
        </TableHead>
    );
};

// Body Iterator Element
Tablenization.TableRowBody = ({
    headerRow = [],
    rowsData = [],
    children,
    alertProps = {},
    rowOnClick: rowOnClickProps,
    bodyRowHeight,
    bodyRowClass = {
        key: undefined,
        value: undefined,
        className: '',
        fn: null,
    },
    cellWidth,
    ...props
}) => {
    const alertProperty = {
        variant: 'standard',
        severity: 'info',
        color: 'info',
        ...alertProps,
    };

    const setRowClass = (resource) => {
        const { key, value, className: classNameProps, fn } = bodyRowClass;
        const targetValue = resource[key];
        let classNames = '';
        if (!isEmpty(targetValue) && String(targetValue) === String(value)) classNames = classNameProps;
        else if (typeof fn === 'function') classNames = fn(resource);
        return clsx(classNames);
    };

    const handleRowOnClick = (_rowData) => {
        rowOnClickProps && rowOnClickProps(_rowData);
    };

    return (
        <TableBody>
            {!isEmpty(rowsData) ? (
                <React.Fragment>
                    {rowsData.map((target, sequence) => {
                        const style = {
                            height: bodyRowHeight,
                        };
                        return (
                            <TableRow key={uuid()} className={setRowClass(target)} style={style} tabIndex={-1} onClick={() => handleRowOnClick(target)} hover>
                                <Tablenization.TableRowCell
                                    headerRow={headerRow}
                                    cellData={target}
                                    rowsData={rowsData}
                                    sequence={sequence + 1}
                                    cellWidth={cellWidth}
                                    {...props}
                                />
                            </TableRow>
                        );
                    })}
                    {children}
                </React.Fragment>
            ) : (
                <TableRow tabIndex={-1}>
                    <TableCell colSpan={headerRow.length}>
                        <Alert {...alertProperty}>{alertProps.text || '尚無資料'}</Alert>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    );
};

Tablenization.TableRowCell = ({ headerRow, cellData, rowsData, className: classNameProps, cellWidth, ...props }) => {
    let offset = {
        left: 0,
        right: 0,
    };
    return (
        <React.Fragment>
            {headerRow.map((target) => {
                const {
                    align = 'left',
                    minWidth = 40,
                    maxWidth,
                    width,
                    style: propsStyle = {},
                    cellKey: _key,
                    formatCell: _fn,
                    className,
                    isFixed = false,
                    isFixedLeft = false,
                    ...others
                } = target;
                const direction = isFixedLeft ? 'left' : 'right';
                let _width = width || cellWidth;
                let style = { ...propsStyle, minWidth };

                if (isFixed) {
                    style = Object.assign(style, {
                        [direction]: `${offset[direction]}rem`,
                    });
                }
                if (maxWidth) {
                    style = Object.assign({}, style, {
                        maxWidth: `${maxWidth}rem`,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    });
                }
                if (_width) {
                    _width = parseFloat(_width);
                    if (isNaN(_width)) {
                        throw new Error('width 只接受數值，請確認');
                    } else {
                        style = Object.assign(style, {
                            width: `${_width}rem`,
                        });
                        if (isFixed) offset[direction] = offset[direction] + _width;
                    }
                }

                // 只回基本型別 否則回該label的字串 Todo

                const cellKey = cellData[_key];
                const _value = isEmpty(cellKey) ? checkTypeof(cellKey) : cellKey;
                let property = {
                    style,
                    align,
                    className: clsx(className, classNameProps, {
                        'table-cell-fixed': isFixed,
                        [`table-cell-fixed-${direction}`]: isFixed,
                    }),
                    key: uuid(),
                };

                return (
                    <TableCell {...property}>
                        {viewTableCell(_value, _key, _fn, cellData, {
                            ...props,
                            ...others,
                        })}
                    </TableCell>
                );
            })}
        </React.Fragment>
    );
};

export default Tablenization;
