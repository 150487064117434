import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { NavLink, Route, Switch, useParams, Redirect, useLocation } from 'react-router-dom';
import InBodyInterviewInfo from '@icoach/inbody/interview/InBodyInterviewInfo';
import InBodyInterviewNotes from '@icoach/inbody/interview/InBodyInterviewNotesTable';
import InBodyInterviewSummary from '@icoach/inbody/interview/InBodyInterviewSummary';
import InBodyInterviewMeasure from '@icoach/inbody/interview/InBodyInterviewMeasureTable';
import InBodyContext from '@icoach/inbody/components/InBodyContext';
import { Card, Tabs, Tab, Switch as SwitchField, FormControlLabel, PrivateRoute } from '@common/components/';
import { INBODY_INTERVIEW_SUMMARY_PATH, INBODY_INTERVIEW_MEASURE_PATH, INBODY_INTERVIEW_NOTES_PATH } from '@icoach/router/routerPath';
import useInBodyApi from '@apis/useInBodyApi';

// 切換是否要量身
const SwitchIsMeasureBox = (props) => {
    const { status = false, peopleID, setSourceData } = props;
    const { putInterviewNotMeasureStatusApi } = useInBodyApi();
    const handleIsMeasureONChange = (e, checked) => {
        doIsMeasureApi({ memberID: peopleID, isInterviewNotMeasure: checked });
    };

    const doIsMeasureApi = async (params) => {
        const resp = await putInterviewNotMeasureStatusApi(params);
        if (resp) {
            const { isInterviewNotMeasure } = params;
            setSourceData((pre) => ({
                ...pre,
                isInterviewNotMeasure,
            }));
        }
    };

    return (
        <div className={'is-measure'}>
            <FormControlLabel
                onChange={handleIsMeasureONChange}
                className="mr-3 mb-2"
                labelPlacement="start"
                control={<SwitchField defaultChecked={status} />}
                label="只面談不量身"
            />
        </div>
    );
};

// tabs 元件
const ContentTabs = (props) => {
    const { peopleID } = props;
    const location = useLocation();
    const [index, setIndex] = React.useState(null);

    const handleChangeTabEvent = (e, value) => {
        setIndex(value);
    };

    useEffect(() => {
        if (!index) {
            const strSplit = location.pathname.split('/');
            const pathIndex = strSplit[strSplit.length - 2];
            !/^\d+$/g.test(pathIndex) && setIndex(pathIndex);
        }
        // eslint-disable-next-line
    }, [location]);

    return (
        <React.Fragment>
            <Tabs value={index} onChange={handleChangeTabEvent}>
                <Tab value={'summary'} label={'會員摘要'} component={NavLink} to={INBODY_INTERVIEW_SUMMARY_PATH(peopleID)} />
                <Tab value={'measure'} label={'量身紀錄'} component={NavLink} to={INBODY_INTERVIEW_MEASURE_PATH(peopleID)} />
                <Tab value={'notes'} label={'面談內容'} component={NavLink} to={INBODY_INTERVIEW_NOTES_PATH(peopleID)} />
            </Tabs>
        </React.Fragment>
    );
};

const InBodyInterviewPage = React.forwardRef((props, ref) => {
    const { getInterviewInitDataApi, getInterviewMemberProfileApi } = useInBodyApi();
    const [sourceData, setSourceData] = useState({});
    const [isMounted, setMounted] = useState(false);
    const { id: peopleID } = useParams();
    const dialogRef = useRef(null);
    const refreshRef = useRef(null);

    const initAllDateApi = async () => {
        const resp = await getInterviewInitDataApi(peopleID);
        setSourceData(resp);
        setMounted(true);
    };

    const refreshProfileApi = async () => {
        const resp = await getInterviewMemberProfileApi(peopleID);
        setSourceData((prev) => ({ ...prev, profileData: resp.profileData }));
    };

    const inbodyInterviewValue = {
        dialogRef: dialogRef,
        peopleID: peopleID,
        memberHeight: sourceData.memberHeight,
        isInterviewNotMeasure: sourceData.isInterviewNotMeasure,
        setSourceData: setSourceData,
        setDialogRef: (el) => (dialogRef.current = el),
        options: sourceData.options || {},
        refresh: refreshRef,
    };

    useEffect(() => {
        if (peopleID) initAllDateApi();
        // eslint-disable-next-line
    }, []);

    refreshRef.current = initAllDateApi;

    const { profileData, options, measureData, isInterviewNotMeasure } = sourceData;

    return (
        <InBodyContext.Provider value={inbodyInterviewValue}>
            <div className="container main-container-spacing inbody-interview">
                {isMounted && (
                    <React.Fragment>
                        <Card className={clsx('inbody-summary-box', 'mb-4')}>
                            <Card.CardContent className="px-4 pt-5 header">
                                <InBodyInterviewInfo className="mb-5" sourceData={profileData} status={isInterviewNotMeasure} />
                                <ContentTabs peopleID={peopleID} />
                                <SwitchIsMeasureBox setSourceData={setSourceData} status={isInterviewNotMeasure} peopleID={peopleID} />
                            </Card.CardContent>
                        </Card>
                        <Switch>
                            <PrivateRoute
                                exact
                                path={INBODY_INTERVIEW_SUMMARY_PATH(peopleID)}
                                as={InBodyInterviewSummary}
                                sourceData={profileData}
                                options={options}
                                initAllDateApi={initAllDateApi}
                                refreshProfileApi={refreshProfileApi}
                            />
                            <PrivateRoute
                                exact
                                path={INBODY_INTERVIEW_MEASURE_PATH(peopleID)}
                                as={InBodyInterviewMeasure}
                                sourceData={measureData.filter((target) => target.inbodyScore)}
                            />
                            <PrivateRoute exact path={INBODY_INTERVIEW_NOTES_PATH(peopleID)} as={InBodyInterviewNotes} sourceData={measureData} />
                            <Route path="/">
                                <Redirect to={INBODY_INTERVIEW_SUMMARY_PATH(peopleID)} />
                            </Route>
                        </Switch>
                    </React.Fragment>
                )}
            </div>
        </InBodyContext.Provider>
    );
});

export default InBodyInterviewPage;
