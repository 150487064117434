import React, { useRef } from 'react';
import { Button } from '../../../components/';
import GradientColorHeader from './GradientColorHeader';
import { Dialog, DialogContent, Grid } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';

// 筆觸設定
const signConfig = {
    minWidth: 1.8,
    maxWidth: 2.8,
};

const SignatureDialog = (props) => {
    const {
        onClose: propsOnClose,
        onCapture: propsOnCapture,
        open,
        titleText,
    } = props;
    const signPadRef = useRef(null);
    const handleCloseOnClick = (e) => {
        propsOnClose && propsOnClose(e);
    };

    const handleCleanPannelClick = (e) => {
        if (signPadRef.current) signPadRef.current.clear();
    };

    const handleSignDataURLClick = (e) => {
        if (signPadRef.current) {
            // const dataURL = signPadRef.current.getTrimmedCanvas().toDataURL('image/png');
            const dataURL = signPadRef.current.toDataURL();
            propsOnCapture && propsOnCapture(e, dataURL);
            propsOnClose && propsOnClose(e);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth="md">
            <GradientColorHeader onClose={handleCloseOnClick}>
                {titleText}
            </GradientColorHeader>
            <DialogContent
                style={{ backgroundColor: '#bdbdbd', padding: '16px 12px' }}
            >
                <SignatureCanvas
                    ref={signPadRef}
                    canvasProps={{
                        className: 'signature-dialog-canvas-container',
                    }}
                    {...signConfig}
                />
            </DialogContent>
            <DialogContent style={{ padding: 0 }}>
                <Grid container>
                    <Grid item xs={6}>
                        <Button
                            color="secondary"
                            className="signature-dialog-action-button"
                            size="large"
                            onClick={handleCleanPannelClick}
                        >
                            清除
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className="signature-dialog-action-button"
                            size="large"
                            onClick={handleSignDataURLClick}
                        >
                            確定
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SignatureDialog;
