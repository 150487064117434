import React from 'react';
import _ from 'lodash';
import useInBody from './useInBody';
import { Edit as EditIcon } from '../../../components/icons/SvgIcon/';
import { IconButton, CheckboxField } from '../../../components';

const InBodyAgreedNotes = React.forwardRef((props, ref) => {
    const { sourceData = [], measureID } = props;
    const { dialogRef } = useInBody();

    const handleAgreedNotesOnClick = () => {
        dialogRef.current.openAgreedNotesDialog(sourceData, measureID);
    };
    
    return (
        <div className="cursor-pointer" onClick={handleAgreedNotesOnClick}>
            {_.isEmpty(sourceData) ? (
                <IconButton>
                    <EditIcon />
                </IconButton>
            ) : (
                <CheckboxField
                    origin="start"
                    row={false}
                    readOnly
                >
                    {sourceData[0] && <CheckboxField.Checkbox label={sourceData[0].taskTitle} checked={sourceData[0].isCompleted}/>}
                    {sourceData[1] && <CheckboxField.Checkbox label={sourceData[1].taskTitle} checked={sourceData[1].isCompleted}/>}
                </CheckboxField>
            )}
        </div>
    );
});

export default InBodyAgreedNotes;
