import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { formatCurrencyFn, getErrorModuleStatus, refIsRequiredError } from '@util/utils';
import { Alert, AutocompleteField, Button, Card, CheckboxField, DateField, MenuItem, SelectField, TextField } from '@common/components/';
import { Grid } from '@mui/material';
import { displayDateText, getCurrentEndDay, isValidDate, parseDate } from '@util/moment';
import usePeopleApi, { peopleType } from '@apis/usePeopleApi';
import { isEmpty } from 'lodash';
import { ReadTextField } from '@icoach/components';
import useDocumentApi from '@apis/useDocumentApi';
import { getCheckboxValue, setCheckboxValue } from '@icoach/documents/tools';
import { PaidList, TermContent } from '@icoach/documents/components';
import { keyStatusMap, cabinetPaymentCycleMap } from '../staticData';

// 承租人資訊 (唯讀)
export const RenterSectionByRead = (props) => {
    const { data = {}, titleClassName = 'bg-light-active' } = props;
    const { memberName, memberNo, cellPhone } = data;
    return (
        <React.Fragment>
            <Card.CardHeader title={'承租人資料'} className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'會員姓名'}>{memberName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'會員編號'}>{memberNo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'手機號碼'}>{cellPhone}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};
// 主要承租資訊 (唯讀)
export const RentMainSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const {
        cabinetRentalNo,
        cabinetRentalStatusText,
        cabinetName,
        firstMonthFee,
        monthlyFee,
        paymentCycle,
        paymentCycleText,
        memo,
        startDate,
        endDate,
        keyStatusText,
        rentalMemo,
    } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title={'承租資料'} className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'租約編號'}>{cabinetRentalNo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'租約狀態'}>{cabinetRentalStatusText}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'繳費週期'}>{paymentCycleText}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'置物櫃名稱'}>{cabinetName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'租金'}>
                            {String(paymentCycle) === cabinetPaymentCycleMap.payOff
                                ? formatCurrencyFn(firstMonthFee, { symbol: '$' })
                                : formatCurrencyFn(monthlyFee, { symbol: '$' })}
                        </ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'置物櫃備註'}>{memo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'租借期間'}>{displayDateText(startDate, endDate)}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'鑰匙'}>{keyStatusText}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'備註'}>{rentalMemo}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};
// 教練與建單日期 (唯讀)
export const TopSectionByRead = (props) => {
    const { className, data = {} } = props;
    const { cabinetRentalCreateDate, employeeName, updatedDate } = data;

    return (
        <Card className={className}>
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'建立日期'}>{displayDateText(cabinetRentalCreateDate)}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'更新擔當'}>{employeeName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'更新日期'}>{displayDateText(updatedDate)}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
};
// 退租 (唯讀)
export const QuitSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { endDate, cabinetQuitKeyStatusText, quitReason } = data;
    return (
        <React.Fragment>
            <Card.CardHeader title={'退租資訊'} className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'退租日期'}>{displayDateText(endDate)}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'鑰匙狀況'}>{cabinetQuitKeyStatusText}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'退租原因'}>{quitReason}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};
// 信用卡資訊 唯讀
export const CreditCardInfoSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { payment = {} } = data;
    const { creditCardNo, cardExpiryDate, cardName, cardRelation, creditCardTypeText } = payment;

    return (
        <React.Fragment>
            <Card.CardHeader title="會籍信用卡資訊" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'信用卡號'}>{creditCardNo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'有效期限'}>{cardExpiryDate}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'卡別'}>{creditCardTypeText}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'持卡人姓名'}>{cardName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'卡片關係人'}>{cardRelation}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

// 會籍扣款方式與卡片持卡人關係
const MembershipInfoSection = (props) => {
    const { data = {} } = props;
    const { cardRelation, paymentTypeText } = data;
    const { getDocumentCardAuthPDFDownloadApi } = useDocumentApi();
    return (
        <Grid xs={12} item>
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    <Alert className={'alert-justify-content-start'} severity={'info'} variant={'filled'} color={'info'}>
                        目前只提供信用卡方式進行扣款，我們將於「契約時間」，以您會籍的扣款方式每月繳付置物櫃承租金額。
                        <br />
                        如信用卡非會員本人持有，請列印「信用卡授權書」請卡片持卡人授權，謝謝。
                    </Alert>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'會籍的扣款方式'}>{paymentTypeText}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <ReadTextField label={'信用卡持卡關係'}>{cardRelation}</ReadTextField>
                </Grid>
                <Grid xs={4} item>
                    <Button className={'mt-4'} variant={'outlined'} onClick={getDocumentCardAuthPDFDownloadApi}>
                        信用卡授權書
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

// 教練與建單日期
export const TopSection = React.forwardRef((props, ref) => {
    const { className, resource = {}, isCreate = false } = props;
    const { employeeOption = [] } = resource;
    const dateRef = useRef();
    const employeeRef = useRef();

    const getResult = () => {
        let result = {};
        if (dateRef.current && dateRef.current.getResult)
            result = Object.assign(result, {
                cabinetRentalCreateDate: dateRef.current.getResult(),
            });
        if (employeeRef.current && employeeRef.current.getResult) {
            result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
        }

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(dateRef, employeeRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <Card className={className}>
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    {isCreate && (
                        <Grid xs={4} item>
                            <DateField ref={dateRef} label={'建立日期'} defaultValue={parseDate()} fullWidth />
                        </Grid>
                    )}
                    <Grid xs={4} item>
                        <SelectField ref={employeeRef} label={'擔當'} fullWidth required>
                            {Array.isArray(employeeOption) &&
                                employeeOption
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
});
// 承租人資訊
export const RenterSection = React.forwardRef((props, ref) => {
    const { data = {}, titleClassName = 'bg-light-active', doCheckDebitPersonalApi } = props;
    const { memberID } = data;
    const { people: memberOptions = [] } = usePeopleApi(peopleType.member);
    const [memberData, setMemberData] = useState({});
    const memberRef = useRef();

    const getResult = () => {
        let result = {};
        if (memberRef.current && memberRef.current.getResult) {
            const { id } = memberRef.current.getResult();
            result = Object.assign(result, { memberID: id });
        }

        return result;
    };

    const handleChangeMember = (newValue) => {
        let value = newValue;
        if (isEmpty(value)) value = {};
        setMemberData(value);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(memberRef),
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(() => {
        if (!isEmpty(memberData)) {
            doCheckDebitPersonalApi(memberData.id);
        }
        // eslint-disable-next-line
    }, [memberData]);

    useEffect(() => {
        if (!isEmpty(memberOptions) && memberID) {
            let _memberData = memberOptions.find((item) => item.id === memberID);
            setMemberData(_memberData);
        }
    }, [memberID, memberOptions]);

    return (
        <React.Fragment>
            <Card.CardHeader title={'承租人資料'} className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <AutocompleteField
                            key={memberData?.id}
                            ref={memberRef}
                            label={'會員'}
                            defaultValue={memberData?.id}
                            options={memberOptions}
                            optionKeys={['name', 'mobile', 'no']}
                            primaryKey={'id'}
                            inputProps={{
                                placeholder: '請輸入會員姓名/手機號碼/會員編號，擇一即可',
                            }}
                            onChange={handleChangeMember}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'會員編號'}>{memberData?.no}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
});
// 主要承租資訊
export const RentMainSection = React.forwardRef((props, ref) => {
    const {
        titleClassName = 'bg-light-active',
        data = {},
        membershipData = {},
        cabinetData = {},
        resource = {},
        isCreate = false,
        setCabinetData,
        handlePaymentCycleChange,
        setCabinetFee,
    } = props;
    const { startDate: startDateProps, endDate: endDateProps, keyStatus = keyStatusMap.inClub, rentalMemo, paymentCycle, firstMonthFee } = data;
    const { cabinetID = 0, cabinetFee = 0, memo } = cabinetData;
    const { cabinets = [], cabinetKeyStatusOption = [], cabinetPaymentCycleOption = [] } = resource;
    const [endDate, setEndDate] = useState(endDateProps);
    const [startDate, setStartDate] = useState(startDateProps);
    const cabinetRef = useRef();
    const keyRef = useRef();
    const memoRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const paymentCycleRef = useRef();
    const rentFeeRef = useRef();
    const isEdit = data.cabinetRentalID && String(data.cabinetRentalID) !== '0';
    const isPaymentCyclePayOff = String(paymentCycle) === cabinetPaymentCycleMap.payOff;
    const isPaymentCycleMonthly = String(paymentCycle) === cabinetPaymentCycleMap.monthly;

    const handleChangeCabinet = (val) => {
        let _val = {};
        if (val) {
            _val = val;
        }
        setCabinetData(_val);
    };

    const getDefaultEndDate = (startDate) => {
        return moment(startDate).add(1, 'years').subtract(1, 'seconds');
    };

    const handleStartDateChange = (e, value) => {
        setStartDate(value);
        // setEndDate(getDefaultEndDate(value));
    };

    const handleEndDateChange = (e, value) => {
        setEndDate(value);
    };

    const handleCalculateCycles = (endDate, startDate) => {
        const _startDate = startDate || startDateRef.current.getResult();
        const _endDate = moment(endDate).add(1, 'days'); // 好計算一個週期
        return moment(_endDate).diff(_startDate, 'months');
    };

    const getResult = () => {
        let result = {};
        if (cabinetRef.current && cabinetRef.current.getResult) {
            const { cabinetID, cabinetFee } = cabinetRef.current.getResult();
            Object.assign(result, {
                cabinetID: cabinetID,
                monthlyFee: cabinetFee,
            });
        }
        if (keyRef.current && keyRef.current.getResult) {
            Object.assign(result, { keyStatus: keyRef.current.getResult() });
        }
        if (memoRef.current && memoRef.current.getResult) {
            Object.assign(result, { rentalMemo: memoRef.current.getResult() });
        }
        if (paymentCycleRef.current && paymentCycleRef.current.getResult) {
            Object.assign(result, { paymentCycle: paymentCycleRef.current.getResult() });
        }
        if (startDateRef.current && startDateRef.current.getResult) {
            Object.assign(result, { startDate: parseDate(startDateRef.current.getResult()) });
        }
        if (endDateRef.current && endDateRef.current.getResult) {
            let endDate = null;
            if (String(result.paymentCycle) === cabinetPaymentCycleMap.payOff) {
                endDate = getCurrentEndDay(parseDate(endDateRef.current.getResult()));
            }
            Object.assign(result, { endDate });
        }
        if (rentFeeRef.current && rentFeeRef.current.getResult) {
            Object.assign(result, { firstMonthFee: rentFeeRef.current.getResult() });
        }

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(cabinetRef, keyRef, memoRef, startDateRef, endDateRef, paymentCycleRef),
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (isEdit) return;

            let _cabinetFee = 0;
            let qty = 1;
            if (!isEmpty(cabinetData)) {
                _cabinetFee = cabinetData.cabinetFee;
                let _startDate = parseDate();
                setStartDate(_startDate);
                if (String(paymentCycle) === cabinetPaymentCycleMap.payOff) {
                    let _endDate = getDefaultEndDate(_startDate);
                    setEndDate(_endDate);
                }
            }

            setCabinetFee(_cabinetFee * qty);
        },
        // eslint-disable-next-line
        [cabinetData, paymentCycle],
    );

    useEffect(
        () => {
            if (isEdit) return;

            if (String(paymentCycle) === cabinetPaymentCycleMap.payOff) {
                const startDate = startDateRef.current.getResult();
                setEndDate(getDefaultEndDate(startDate));
            }
        },
        // eslint-disable-next-line
        [paymentCycle],
    );

    useEffect(
        () => {
            if (isEdit) return;

            let qty = 1;

            if (isValidDate(endDate) && isValidDate(startDate)) {
                qty = handleCalculateCycles(endDate, startDate);
            }

            if (String(paymentCycle) === cabinetPaymentCycleMap.payOff && (!isValidDate(endDate) || !isValidDate(startDate))) {
                qty = 0;
            }

            setCabinetFee(cabinetFee * qty);
        },
        // eslint-disable-next-line
        [endDate, startDate],
    );

    useEffect(
        () => {
            if (isValidDate(endDateProps)) {
                setEndDate(endDateProps);
            }
        },
        // eslint-disable-next-line
        [endDateProps],
    );

    useEffect(
        () => {
            let _startDate;
            if (isValidDate(startDateProps)) {
                _startDate = startDateProps;
            } else {
                _startDate = parseDate();
            }
            setStartDate(_startDate);
        },
        // eslint-disable-next-line
        [startDateProps],
    );

    return (
        <React.Fragment>
            <Card.CardHeader title={'承租資料'} className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <AutocompleteField
                            key={cabinetID}
                            ref={cabinetRef}
                            label={'選擇置物櫃'}
                            defaultValue={cabinetID}
                            options={cabinets}
                            optionKeys={['cabinetName', 'cabinetNo']}
                            primaryKey={'cabinetID'}
                            inputProps={{
                                placeholder: '請輸入置物櫃名稱或編號',
                            }}
                            onChange={handleChangeCabinet}
                            fullWidth
                            required
                        />
                    </Grid>
                    {isPaymentCyclePayOff && isEdit ? (
                        <Grid xs={4} item>
                            <TextField key={firstMonthFee} ref={rentFeeRef} label={'租金'} defaultValue={firstMonthFee} fullWidth />
                        </Grid>
                    ) : (
                        <Grid xs={4} item>
                            <ReadTextField label={'月租定價'}>{formatCurrencyFn(cabinetFee)}</ReadTextField>
                        </Grid>
                    )}

                    <Grid xs={4} item>
                        <ReadTextField label={'置物櫃備註'}>{memo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <SelectField
                            key={paymentCycle}
                            ref={paymentCycleRef}
                            label={'繳費週期'}
                            defaultValue={paymentCycle}
                            onChange={handlePaymentCycleChange}
                            fullWidth
                            required
                            disabled={isEdit}
                        >
                            {Array.isArray(cabinetPaymentCycleOption) &&
                                cabinetPaymentCycleOption
                                    .filter((item) => String(item.value) !== '99')
                                    .map(({ text, value }) => {
                                        return (
                                            <MenuItem value={value} key={value}>
                                                {text}
                                            </MenuItem>
                                        );
                                    })}
                        </SelectField>
                    </Grid>
                    <Grid xs={8} item />
                    {isCreate && isPaymentCycleMonthly && <MembershipInfoSection data={membershipData} />}
                    {isCreate && isPaymentCyclePayOff && (
                        <Grid xs={12} item>
                            <Alert className={'alert-justify-content-start'} severity={'info'} variant={'filled'} color={'info'}>
                                iCoach預設一次付清的金額是「置物櫃月租金Ｘ12個月」，如果這不是妳想要的，妳可以隨時調整租借期間與費用，調整之前要記得確認好要承租的置物櫃與支付週期，避免iCoach2重新算一次喔！
                            </Alert>
                        </Grid>
                    )}
                    <Grid xs={4} item>
                        <DateField
                            key={startDate}
                            ref={startDateRef}
                            label={'租借起始日'}
                            defaultValue={startDate}
                            onChange={handleStartDateChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    {isPaymentCyclePayOff && (
                        <Grid xs={4} item>
                            <DateField
                                key={endDate}
                                ref={endDateRef}
                                label={'租借結束日'}
                                defaultValue={endDate}
                                onChange={handleEndDateChange}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}
                    <Grid xs={4} item>
                        <SelectField key={keyStatus} ref={keyRef} label={'鑰匙'} defaultValue={keyStatus} fullWidth required>
                            {Array.isArray(cabinetKeyStatusOption) &&
                                cabinetKeyStatusOption.map(({ text, value }) => (
                                    <MenuItem value={value} key={value}>
                                        {text}
                                    </MenuItem>
                                ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField ref={memoRef} label={'備註'} defaultValue={rentalMemo} minRows={3} multiline fullWidth />
                    </Grid>
                    {!!isPaymentCyclePayOff && !!isEdit && (
                        <Grid xs={12} item>
                            <Alert color="info" severity="info" variant="filled">
                                如果變動租櫃費用要記得到收入總覽處理訂單金額與發票喔。
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
});
// 費用資訊
export const FeeSection = React.forwardRef((props, ref) => {
    const { cabinetData = {}, titleClassName = 'bg-light-active', cabinetFee, paymentCycle, errorModule } = props;
    const { isImmediatelyIssuing = true } = cabinetData;
    const [total, setTotal] = useState(0);
    const paymentRef = useRef();
    const rentCabinetFeeRef = useRef();
    const processingFeeRef = useRef();
    const immediatelyIssuingRef = useRef();

    const getResult = () => {
        let result = {};
        if (paymentRef.current && paymentRef.current.getResult) {
            let { cashPaymentAmount, cardPaymentAmount } = paymentRef.current.getResult();
            Object.assign(result, {
                cashAmount: cashPaymentAmount,
                creditCardAmount: cardPaymentAmount,
            });
        }
        if (rentCabinetFeeRef.current && rentCabinetFeeRef.current.getResult) {
            Object.assign(result, {
                firstMonthFee: rentCabinetFeeRef.current.getResult(),
            });
        }
        if (processingFeeRef.current && processingFeeRef.current.getResult) {
            Object.assign(result, {
                processingFee: processingFeeRef.current.getResult(),
            });
        }
        if (immediatelyIssuingRef.current && immediatelyIssuingRef.current.getResult) {
            Object.assign(result, {
                isImmediatelyIssuing: getCheckboxValue(immediatelyIssuingRef.current.getResult(), {
                    isMultiple: false,
                }),
            });
        }
        return result;
    };

    const calculateFeesTotal = () => {
        let total = 0,
            firstMonthFee = 0,
            processingFee = 0;

        if (rentCabinetFeeRef.current && rentCabinetFeeRef.current.getResult) firstMonthFee = rentCabinetFeeRef.current.getResult();
        if (processingFeeRef.current && processingFeeRef.current.getResult) processingFee = processingFeeRef.current.getResult();

        total += parseInt(firstMonthFee);
        total += parseInt(processingFee);

        setTotal(total);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(paymentRef, rentCabinetFeeRef, processingFeeRef, immediatelyIssuingRef),
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(() => {
        calculateFeesTotal();
    }, [cabinetFee]);

    return (
        <React.Fragment>
            <Card.CardHeader title={'費用明細'} className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={6} item>
                        <PaidList
                            ref={paymentRef}
                            total={total}
                            cashPaymentAmountProps={{ ...getErrorModuleStatus(errorModule, 'cashAmount') }}
                            cardPaymentAmountProps={{ ...getErrorModuleStatus(errorModule, 'creditCardAmount') }}
                        >
                            <PaidList.PaidListItem label={String(paymentCycle) === cabinetPaymentCycleMap.payOff ? '既存租櫃費' : '首月租櫃費'}>
                                <TextField
                                    key={cabinetFee}
                                    ref={rentCabinetFeeRef}
                                    defaultValue={cabinetFee}
                                    onChangeNative={calculateFeesTotal}
                                    maskType={'MONEY'}
                                    required
                                />
                            </PaidList.PaidListItem>
                            <PaidList.PaidListItem label={'工本費'}>
                                <TextField ref={processingFeeRef} defaultValue={0} onChangeNative={calculateFeesTotal} maskType={'MONEY'} required />
                            </PaidList.PaidListItem>
                        </PaidList>
                    </Grid>
                    <Grid xs={12} item>
                        <CheckboxField ref={immediatelyIssuingRef} defaultChecked={setCheckboxValue(isImmediatelyIssuing)} className={'mb-1'}>
                            <CheckboxField.Checkbox label={'是否收款並直接開立發票'} value={true} />
                        </CheckboxField>
                        <Alert className={'alert-justify-content-start'} severity={'info'} variant={'filled'} color={'info'}>
                            若未直接開立，需到 新增收入 確認結帳方可確認收款及開立
                        </Alert>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
});
// 訂購條款
export const TermSection = React.forwardRef((props, ref) => {
    const { className, data } = props;
    const confirmRef = useRef();
    const signImgRef = useRef();

    const getResult = () => {
        let result = {};
        if (signImgRef.current && signImgRef.current.getResult) result = Object.assign(result, { signImg: signImgRef.current.getResult() });

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(confirmRef, signImgRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return <TermContent className={className} title="置物櫃租用注意事項" data={data}></TermContent>;
});
