import React, { useLayoutEffect, useState, useRef } from 'react';
import { Close as CloseIcon, Print as PrintIcon, Download as DownloadIcon } from '@common/SvgIcon/';
import { IconButton, Stack } from '@common/components/';
import usePrintApi from '@apis/usePrintApi';
import useAjax from '@apis/useAjax';
import useMessageDialog from '@util/hook/useMessageDialog';

const HeaderBar = (props) => {
    const { pdfUrl, onClose: onCloseProps = () => {}, fileName } = props;
    const { postPrintApi } = usePrintApi();
    const setMessageDialog = useMessageDialog();
    const Ajax = useAjax();

    const handlePrinter = () => {
        setMessageDialog({
            open: true,
            title: '列印PDF',
            msg: '是否要列印？',
            onConfirm: doPrintApi,
        });
    };

    const handleOndownLoad = () => {
        if (!pdfUrl) {
            alert('尚無pdf路徑可供下載');
            return null;
        }
        downloadExcel();
    };

    const downloadExcel = async () => {
        await Ajax.getPDF(pdfUrl, '', { fileName: fileName });
    };

    const doPrintApi = async () => {
        if (!pdfUrl) {
            alert('尚無pdf路徑可供下載');
            return null;
        }
        await postPrintApi(pdfUrl);
    };

    return (
        <div
            style={{
                alignItems: 'center',
                backgroundColor: '#eeeeee',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                display: 'flex',
                padding: '4px',
            }}
        >
            <Stack className="ml-auto" spacing={2}>
                <IconButton onClick={handleOndownLoad}>
                    <DownloadIcon />
                </IconButton>
                <IconButton onClick={handlePrinter}>
                    <PrintIcon />
                </IconButton>
                <IconButton onClick={onCloseProps}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </div>
    );
};

const HTMLViewPdfComponent = React.forwardRef((props, ref) => {
    const { htmlUrl = '', pdfUrl = '', onClose: onCloseProps, fileName } = props;
    const divFrameRef = useRef(null);
    const [width, setWidth] = useState('100%');

    const obseverWidth = (entries) => {
        const { contentRect } = entries[0];
        setWidth(contentRect.height);
    };

    useLayoutEffect(() => {
        const detectionDiv = new ResizeObserver(obseverWidth);
        detectionDiv.observe(divFrameRef.current);
    }, []);

    return (
        <div
            className="rpv-core-viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: '100%',
            }}
        >
            <div
                style={{
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    padding: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <HeaderBar pdfUrl={pdfUrl} onClose={onCloseProps} fileName={fileName} />
                <div
                    style={{
                        flex: 1,
                        overflow: 'hidden',
                        background: '#fff',
                    }}
                    ref={divFrameRef}
                >
                    <iframe title={fileName || 'undefined'} width={'100%'} height={width} src={htmlUrl}></iframe>
                </div>
            </div>
        </div>
    );
});

export default HTMLViewPdfComponent;
