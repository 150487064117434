import React, { useRef } from 'react';
import clsx from 'clsx';
import InBodyInterviewDialogBox from './InBodyInterviewDialogBox';
import InBodyInterviewMeasureNormalBox, {
    inBodyInterviewNotesItems,
} from './InBodyInterviewMeasureNormalBox';
import InBodyExtendDiv from '../components/InBodyExtendDiv';
import ScrollerArrowButton from '../components/ScrollerArrowButton';

const InBodyInterviewNotesTable = React.forwardRef((props, ref) => {
    const { sourceData } = props;
    const divRef = useRef(null);

    return (
        <div className="inbody_measure">
            <div className="container" ref={divRef}>
                {inBodyInterviewNotesItems.map(
                    ({ value, key, rowClass, styles = {}, formatCell }) => {
                        return (
                            // row
                            <div
                                key={value}
                                className={clsx(
                                    'row',
                                    rowClass,
                                    sourceData.length === 0 && 'full-width'
                                )}
                                style={{ ...styles }}
                            >
                                {/* Column 標題 */}
                                <div className={clsx('item', 'sticky')}>{value}</div>
                                {/* 全部內容 */}
                                <InBodyInterviewMeasureNormalBox
                                    sourceData={sourceData}
                                    itemKey={key}
                                    styles={{
                                        maxWidth: 400,
                                        minWidth: 400,
                                        overflow: 'hidden',
                                        ...styles,
                                    }}
                                    formatCell={formatCell}
                                />
                                <InBodyExtendDiv />
                            </div>
                        );
                    }
                )}
            </div>
            <ScrollerArrowButton arrowLeftPos={150} moveDistance={800} parentRef={divRef} />
            <InBodyInterviewDialogBox />
        </div>
    );
});

export default InBodyInterviewNotesTable;
