import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, Grid, DialogActions } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { SelectField, MenuItem, Button, TextField } from '@common/components/';
import { isEmpty, refIsRequiredError } from '@util/utils';
import useEmployeeApi from '@apis/useEmployeeApi';
import usePointsApi from '@apis/usePointsApi';

const PointsGrantDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, sourceData: memberData = {}, refresh } = props;
    if (isEmpty(memberData) || !openProps) return null;
    const [options, setOptions] = useState([]);
    const { postPointsRewardsIdAccumulateApi } = usePointsApi();
    const pointsNumRef = useRef(null);
    const empoloyeeIdRef = useRef(null);
    const employeeNameRef = useRef(null);
    const reasonRef = useRef(null);
    const { getEmployeesOptionsApi } = useEmployeeApi();

    const getEmployeeName = (_employeeID) => {
        if (isEmpty(_employeeID)) return '';
        else {
            return options.find((item) => String(item.value) === String(_employeeID))?.text ?? '';
        }
    };

    const getParams = () => {
        let params = {
            accumulatedPoints: pointsNumRef.current.getResult(),
            employeeID: empoloyeeIdRef.current?.getResult() ?? 0,
            accumulatedDesc: reasonRef.current.getResult(),
            employeeName: '',
        };
        if (params.employeeID === 0) {
            params.employeeName = employeeNameRef.current.getResult();
        } else {
            params.employeeName = getEmployeeName(params.employeeID);
        }

        return params;
    };

    const onComfirm = () => {
        if (!refIsRequiredError(pointsNumRef, empoloyeeIdRef, employeeNameRef, reasonRef)) {
            doPointsGrantApi(getParams(), memberData.rewardID);
        }
    };

    const doPointsGrantApi = async (params, rewardID) => {
        const resp = await postPointsRewardsIdAccumulateApi(params, rewardID);
        if (resp) {
            refresh();
            onCloseProps && onCloseProps();
        }
    };

    const doInitApi = async () => {
        const resp = await getEmployeesOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    useEffect(
        () => {
            if (isEmpty(options)) doInitApi();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Dialog open={openProps}>
            <GradientColorHeader onClose={onCloseProps}>會員補點</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid xs={12} item>
                        <TextField ref={pointsNumRef} label="補點點數" type={'number'} defaultValue={0} fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        {options.length === 0 ? (
                            <TextField ref={employeeNameRef} label="經手教練" fullWidth required />
                        ) : (
                            <SelectField key={options.length} ref={empoloyeeIdRef} label="經手教練" defaultValue="" displayEmpty fullWidth required>
                                <MenuItem disabled value="">
                                    <em>請選擇</em>
                                </MenuItem>
                                {options.map(({ value, text }) => (
                                    <MenuItem key={value} value={value}>
                                        {text}
                                    </MenuItem>
                                ))}
                            </SelectField>
                        )}
                    </Grid>
                    <Grid xs={12} item>
                        <TextField ref={reasonRef} label="補點原因" rows={3} multiline fullWidth required />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onComfirm}>
                    確認
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PointsGrantDialog;
