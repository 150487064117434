import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import InBodyOverviewFilterSearchBar from '@icoach/inbody/overview/InBodyOverviewFilterSearchBar';
import InBodyOverviewFilterTable from '@icoach/inbody/overview/InBodyOverviewFilterTable';
import InBodyContext from '@icoach/inbody/components/InBodyContext';
import { Typography } from '@common/components/';
import { initBookmark } from '@icoach/members/staticData';

const InbodyOverviewFilterPage = React.forwardRef((props, ref) => {
    const [rowsLabel, setRowsLabel] = useState([]);
    const [sortOption, setSortOption] = useState([]);
    const [sourceData, setSourceData] = useState({});
    const [resource, setResource] = useState({});
    const [bookmark, setBookmark] = useState(initBookmark());
    const refreshRef = useRef(null);
    const reSetCheckedItemsRef = useRef(null);
    const { quickType } = useParams();

    const setContactState = (memberID, params) => {
        setSourceData(({ list, ...others }) => {
            let newData = _.cloneDeep(list);
            newData = newData.map((target) => {
                if (String(target.memberID) === String(memberID)) {
                    return {
                        ...target,
                        contact: params,
                    };
                }
                return target;
            });
            return {
                list: newData,
                ...others,
            };
        });
    };

    const chilrenValue = {
        sourceData,
        setSourceData,
        bookmark,
        rowsLabel,
        setRowsLabel,
        sortOption,
        setSortOption,
        setContactState,
        resource,
        setResource,
        reSetCheckedItemsRef: (fn) => (reSetCheckedItemsRef.current = fn),
        reSetCheckedItems: () => typeof reSetCheckedItemsRef.current === 'function' && reSetCheckedItemsRef.current(),
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
        setBookmarkNum: (num) => setBookmark((prev) => ({ ...prev, pageIndex: num })),
        setBookmarkSortBy: (sortBy) => setBookmark(() => initBookmark(sortBy)),
    };

    const PanelCompoment = React.useMemo(
        () => {
            return <InBodyOverviewFilterSearchBar className={'mb-4'} targetType={quickType} />;
        },
        // eslint-disable-next-line
        [quickType]
    );

    return (
        <InBodyContext.Provider value={chilrenValue}>
            <div className="container main-container-spacing">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        快速名單查詢
                    </Typography>
                </header>
                {PanelCompoment}
                <InBodyOverviewFilterTable />
            </div>
        </InBodyContext.Provider>
    );
});

export default InbodyOverviewFilterPage;
