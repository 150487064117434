import React from 'react';
import _ from 'lodash';
import useInBody from '@icoach/inbody/components/useInBody';
import { Edit as EditIcon } from '@common/SvgIcon/';
import { IconButton } from '@common/components/';

const InBodyXFactor = React.forwardRef((props, ref) => {
    const { sourceData = [], measureID } = props;
    const { dialogRef } = useInBody();

    const handleOnClick = () => {
        dialogRef.current.openXFactoeyDialog(sourceData, measureID);
    };

    return (
        <div onClick={handleOnClick} className="cursor-pointer">
            {_.isEmpty(sourceData) ? (
                <IconButton>
                    <EditIcon />
                </IconButton>
            ) : (
                <ol className="x-factort">
                    {sourceData[0] && <li>{sourceData[0].taskTitle}</li>}
                    {sourceData[1] && <li>{sourceData[1].taskTitle}</li>}
                </ol>
            )}
        </div>
    );
});

export default InBodyXFactor;
