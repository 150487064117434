import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent } from '@mui/material';
import { PdfComponent, HTMLViewPdfComponent } from '@icoach/components/';
// select
import { getPdfDialogData } from '@redux/pdfDialog/selector';

const ShowPdfDialog = React.memo(
    React.forwardRef((props, ref) => {
        const { open: openProps, pdfUrl, htmlUrl, onClose: onCloseProps } = useSelector(getPdfDialogData);
        const [open, setOpen] = React.useState(false);
        // 自己監聽關閉
        const handleOnClose = () => {
            setOpen(false);
            onCloseProps && onCloseProps();
        };

        useEffect(
            () => {
                setOpen(!!openProps);
                if (open && !pdfUrl) {
                    console.error('Url is null or undefined or ``.');
                }
            },
            // eslint-disable-next-line
            [openProps, pdfUrl]
        );

        return (
            <Dialog open={open} PaperProps={{ className: 'dialog-pdf' }} fullScreen>
                {pdfUrl && (
                    <DialogContent>
                        {htmlUrl ? (
                            <HTMLViewPdfComponent key={pdfUrl} htmlUrl={htmlUrl} pdfUrl={pdfUrl} onClose={handleOnClose} />
                        ) : (
                            <PdfComponent key={pdfUrl} pdfUrl={pdfUrl} onClose={handleOnClose} />
                        )}
                    </DialogContent>
                )}
            </Dialog>
        );
    })
);

export default ShowPdfDialog;
