import React, { useState } from 'react';
import clsx from 'clsx';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router';
import { Typography, Box, Divider, Stack } from '@common/components/';
import useNotificationApi from '@apis/useNotificationApi';
import { parseDate } from '@util/moment';
import { serialize } from '@util/utils';
import { ACCOUNT_BOOK_ORDERS_OVERVIEW_URL } from '@icoach/router/AccountBookRouter';
import {
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_PATH,
    DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_PATH,
} from '@icoach/router/routerPath';
import { initBookmark } from '@icoach/notifications/staticData';

const NotificationTable = React.forwardRef((props, ref) => {
    const { sourceData: sourceDataProps, isLast = false, notificationType } = props;
    const { putNotificationReadApi } = useNotificationApi();
    const history = useHistory();
    const { title = '', textContent: content = '', recordTime: datetime = null, isRead: isReadProps, targetPayload, notificationID } = sourceDataProps;
    const [isRead, setRead] = useState(isReadProps);

    const handleRowClick = () => {
        if (String(notificationType) === '1') {
            const { ReferenceID, ReferenceType } = JSON.parse(targetPayload);
            const r = DOCUMENTS_MEMBERSHIPTRANSFERIN_UNENTERED_PEOPLETYPE_REFERENCEID_CONTRACT_PATH(ReferenceType, ReferenceID, 0);
            doPageChangeApi(null, r);
        } else if (String(notificationType) === '2') {
            const { ReferenceID, ReferenceType } = JSON.parse(targetPayload);
            const r = DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_PATH(ReferenceType, ReferenceID);
            doPageChangeApi(null, r);
        } else if (String(notificationType) === '6') {
            doOpenPDFApi();
        } else if (String(notificationType) === '4') {
            const { OrderSourceType, OrderStatus, Name, StartDate, EndDate, ReceiverType, OrderType } = JSON.parse(targetPayload);
            const params = serialize({
                orderSourceType: OrderSourceType || '',
                status: OrderStatus || ',',
                name: Name || '',
                startDate: StartDate || '',
                endDate: EndDate || '',
                receiverType: ReceiverType || '',
                orderType: OrderType || '',
                ...initBookmark(),
            });
            const r = ACCOUNT_BOOK_ORDERS_OVERVIEW_URL;
            doPageChangeApi(params, r);
        }
    };

    const doPageChangeApi = async (params, path) => {
        let resp;
        const _r = path + '?' + params;
        if (!isRead) {
            resp = await putNotificationReadApi(notificationID);
            if (!resp) {
                return;
            }
        }
        history.push(_r);
    };

    const doOpenPDFApi = async () => {
        ReactGA.event({ category: '新版本通知', action: `新版本通知 ${parseDate(datetime)}`, label: '通知' });
        await window.open(targetPayload, `blank`);
        if (!isRead) {
            await putNotificationReadApi(notificationID);
            setRead(true);
        }
    };

    return (
        <React.Fragment>
            <Box className={clsx(isRead && 'bg-white', 'p-2', 'cursor-pointer')} onClick={handleRowClick}>
                <Stack>
                    <Box className={clsx('notification-dot', isRead && 'read')} />
                    <Box className="flex-grow-1">
                        <Typography className="font-weight-bold">{title}</Typography>
                        <Box className="py-1">{content}</Box>
                        <Typography>{parseDate(datetime)}</Typography>
                    </Box>
                </Stack>
            </Box>
            {!isLast && <Divider />}
        </React.Fragment>
    );
});

export default NotificationTable;
