import React from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import _ from 'lodash';
import InbBodtyXFactor from '@icoach/inbody/components/InBodyXFactor';
import InBodyAgreedNotes from '@icoach/inbody/components/InBodyAgreedNotes';
import InBodyInterviewNote from '@icoach/inbody/components/InBodyInterviewNote';
import InBodyDeleteMeasure from '@icoach/inbody/components/InBodyDeleteMeasure';
import InBodyInterviewPdfDialog from '@icoach/inbody/components/InBodyInterviewPdfDialog';
import { parseDate } from '@util/moment';
import { ArrowUp as ArrowUpIcon, ArrowDown as ArrowDownIcon } from '@common/SvgIcon/';
import { accSub } from '@util/math';
import { Typography, SelectField, MenuItem } from '@common/components/';

// 量身紀錄項目 身體總水重 骨骼肌重 基礎代謝 InBody評分 這四項為反指標
export const inBodyInterviewMeasureItems = [
    {
        value: '項目',
        key: 'measureDate',
        uuid: uuid(),
        rowClass: clsx('bg-measure-gray', 'header'),
        formatCell: (value) => <Typography component={'span'}>{parseDate(value)}</Typography>,
        formatCompare: () => '比較',
        formatMutipleCell: (value) => <Typography component={'span'}>{parseDate(value)}</Typography>,
    },
    { value: 'WO頻率', uuid: uuid(), key: 'monthlyWorkoutFrequency' },
    {
        value: 'InBody評分',
        uuid: uuid(),
        key: 'inbodyScore',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '體重 KG',
        uuid: uuid(),
        key: 'weight',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '骨骼肌重 KG',
        uuid: uuid(),
        key: 'smm',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '蛋白質重 KG',
        uuid: uuid(),
        key: 'protein',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted/>,
    },
    {
        value: '體脂肪重 KG',
        uuid: uuid(),
        key: 'bfm',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '體脂肪率 %',
        uuid: uuid(),
        key: 'pbf',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '身體總水重 L',
        uuid: uuid(),
        key: 'tbw',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '頸部',
        uuid: uuid(),
        key: 'neckMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '胸部',
        uuid: uuid(),
        key: 'chestMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '腹部',
        uuid: uuid(),
        key: 'abdomenMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '臀部',
        uuid: uuid(),
        key: 'hipMC',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '右臂/左臂',
        uuid: uuid(),
        key: ['rightArmMC', 'leftArmMC'],
        formatCell: (v, rowData, { key }) => key.map((target) => rowData[target]).join(' / '),
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '右大腿/左大腿',
        uuid: uuid(),
        key: ['rightThighMC', 'leftThighMC'],
        formatCell: (v, rowData, { key }) => key.map((target) => rowData[target]).join(' / '),
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '基礎代謝率',
        uuid: uuid(),
        key: 'bmr',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} isInverted />,
    },
    {
        value: '腰臀圍比',
        uuid: uuid(),
        key: 'whr',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '內臟脂肪',
        uuid: uuid(),
        key: 'vfl',
        formatCompare: (target) => <MeasureCompareNumericBox {...target} />,
    },
    {
        value: '上半身',
        uuid: uuid(),
        key: 'ubbEvalText',
    },
    {
        value: '下半身',
        uuid: uuid(),
        key: 'lbbEvalText',
    },
    {
        value: '上-下半身',
        uuid: uuid(),
        key: 'ulbbEvalText',
    },
    {
        value: 'InBody 套表',
        uuid: uuid(),
        key: 'pathUrl',
        rowClass: 'bg-measure-gray',
        formatCell: (value) => {
            return <InBodyInterviewPdfDialog sourceData={value} />;
        },
    },
    {
        value: 'X-factor',
        uuid: uuid(),
        key: 'xFactorNotes',
        formatCell: (sourceData, { measureID }) => {
            return <InbBodtyXFactor sourceData={sourceData} measureID={measureID} />;
        },
        itemHeight: 60,
    },
    {
        value: '約定事項',
        uuid: uuid(),
        key: 'agreedNotes',
        formatCell: (sourceData, { measureID }) => {
            return <InBodyAgreedNotes sourceData={sourceData} measureID={measureID} />;
        },
    },
    { value: '量身擔當', uuid: uuid(), key: 'employeeName' },
    {
        value: '',
        uuid: uuid(),
        key: 'measureID',
        formatCell: (measureID, rowData) => {
            return <InBodyDeleteMeasure measureID={measureID} sourceData={rowData} />;
        },
    },
];

// 量身面談項目
export const inBodyInterviewNotesItems = [
    {
        value: '項目',
        uuid: uuid(),
        key: 'measureDate',
        rowClass: clsx('bg-measure-gray', 'header'),
        formatCell: (value) => {
            return (
                <Typography component={'div'} className="interview-notes">
                    {parseDate(value)}
                </Typography>
            );
        },
    },
    {
        value: '面談擔當',
        uuid: uuid(),
        key: 'interviewEmployeeName',
        formatCell: (value, { measureID, interviewContent, interviewEmployeeID }) => {
            return <Typography component="div">{`${value || '尚未設定'}`}</Typography>;
        },
    },
    {
        value: '面談內容',
        uuid: uuid(),
        key: 'interviewContent',
        formatCell: (value, rowData) => {
            return <InBodyInterviewNote value={value} sourceData={rowData} measureID={rowData.measureID} />;
        },
    },
    {
        value: '',
        uuid: uuid(),
        key: 'measureID',
        formatCell: (measureID, rowData) => {
            return <InBodyDeleteMeasure measureID={measureID} sourceData={rowData} />;
        },
    },
];

// 處理cell顯示是兩個value值 ex: xx / xx
export const MeasureCompareNumericBox = (props) => {
    const { datumDataValue, compareDataValue, isInverted = false, itemKey } = props;
    const viewValue = accSub(datumDataValue, compareDataValue);
    if (typeof itemKey !== 'string') {
        const comp = itemKey.map((target) => {
            if (target === '/') return target;
            return (
                <MeasureCompareNumericBox key={target} datumDataValue={props.datumData[target]} compareDataValue={props.compareData[target]} itemKey={target} />
            );
        });

        return [comp[0], ' / ', comp[1]];
    }
    const upColor = isInverted ? 'primary' : 'gray';
    const downColor = isInverted ? 'gray' : 'primary';

    return (
        <React.Fragment>
            {viewValue > 0 && <ArrowUpIcon fontSize={'small'} color={upColor} />}
            {viewValue < 0 && <ArrowDownIcon fontSize={'small'} color={downColor} />}
            &nbsp;
            <span className={clsx(viewValue > 0 && `numeric-${upColor}`, viewValue < 0 && `numeric-${downColor}`, viewValue === 0 && 'numeric-black')}>
                {Math.abs(viewValue)}
            </span>
        </React.Fragment>
    );
};

// 量測資訊比較外框元件
export const InBodyInterviewMeasureCompareBox = (props) => {
    const {
        itemKey,
        formatCell,
        formatCompare,
        formatMutipleCell,
        datumData = [],
        compareData = [],
        styles = {},
        selectedItem,
        setSelectedItem,
        measureDateOptions = [],
    } = props;
    const stickyWidth = 146;

    const handleDateOnChange = (e, n, value) => {
        setSelectedItem(value);
    };

    return (
        <React.Fragment>
            <div className={clsx('item', 'text-center', 'sticky')} style={{ left: stickyWidth, width: stickyWidth, ...styles }}>
                {formatCell ? formatCell(datumData[itemKey], datumData, { key: itemKey }) : datumData[itemKey]}
            </div>
            <div className={clsx('item', 'text-center', 'sticky')} style={{ left: stickyWidth * 2, width: stickyWidth, ...styles }}>
                {formatCompare
                    ? formatCompare({
                          datumDataValue: datumData[itemKey],
                          compareDataValue: compareData[itemKey],
                          itemKey: itemKey,
                          datumData: datumData,
                          compareData: compareData,
                      })
                    : ''}
            </div>
            <div className={clsx('item', 'text-center', 'right-divider', 'sticky')} style={{ left: stickyWidth * 3, width: stickyWidth, ...styles }}>
                {itemKey === 'measureDate' && !_.isEmpty(measureDateOptions) ? (
                    <SelectField defaultValue={selectedItem} onChange={handleDateOnChange}>
                        {measureDateOptions.map(({ text, value }) => {
                            const dateString = parseDate(text);
                            return (
                                <MenuItem key={value} value={value}>
                                    {dateString}
                                </MenuItem>
                            );
                        })}
                    </SelectField>
                ) : formatMutipleCell ? (
                    formatMutipleCell(compareData[itemKey], compareData, { key: itemKey })
                ) : formatCell ? (
                    formatCell(compareData[itemKey], compareData, { key: itemKey })
                ) : (
                    compareData[itemKey]
                )}
            </div>
        </React.Fragment>
    );
};

// 顯示所有量測資訊
const InBodyInterviewMeasureNormalBox = (props) => {
    const { formatCell, sourceData = [], itemKey, styles, className } = props;

    return (
        <React.Fragment>
            {sourceData.map((targetData, columnIndex) => {
                const { [itemKey]: cellVale } = targetData;
                return (
                    <div className={clsx('item', 'text-center', className)} key={`${itemKey}_${columnIndex}`} style={{ ...styles }}>
                        {formatCell ? formatCell(cellVale, targetData, { key: itemKey }) : cellVale}
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default InBodyInterviewMeasureNormalBox;
