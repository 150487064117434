import { isEmpty } from 'lodash';
import { floatToPercentage, toFixed } from '../../util/utils';
import { parseDate } from '../../util/moment';

export const tableTargetMap = {
    daily: 'daily',
    monthly: 'monthly',
};

export const dialogTypeMap = {
    membership: 'membership',
    service: 'service',
    gp: 'gp',
    sales: 'sales',
    hc: 'hc',
};

// 顧客製造 sub tabs
export const guestProductionTabsItemType = {
    apo: 'apo',
    ask: 'ask',
    fa: 'fa',
    list: 'list',
    totalSales: 'totalSales',
    agp: 'agp',
    calls: 'calls',
};

// 非會籍 sub tabs
export const salesTabItemTypeMap = {
    hc: 'healthCare',
    goods: 'goods',
};

// 轉置周紀錄
export const transposedWeeklyData = (weeklyData) => {
    let _obj = {};

    if (!isEmpty(weeklyData)) {
        let keys = Object.keys(weeklyData[0]);
        weeklyData.forEach((item, i) => {
            const { week = 0 } = item;
            keys.forEach((key, j) => {
                let value = item[key];
                let weekKey = `W${week}`;
                if (key === 'week') {
                    if (_obj[key] === undefined) {
                        _obj[key] = [];
                    }
                    _obj[key].push({
                        cellKey: `W${item.week}`,
                        headerLabel: `W${item.week}`,
                        align: 'center',
                        minWidth: 82,
                    });
                } else if (Array.isArray(value)) {
                    let arr = value;
                    arr.forEach((dynamicItem) => {
                        const { amount, saleCategoryID, dataType, orderType } = dynamicItem;
                        const id = `hc_${dataType}_${saleCategoryID}_${orderType}`;
                        if (_obj[id] === undefined) {
                            _obj[id] = {};
                        }
                        _obj[id][weekKey] = amount;
                    });
                } else {
                    if (_obj[key] === undefined) {
                        _obj[key] = {};
                    }
                    _obj[key][weekKey] = item[key];
                }
            });
        });
    }

    return _obj;
};

// 轉置教練累計比較資料
export const transposedEmployeeData = (employeeData) => {
    let _obj = { employees: [] };
    if (!isEmpty(employeeData)) {
        let keys = Object.keys(employeeData[0].cumulative);
        employeeData.forEach((item, i) => {
            const { employeeID, employeeName } = item;
            _obj.employees.push({
                cellKey: employeeID,
                headerLabel: employeeName,
                align: 'center',
                minWidth: 128,
            });
            keys.forEach((key, j) => {
                let value = item.cumulative[key];

                if (Array.isArray(value)) {
                    let arr = value;
                    arr.forEach((dynamicItem) => {
                        const { amount, saleCategoryID, dataType, orderType } = dynamicItem;
                        const id = `hc_${dataType}_${saleCategoryID}_${orderType}`;
                        if (_obj[id] === undefined) {
                            _obj[id] = {};
                        }
                        _obj[id][employeeID] = amount;
                    });
                } else {
                    if (_obj[key] === undefined) {
                        _obj[key] = {};
                    }
                    _obj[key][employeeID] = value;
                }
            });
        });
    }
    return _obj;
};

// 取得各屬性的加總
export const getRowsDataTotal = (rowsData) => {
    if (isEmpty(rowsData)) return {};
    let result = {};
    const keys = Object.keys(rowsData[0]);

    rowsData.forEach((item) => {
        keys.forEach((key) => {
            let value = parseFloat(item[key]);

            if (result[key] === undefined) {
                result[key] = 0;
            }

            if (!isNaN(value)) {
                result[key] = result[key] + value;
            }
        });
    });
    return result;
};
// 取得各屬性的做大值
export const getRowsDataMaxValue = (rowsData, targets) => {
    let result = {};
    const keys = Object.keys(rowsData[0]);
    rowsData.forEach((item) => {
        keys.forEach((key) => {
            if (targets.includes(key)) {
                let value = parseFloat(item[key]);
                if (result[key] === undefined) {
                    result[key] = 0;
                }
                if (!isNaN(value) && value >= result[key]) {
                    result[key] = value;
                }
            }
        });
    });
    return result;
};
// 取得今天的值
export const getRowsDataTodayValue = (rowsData, targets) => {
    let result = {};
    const today = parseDate();
    const keys = Object.keys(rowsData[0]);
    rowsData.forEach((item) => {
        let itemData = parseDate(item.date);
        keys.forEach((key) => {
            if (targets.includes(key)) {
                if (itemData === today) {
                    result[key] = item[key];
                    // console.log(itemData, 'itemData');
                    // console.log(today, 'today');
                }
            }
        });
    });
    return result;
};

export const displayKpiDefaultValue = (data = {}, key = 'targetSettingValue', isSymbol = false) => {
    const { isRate = false } = data;
    let value = data[key];
    if (value !== '設定目標') {
        if (isRate) {
            value = floatToPercentage(value);
            if (isSymbol) value = value + '%';
        } else {
            value = toFixed(value);
        }
    }

    return value;
};
