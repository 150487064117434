import React, { useState } from 'react';
import { Button } from '@common/components/';
import MembershipFormAvatarDialog from '@icoach/members/dialog/MembershipFormAvatarDialog';
import CreateOrderDialog from '@icoach/accountBook/dialog/CreateOrderDialog';

const MembershipCreateNewMemberBox = React.memo(
    React.forwardRef((props, ref) => {
        const { memberID } = props;
        if (!/^\d+$/.test(memberID)) return null;
        const [openDialog, setOpenDialog] = useState(null);

        const handleOnClose = () => {
            setOpenDialog(null);
        };

        return (
            <React.Fragment>
                <Button variant="outlined" onClick={() => setOpenDialog('take-pciture')}>
                    新增大頭照
                </Button>
                <Button variant="outlined" onClick={() => setOpenDialog('buy-products')}>
                    購買商品
                </Button>
                <CreateOrderDialog open={openDialog === 'buy-products'} memberID={memberID} onClose={handleOnClose} />
                <MembershipFormAvatarDialog open={openDialog === 'take-pciture'} memberID={memberID} onClose={handleOnClose} />
            </React.Fragment>
        );
    })
);

export default MembershipCreateNewMemberBox;
