import React from 'react';
import { Box, Card } from '@common/components/';
import {
    ClubSectionByRead,
    ConsumerSectionByRead,
    InvoiceSectionByRead,
    PaymentSectionByRead,
    ProductSectionByRead,
    QuitSectionByRead,
    ReceiverSectionByRead,
    SuspensionSectionByRead,
} from '@icoach/documents/components/CyclePurchaseComponents';

const cycleOrderTypeMap = {
    suspension: 3, // 暫停
    quit: 4, // 退訂
};

const CyclePurchaseDetailOrder = (props) => {
    const sourceData = props.sourceData || {};
    const { cycleOrderType } = sourceData;
    return (
        <Box>
            {cycleOrderType === cycleOrderTypeMap.suspension || cycleOrderType === cycleOrderTypeMap.quit ? (
                <Card className={'order-info-card mb-4'}>
                    {cycleOrderType === cycleOrderTypeMap.suspension && (
                        <SuspensionSectionByRead title={'暫停資訊'} titleClassName={'bg-error-light'} data={sourceData} />
                    )}
                    {cycleOrderType === cycleOrderTypeMap.quit && <QuitSectionByRead title={'退訂資訊'} titleClassName={'bg-error-light'} data={sourceData} />}
                </Card>
            ) : null}
            <Card className={'order-info-card mb-4'}>
                <ConsumerSectionByRead data={sourceData} />
                <ProductSectionByRead data={sourceData} />
                <ReceiverSectionByRead data={sourceData} />
                <PaymentSectionByRead data={sourceData} isCreate />
                <InvoiceSectionByRead data={sourceData} />
            </Card>
            <Card className={'order-info-card'}>
                <ClubSectionByRead data={sourceData} />
            </Card>
        </Box>
    );
};

export default CyclePurchaseDetailOrder;
