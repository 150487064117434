import React, { useEffect, useRef, useCallback, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { Button, Stack } from '@common/components/';
import useShowPDF from '@util/hook/useShowPDF';
import useSignalr from '../../../../signalr/useSignalr';
import useInBodyApi from '../../../apis/useInBodyApi';

const ProcessCircleBox = (props) => {
    const { className, refresh, onClose: propsOnClose } = props;
    const { postCancelMeasureApi } = useInBodyApi();
    const [path, setPath] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const showPDF = useShowPDF();
    const barRef = useRef(null);
    const contRef = useRef(null);
    const signalr = useSignalr();

    const handleProcess = useCallback((text) => {
        const barDom = barRef.current;
        const contDom = contRef.current;
        let processNum = parseInt(text, 10);
        const r = barDom.getAttribute('r');
        const c = Math.PI * (r * 2);
        if (processNum < 0) processNum = 0;
        if (processNum > 100) processNum = 100;
        if (processNum === 100) {
            enqueueSnackbar('量身完畢，即將開啟套表畫面', { variant: 'success' });
        }
        const pct = ((100 - processNum) / 100) * c;
        barDom.style['stroke-dashoffset'] = pct;
        contDom.setAttribute('data-pct', processNum);
        // eslint-disable-next-line
    }, []);

    const handleOpenPDF = useCallback((text) => {
        setPath(text);
        showPDF({ open: true, pdfUrl: text });
        signalr.stop();
        refresh && refresh();
        // eslint-disable-next-line
    }, []);

    const handleOpenPDFOnClick = () => {
        // 不需要轉 源頭已經format
        if (path) {
            showPDF({
                open: true,
                pdfUrl: path,
            });
        }
    };

    const handleCloseWindowOnClick = () => {
        propsOnClose();
    };

    const doSignalrConnect = async () => {
        await signalr.start();
        await signalr.on('RECEIVE_INBODY_PROCESS', handleProcess);
        await signalr.on('RECEIVE_INBODY_PDF_PATH', handleOpenPDF);
    };

    useEffect(() => {
        doSignalrConnect();
        return () => {
            signalr.stop();
            postCancelMeasureApi();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className={clsx('process-circle', className)} key={'process-circle'}>
                <div className="cont" ref={contRef} data-pct={0}>
                    <svg className="svg" width="200" height="200" viewport="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle r="90" cx="100" cy="100" fill="transparent" strokeDasharray="565.48" strokeDashoffset="0"></circle>
                        <circle ref={barRef} className="bar" r="90" cx="100" cy="100" fill="transparent" strokeDasharray="565.48" strokeDashoffset=""></circle>
                    </svg>
                </div>
            </div>
            {!!path ? (
                <Stack justifyContent="end" direction="row" spacing={2}>
                    <Button onClick={handleOpenPDFOnClick} variant="outlined">
                        套表
                    </Button>
                    <Button onClick={propsOnClose} variant="contained">
                        關閉
                    </Button>
                </Stack>
            ) : (
                <Stack justifyContent="center" direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleCloseWindowOnClick}>
                        取消量身
                    </Button>
                </Stack>
            )}
        </React.Fragment>
    );
};

export default ProcessCircleBox;
