import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Form, Card, AutocompleteField, Button, MenuItem, SelectField, Typography } from '@common/components/';
import { PointsRedeemTable } from '@icoach/points/';
import { PointsRedeemDialogBox } from '@icoach/points/dialog/';
import { PointsRedeemProvider, usePointsRedeemContext } from '@icoach/points/components/PointsRedeemContext';
import { initBookmark } from '@icoach/points/staticData';
import usePointsApi from '@apis/usePointsApi';
import { isEmpty } from '@util/utils';
import { head } from 'lodash/array';

const SearchBar = React.memo((props) => {
    const [options, setOptions] = useState({});
    const peopleIdRef = useRef(null);
    const activityIdRef = useRef(null);
    const { setSourceData, setActivityIdRef, setRefreshRef, bookmark } = usePointsRedeemContext();
    const { getPointsRewardsOverviewOptionsApi, getPointsRewardsOverviewApi, getRewardsExportApi } = usePointsApi();
    const { peopleOption = [], activityOption = [] } = options;
    const preloadRef = useRef(false);

    const getParams = (pageModel = {}) => {
        let defaultActivityID = (head(activityOption) ?? { value: '' }).value;
        let params = {
            rewardID: peopleIdRef.current.getResult('id') || '',
            activityID: activityIdRef.current.getResult() || defaultActivityID,
            ...initBookmark(),
        };
        if (!isEmpty(pageModel)) {
            Object.assign(params, pageModel);
        }
        return params;
    };

    const handleSearchOnClick = () => {
        if (isEmpty(options)) return;
        getOverviweApi(getParams());
    };

    const handleExcelOnClick = () => {
        const params = getParams();
        getExcelDownloadApi({ activityID: params.activityID });
    };

    const getExcelDownloadApi = async (params) => {
        await getRewardsExportApi(params);
    };

    const getOverviweApi = async (params) => {
        const resp = await getPointsRewardsOverviewApi(params);
        if (resp) {
            const { list, ...pageModel } = resp;
            setSourceData({ list: [...list], ...pageModel });
            setActivityIdRef(params.activityID);
        }
    };

    const initOptions = async () => {
        const resp = await getPointsRewardsOverviewOptionsApi();
        if (resp) {
            setOptions(resp);
            preloadRef.current = true;
        }
    };
    setRefreshRef(handleSearchOnClick);

    useEffect(
        () => {
            if (preloadRef.current) {
                const params = getParams(bookmark);
                getOverviweApi(params);
            }
        },
        // eslint-disable-next-line
        [bookmark.pageIndex]
    );

    useEffect(
        () => {
            handleSearchOnClick();
        },
        // eslint-disable-next-line
        [options]
    );

    useEffect(
        () => {
            if (isEmpty(options)) {
                initOptions();
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Form onSubmit={handleSearchOnClick}>
            <Card>
                <Card.CardContent className="p-4 px-2">
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <SelectField
                                key={activityOption}
                                ref={activityIdRef}
                                label="兌換時間"
                                defaultValue={activityOption[0]?.value ?? ''}
                                direction="row"
                                displayEmpty
                                required
                                fullWidth
                            >
                                <MenuItem value="" disabled>
                                    <em>請選擇</em>
                                </MenuItem>
                                {activityOption.map(({ text, value, disabled }) => (
                                    <MenuItem key={value} value={value} disabled={disabled}>
                                        {text}
                                    </MenuItem>
                                ))}
                            </SelectField>
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteField
                                inputProps={{ placeholder: '輸入姓名/手機號碼/會員編號(擇一)' }}
                                ref={peopleIdRef}
                                options={peopleOption}
                                optionKeys={['name', 'mobile', 'no']}
                                primaryKey={'id'}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button className="ml-2" variant="contained" type="submit">
                                搜尋
                            </Button>
                            <Button className="ml-2" variant="contained" color="success" onClick={handleExcelOnClick}>
                                下載Excel
                            </Button>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Card>
        </Form>
    );
});

// 點數兌換
const PointsRedeemPage = React.forwardRef((props, ref) => {
    const [sourceData, setSourceData] = useState({});
    const dialogRef = useRef(null);
    const activityIdRef = useRef(null);
    const refreshRef = useRef(null);
    const [bookmark, setBookmark] = useState({
        ...initBookmark(),
    });

    const childContext = {
        dialogRef: dialogRef,
        setDialogRef: (el) => (dialogRef.current = el),
        activityId: activityIdRef,
        setActivityIdRef: (actID) => (activityIdRef.current = actID),
        sourceData,
        setSourceData,
        bookmark: bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
        refresh: () => typeof refreshRef.current === 'function' && refreshRef.current(),
        setRefreshRef: (fn) => (refreshRef.current = fn),
    };

    return (
        <PointsRedeemProvider value={childContext}>
            <div className="container main-container-spacing points-redeem-container">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        會員點數總覽
                    </Typography>
                </header>
                <SearchBar />
                <PointsRedeemTable />
                <PointsRedeemDialogBox ref={dialogRef} />
            </div>
        </PointsRedeemProvider>
    );
});

export default PointsRedeemPage;
