import React from 'react';
import clsx from 'clsx';
import Tablenization from '../../components/Tablenization';
import { TableLabel } from '../components';
import { guestProductionTabsItemType, transposedWeeklyData } from '../staticData';
import { isEmpty } from 'lodash';
import { floatToPercentage } from '../../../util/utils';

const getRowsData = (data, cumulative, thData) => {
    let rowsData = [];
    let rowsLabel = [
        {
            cellKey: 'title',
            headerLabel: '項目',
            width: 26,
            formatCell: (title, { tip, isStar }) => {
                return <TableLabel title={title} tip={tip} isStar={isStar} />;
            },
        },
        {
            cellKey: 'goal',
            headerLabel: '目標',
            align: 'center',
            width: 11,
        },
        {
            cellKey: 'cumulative',
            headerLabel: '最新數字',
            align: 'center',
            className: 'total-col',
            minWidth: 82,
        },
    ];

    data = transposedWeeklyData(data);

    if (!isEmpty(data)) {
        rowsData = thData.map(({ key, formatCell, ...other }) => {
            let weeklyData = data[key];
            let rowData = {
                cellKey: '',
                cumulative: cumulative[key],
                ...other,
                ...weeklyData,
            };

            if (formatCell) {
                rowData.cellKey = 'cumulative';
                rowData.cumulative = formatCell(cumulative[key], rowData);
                for (let week in weeklyData) {
                    rowData.cellKey = week;
                    rowData[week] = formatCell(weeklyData[week], rowData);
                }
            }

            return rowData;
        });

        rowsLabel = [...rowsLabel, ...data.week];
    }

    return { rowsLabel, rowsData };
};

// 表格標題與目標資訊
// title: 標題 string
// tip: 說明 string
// isStar: 閃亮之星 boolean 選填
// goal: 目標 string
const agpThData = [
    {
        key: 'boxCount',
        title: '信箱傳單投遞張數',
        tip: '每週發出信D總數',
        goal: '>5000/mnth',
    },
    {
        key: 'flyerCount',
        title: '員工手發傳單的張數',
        tip: '每週發出手D總數',
        goal: '',
    },
    {
        key: 'gpHour',
        title: '店外GP的時數',
        tip: '每週所有教練在外GP總時數',
        goal: '',
    },
    {
        key: 'cpVisit',
        title: 'CP拜訪、檢查Lead Box的家數',
        tip: '每週拜訪的CP家數',
        isStar: false,
        goal: '>10家',
    },
    {
        key: 'gpList',
        title: '名單數-GP名單',
        tip: '每週GP獲得的名單數（全名＋手機）',
        goal: '',
    },
    {
        key: 'brList',
        title: '名單數-BR名單',
        tip: '每週BR獲得的名單數（全名＋手機）',
        goal: '',
    },
    {
        key: 'social',
        title: '名單數-自店社群',
        tip: '每週從Blog or 自店FB / LINE@獲得的名單數（全名＋手機）',
        goal: '',
    },
    {
        key: 'cpList',
        title: '名單數-CP名單',
        tip: '每週從CP商家得到的名單數（全名＋手機）',
        goal: '',
    },
    {
        key: 'callGuestTotal',
        title: '得到電話與人名的總數',
        tip: '主動製造的所有名單總數',
        goal: '',
    },
];
const callsThData = [
    {
        key: 'incomingLead',
        title: '所有來電及來店詢問',
        tip: '每週來自「自店介紹、 他店介紹、 平面媒體、 網路、 招牌、 策略夥伴、 其他、 信箱、 手發傳單、 CP」的總數',
        goal: '',
    },
    {
        key: 'leadGpList',
        title: '詢問數-GP名單',
        tip: '每週所有打通的電話中，來自GP名單的總數',
        goal: '',
    },
    {
        key: 'leadBrList',
        title: '詢問數-BR名單',
        tip: '每週所有打通的電話中，來自BR名單的總數',
        goal: '',
    },
    {
        key: 'leadSocial',
        title: '詢問數-自店社群',
        tip: '每週所有打通的電話中，來自Blog or 自店FB / LINE@名單的總數',
        goal: '',
    },
    {
        key: 'leadExisting',
        title: '詢問數-既有名單',
        tip: '每週所有打通的電話中，來自過往No Show、No Sale名單的總數',
        goal: '',
    },
    {
        key: 'leadCpList',
        title: '詢問數-CP名單',
        tip: '每週所有打通的電話中，來自CP名單的總數',
        goal: '',
    },
];
const apoThData = [
    {
        key: 'totalApo',
        title: 'APO總數',
        tip: '',
        goal: '',
    },
    {
        key: 'apoRate',
        title: '來電或來店 APO率',
        tip: '所有來電或來店APO/所有來電或來店詢問',
        goal: '100%',
        isStar: true,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'outgoingCallsToLeads',
        title: '名單轉換率%',
        tip: 'AGP Outgoing Calls APO/AGP Outgoing Calls',
        goal: '>50%',
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'brWithAgpRate',
        title: '會員介紹(自店) + AGP佔所有預約比率',
        tip: '店舖主動製造顧客的能力',
        goal: '≥60%',
        isStar: true,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'tog',
        title: 'TOG人數(全部做FA人次)',
        tip: '每週「銷售管理表」中FA人數加總',
        goal: '',
    },
    {
        key: 'showUpRate',
        title: '來店率',
        tip: '來店FA/APO總數',
        goal: '>80%',
        isStar: true,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'apoService',
        title: 'SERVICE-自店介紹',
        tip: '每週所有APO中，來源為自店介紹的總數',
        goal: '',
    },
    {
        key: 'apoOtherClub',
        title: 'BRANDED-他店介紹',
        tip: '每週所有APO中，來源為他店介紹的總數',
        goal: '',
    },
    {
        key: 'apoMedia',
        title: 'BRANDED-平面媒體',
        tip: '每週所有APO中，來源為報章雜誌等平面媒體的總數',
        goal: '',
    },
    {
        key: 'apoInternet',
        title: 'BRANDED-網路',
        tip: '每週所有APO中，來源為網路廣告、關鍵字等的總數',
        goal: '',
    },
    {
        key: 'apoSign',
        title: 'BRANDED-招牌',
        tip: '每週所有APO中，來源為店舖招牌的總數',
        goal: '',
    },
    {
        key: 'apoPartner',
        title: 'BRANDED-策略夥伴',
        tip: '每週所有APO中，來源為勵馨基金會、愛買等策略夥伴的總數',
        goal: '',
    },
    {
        key: 'apoOther',
        title: 'BRANDED-其它',
        tip: '每週所有APO中，來源無法歸類在以上者的總數',
        goal: '',
    },
    {
        key: 'apoBox',
        title: 'AGP來電-信箱傳單',
        tip: '每週所有APO中，來源為信箱傳單的總數',
        goal: '',
    },
    {
        key: 'apoFlyer',
        title: 'AGP來電-手發傳單',
        tip: '每週所有APO中，來源為手發傳單的總數',
        goal: '',
    },
    {
        key: 'apoCp',
        title: 'AGP來電-CP',
        tip: '每週所有APO中，來源為CP店家介紹的總數',
        goal: '',
    },
    {
        key: 'apoGpList',
        title: 'AGP去電-GP名單',
        tip: '每週所有APO中，來源為店外GP名單的總數',
        goal: '',
    },
    {
        key: 'apoBrList',
        title: 'AGP去電-BR名單',
        tip: '每週所有APO中，來源為店內BR名單的總數',
        goal: '',
    },
    {
        key: 'apoSocial',
        title: 'AGP去電-自店社群',
        tip: '每週所有APO中，來源為Blog or 自店FB / LINE@名單的總數',
        goal: '',
    },
    {
        key: 'apoExisting',
        title: 'AGP去電-既有名單',
        tip: '每週所有APO中，來源包含過往的No Show、No Sale名單的總數',
        goal: '',
    },
    {
        key: 'apoCpList',
        title: 'AGP去電-CP名單',
        tip: '每週所有APO中，來源為CP名單的總數',
        goal: '',
    },
    {
        key: 'boxGetApo',
        title: 'Analysis-投信箱傳單幾張可獲得一個APO',
        tip: '',
        goal: '',
    },
    {
        key: 'flyerGetApo',
        title: 'Analysis-手發傳單幾張可獲得一個APO',
        tip: '',
        goal: '',
    },
    {
        key: 'gpListGetApo',
        title: 'Analysis-店外GP幾小時可獲得一個APO',
        tip: '',
        goal: '',
    },
];
const totalSalesThData = [
    {
        key: 'newOneYear',
        title: '一年合約',
        tip: '每週入會的合約類型採用1年的總數',
        goal: '',
    },
    {
        key: 'newTwoYear',
        title: '兩年合約',
        tip: '每週入會的合約類型採用2年的總數',
        goal: '',
    },
    {
        key: 'newOther',
        title: '其他',
        tip: '每週入會的合約類型採用其他的總數',
        goal: '',
    },
    {
        key: 'totalSales',
        title: '入會數',
        tip: '每週入會總數',
        goal: '',
    },
    {
        key: 'salesRate',
        title: '結案率',
        tip: '入會數/FA數',
        goal: '>85%',
        isStar: true,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'paymentRate',
        title: '扣款比率',
        tip: '',
        goal: '>90%',
        isStar: true,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
];
const thDataMap = new Map([
    [guestProductionTabsItemType.agp, agpThData],
    [guestProductionTabsItemType.calls, callsThData],
    [guestProductionTabsItemType.apo, apoThData],
    [guestProductionTabsItemType.totalSales, totalSalesThData],
]);

const MembershipMonthlyTable = (props) => {
    const { className, tabsIndex, rowsData: data = [], cumulative } = props;
    const { rowsLabel, rowsData } = getRowsData(data, cumulative, thDataMap.get(tabsIndex));

    return (
        <Tablenization cardProps={{ className: clsx('monthly-table', className) }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel} />
            <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={rowsData} />
        </Tablenization>
    );
};

export default MembershipMonthlyTable;
