import useShowPDF from '@util/hook/useShowPDF';

const GET_DOCUMENT_CARD_AUTH_PDF_PATH_API = `/document/card-auth.pdf`;

const useDocumentApi = () => {
    const showPDF = useShowPDF();
    // 信用卡申請書
    const getDocumentCardAuthPDFDownloadApi = async () => {
        showPDF({
            open: true,
            pdfUrl: GET_DOCUMENT_CARD_AUTH_PDF_PATH_API,
        });
    };

    return {
        getDocumentCardAuthPDFDownloadApi,
    };
};

export default useDocumentApi;
