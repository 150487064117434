import React from 'react';
import useInBody from './useInBody';
import { Button } from '../../../components';

const InBodyInterviewPdfDialog = React.forwardRef((props, ref) => {
    const { sourceData: pdfUrl } = props;
    const { dialogRef } = useInBody();

    const handleOnClick = () => {
        dialogRef.current.openViewPdfDialog(pdfUrl);
    };

    return (
        <React.Fragment>{pdfUrl && <Button onClick={handleOnClick}>開啟</Button>}</React.Fragment>
    );
});

export default InBodyInterviewPdfDialog;
