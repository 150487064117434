import React, { useState, useRef } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import useInBody from '../components/useInBody';
import useInBodyApi from '../../../apis/useInBodyApi';
import { Button, SelectField, MenuItem, Stack, TextField } from '../../../components';
import { refIsRequiredError } from '../../../util/utils';

const InBodyInterviewAddMeasureDialog = React.forwardRef((props, ref) => {
    const { peopleID, options, refresh } = useInBody();
    const { postInterViewNotMeasureDataApi } = useInBodyApi();
    const { employeesOptions } = options;
    const [openDialog, setOpenDialog] = useState(false);
    const employeeIDRef = useRef(null);
    const interviewContentRef = useRef(null);

    const handleOpenDialogOnClock = () => {
        setOpenDialog(true);
    };

    const handleConfirmClick = () => {
        if (!refIsRequiredError(employeeIDRef, interviewContentRef)) {
            postInterviewDataApi(
                {
                    measureID: 0,
                    interviewEmployeeID: employeeIDRef.current.getResult(),
                    interviewContent: interviewContentRef.current.getResult(),
                },
                peopleID
            );
        }
    };

    const postInterviewDataApi = async (params, pID) => {
        const resp = await postInterViewNotMeasureDataApi(params, pID);
        if (resp) {
            setOpenDialog(false);
            refresh && refresh.current && refresh.current();
        }
    };

    return (
        <React.Fragment>
            <Button variant="contained" onClick={handleOpenDialogOnClock}>
                新增面談
            </Button>
            <Dialog open={openDialog} PaperProps={{ className: 'wx-65rem' }} fullWidth>
                <GradientColorHeader onClose={() => setOpenDialog(false)}>
                    新增面談
                </GradientColorHeader>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <SelectField ref={employeeIDRef} label="擔當教練" displayEmpty required>
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {employeesOptions.map(({ text, value, disable }) => {
                                return (
                                    <MenuItem key={value} value={value} disabled={disable}>
                                        {text}
                                    </MenuItem>
                                );
                            })}
                        </SelectField>
                        <TextField
                            ref={interviewContentRef}
                            label="面談內容"
                            rows={10}
                            multiline
                            required
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClick} variant="contained">
                        送出
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
});

export default InBodyInterviewAddMeasureDialog;
