import * as React from 'react';
import { Box, Checkbox, Stack, Typography } from '@common/components/';
import { displayDateText } from '@util/moment';
import { memberSheet, moreSheet, cellPhoneSheet, contactSheet, rowLabelCheckboxSheet } from '@icoach/components/';
import { membershipStateColors } from '@icoach/components/MemberAvatar';

// 選取元件
export const rowLabelCheckbox = {
    cellKey: 'memberID',
    headerLabel: '',
    isIcon: true,
    width: 6.8,
    formatCell: (value, rowData, { checkInputRef: ciRef, onCheckChange = () => {} }) => {
        const nickName = rowData.nickName || rowData.name;
        let inputProps = {};
        const changeFn = () => {
            onCheckChange();
        };
        if (nickName) {
            inputProps['data-name'] = nickName;
        }
        return (
            <Checkbox
                color="primary"
                onChange={changeFn}
                value={value}
                inputProps={inputProps}
                inputRef={(el) => {
                    if (el && ciRef && ciRef.current && ciRef.current.indexOf(el) === -1) {
                        ciRef.current.push(el);
                    }
                }}
            />
        );
    },
};

const MembershipStateComponent = (props) => {
    const { membershipStatus, membershipStatusText } = props;
    return (
        <Stack className={'membership-state-wrap'}>
            <div className={'membership-state'} style={{ backgroundColor: membershipStateColors[membershipStatus] }}></div>
            <Typography variant={'body1'}>{membershipStatusText}</Typography>
        </Stack>
    );
};

export const normalLabel = [
    { ...rowLabelCheckboxSheet, cellKey: 'memberID' },
    { ...memberSheet, isFixed: true, isFixedLeft: true },
    { ...cellPhoneSheet },
    {
        cellKey: '',
        headerLabel: '會籍',
        width: 23,
        formatCell: (_value, { membershipStatus, membershipStatusText, membershipStartDate, membershipEndDate }) => {
            return (
                <Box>
                    <MembershipStateComponent membershipStatus={membershipStatus} membershipStatusText={membershipStatusText} />
                    <Typography className={'mb-0'} variant={'body1'}>
                        {displayDateText(membershipStartDate, membershipEndDate, {
                            defaultText: '無會籍',
                        })}
                    </Typography>
                </Box>
            );
        },
    },
    { ...contactSheet },
    { ...moreSheet },
];
