import { memberSheet, moreSheet, cellPhoneSheet, contactSheet, rowLabelCheckboxSheet } from '@icoach/components/';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

//********************** 續約名單 **********************//
export const FilterExtensionLabel = [
    {
        ...rowLabelCheckboxSheet,
        cellKey: 'memberID',
    },
    { ...memberSheet, isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
    { ...cellPhoneSheet },
    {
        cellKey: 'weeklyWorkoutQuantity',
        headerLabel: '本週WO',
        width: 9,
        align: 'center',
    },
    {
        cellKey: 'monthlyWorkoutFrequency',
        headerLabel: 'WO頻率',
        align: 'center',
    },
    {
        cellKey: 'membershipEndDate',
        headerLabel: '現行合約終止日',
        width: 17,
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
    },
    {
        cellKey: 'membershipContinueMonth',
        headerLabel: '會籍已持續時間',
        align: 'center',
        width: 17,
        formatCell: (value) => `${value} 個月`,
    },
    { ...contactSheet },
    { ...moreSheet },
];
